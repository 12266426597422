:root {
    /* --header-bg-color: #FFDA01; */
    --header-bg-color: #ffffff;
    --header-top-bg-color: #ffffff;
    --header-top-color: #000000;
    --header-text-color: #000000;
    --header-button-bg: #008bde;
    --header-button-text: #ffffff;
    --header-cart-bg: #e8104a;
    --header-cart-text: #ffffff;
    --common-color: #008bde;
    --category-bg: #43b02a;
    --category-color: #ffffff;
    --common-button-bg: #008bde;
    --common-button-color: #ffffff;
    --price-color: #000000;
    --heading-color: #3a3a42;
    --continue-shopping: #e8104a;
    --preview-btn-bg: #74b38d;
    --search-btn-bg: #0ab8b4;
    --search-btn-txt-clr: #000;
    --analytics_bg: #efefef;
    --text-heighlight-color: #23a196;
}

body a {
    outline: 0;
}

header {
    border-bottom: 1px solid #f3f3f3;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: var(--header-bg-color);
    z-index: 3;
}

.toggle {
    width: 16px;
    height: 13px;
    cursor: pointer;
    float: left;
    position: relative;
    margin: 20px 10px 0 0;
}

.toggle span,
.toggle span:before,
.toggle span:after {
    height: 2px;
    background: var(--header-text-color);
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.toggle span:before,
.toggle span:after {
    content: "";
    top: 6px;
}

.toggle span:after {
    top: 12px;
}

.logo img {
    width: 175px;
    /* width: 100%; */
}

.logo {
    float: left;
    padding-left: 5px;
}

.headerLeft {
    float: left;
    padding: 14px 0 !important;
    width: 80%;
}

.headerBottom .headerLeft.lokalHeaderLeft {
    padding: 0px 0 !important;
}

.headerRight {
    float: right;
    text-align: right;
    padding: 14px 0;
    position: relative;
    width: 20%;
}

.category {
    background: var(--category-bg);
    font-size: 14px;
    float: left;
    font-weight: 500;
    height: 42px;
    color: var(--category-color);
    position: relative;
    width: 20%;
    z-index: 1;
}

.categoryArrow {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color: var(--category-color) transparent transparent transparent;
    position: absolute;
    right: 5px;
    top: 18px;
    cursor: pointer;
}

body .mainButton {
    cursor: pointer;
    text-decoration: none;
    border-radius: 17px;
    border: 0;
    font-size: 11px;
    font-weight: bold;
    line-height: 18px;
    height: 30px;
    background: var(--header-button-bg);
    color: var(--header-button-text);
    display: inline-block;
    padding: 5px 20px;
    margin: 18px 0 0 13px;
}

.headerIcons {
    display: inline-block;
    /* padding: 24px 0px 16px; */
    /* margin: 0 0 0 10px; */
    position: relative;
    /* min-height: 77px; */
    vertical-align: top;
}


/* .headerIcons:after {content: "";position: absolute;right: 0;top: 0;bottom: 0;width: 1px;background: #ddd;} */

.headerIcons:last-child:after {
    display: none;
}

.contentWrap {
    /* padding: 110px 0 0px; */
    padding: 79px 0 0px;
    min-height: 700px;
}

.headerIcons img {
    width: 21px;
}

.headerImage {
    display: block;
    cursor: pointer;
    padding: 0;
    font-size: 17px;
    font-weight: 600;
    color: var(--header-text-color);
}

.homeWrap {
    width: 95%;
    margin: auto;
    padding-bottom: 80px;
}

.banner img {
    width: 100%;
}

.searchSection {
    position: relative;
    margin-top: -27px;
}

.hideClass {
    display: none;
}

.showClass {
    display: block;
}

.searchField {
    position: relative;
    border-radius: 0px;
    float: left;
    width: 80%;
}

.searchField input[type=text] {
    width: 100%;
    outline: 0;
    position: relative;
    height: 42px;
    border-radius: 0;
    font-size: 15px;
    font-weight: 400;
    color: #3a3a42;
    padding: 2px 15px;
    border: 1px solid var(--category-bg);
    background: #fff;
    border: 1px solid #afafaf;
    border-left: 0;
}

.searchField img {
    position: absolute;
    right: 10px;
    z-index: 1;
    bottom: 12px;
    width: 18px;
    cursor: pointer;
    opacity: 0.7;
}

.homeTab {
    padding-top: 20px;
    position: relative;
}

.homeTab .react-tabs__tab-list {
    border: 0;
    text-align: center;
}

.homeTab .react-tabs__tab,
.homeTab .react-tabs__tab--selected {
    border: 0;
    padding: 0 0 11px;
    border-radius: 0;
    background: transparent;
    bottom: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: .5px;
    text-align: center;
    color: rgba(58, 58, 66, .5);
    text-transform: uppercase;
    margin: 0 16px;
    box-shadow: none;
    outline: 0;
}

.homeTab .react-tabs__tab--selected {
    color: #3a3a42;
    font-weight: 700;
    pointer-events: none;
}

.homeTab .react-tabs__tab--selected:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 6px;
    height: 6px;
    background-color: var(--common-color);
    border-radius: 50%;
    margin: auto;
}

.homeTab .react-tabs__tab-panel {
    padding: 10px 0 0;
}

.categorySection {
    margin: 0 -7px;
    clear: both;
    overflow: hidden;
}

.categoryList {
    padding: 10px 7px 4px;
    width: 16.6%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
}

.categoryItem {
    border-radius: 20px;
    background-color: var(--category-bg-color);
    box-shadow: 0 2px 10px #dcdcdc;
}

.categoryList a,
.categoryList a:hover {
    text-decoration: none;
}

.categoryImage img {
    width: 70%;
}

.categoryImage {
    text-align: center;
    padding: 15px;
}

.categoryDetails {
    padding: 15px;
}

.categoryName {
    height: 36px;
    font-size: 15px;
    line-height: 1.12;
    margin-bottom: 0;
    font-weight: 500;
    color: var(--category-text-color);
}

.itemSection {
    margin: 0 -7px;
    clear: both;
    overflow: hidden;
    min-height: 600px;
}

.productList {
    padding: 10px 7px 4px;
    width: 20%;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: top;
}

.productItem {
    border-radius: 10px;
    background-color: #ffffff;
    /* box-shadow: 0 0px 10px #dcdcdc; */
    position: relative;
    overflow: hidden;
    /* min-height: 335px;
    max-height: 350px; */
    padding: 0 0 8px;
}

.productImage img {
    width: 80%;
    margin: auto;
}

.saveMoreContent .productImage img {
    width: 190px;
}

.productImage {
    text-align: center;
    padding: 22px 15px 15px;
}

.productDetails {
    padding: 15px;
    position: relative;
}

.productName {
    height: 18px;
    font-size: 14px;
    line-height: 1.12;
    margin-bottom: 0;
    font-weight: 500;
    color: #3a3a42;
}

.productName a,
.productName a:hover {
    text-decoration: none;
    color: inherit;
}

.productName a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    height: 100%;
}

.productQty {
    color: #969696;
    font-size: 12px;
    height: 20px;
}

.newPrice {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: var(--price-color);
    margin-right: 10px;
    line-height: 1;
}

.oldPrice {
    font-size: 12px;
    display: inline-block;
    font-weight: 600;
    color: #a2a2a2;
    position: relative;
}

.oldPrice:after {
    content: "";
    position: absolute;
    top: 10px;
    border-bottom: 1px solid #a2a2a2;
    width: 100%;
    left: 0;
}

.productPrice {
    padding: 2px 0 0;
    height: 26px;
}

.productQty select {
    width: 100%;
    background: transparent;
    border: 1px solid #cccccc;
    color: #969696;
    border-radius: 3px;
    height: 20px;
    margin-top: 1px;
}

.brandName {
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0;
    color: var(--common-color);
    position: absolute;
    top: 0;
    font-weight: 600;
}

.offerTag {
    font-size: 11px;
    background: red;
    display: inline-block;
    position: absolute;
    right: 7px;
    top: 21px;
    padding: 0px 5px 0px 13px;
    color: #fff;
    height: 16px;
    border-radius: 0px 4px 0 0px;
    text-align: right;
}

.offerTag:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 6px;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    left: 0;
    top: 0;
}

footer {
    background: #171717;
    padding: 15px 60px 15px 80px;
    color: #fff;
    font-size: 12px;
}

footer a,
footer a:hover {
    color: var(--common-color);
    margin: 0 0 0 3px;
    outline: 0;
}

.adminFooter footer {
    padding-left: 70px;
}

.categoryDrop {
    position: absolute;
    background: #ffffff;
    left: 0;
    width: 195px;
    top: 33px;
    padding: 10px 0 0;
    min-height: 355px;
    box-shadow: 0 5px 10px 0 rgba(117, 117, 117, 0.29);
}

.headerSearch {
    float: left;
    padding: 5px 0 0 30px;
    position: relative;
    width: 74%;
}

.locationPicker img {
    width: 16px;
    margin-right: 5px;
}

.locationPicker span {
    font-size: 12px;
    color: var(--header-text-color);
    font-weight: 600;
}

.locationDiv {
    position: relative;
    top: 0;
}

.locationPicker {
    cursor: pointer;
}

.popupWrap {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    z-index: 6;
    display: none;
}

.popOverlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(247, 249, 250, .8);
    z-index: 0;
}

.loginPop {
    width: 480px;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    margin: auto auto 20px;
    box-shadow: 0 3px 22px 4px hsla(0, 0%, 65.1%, .47);
    text-align: left;
}

.form-field-confirm {
    padding: 5px 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-field-confirm::placeholder {

    font-weight: 200;
    color: #000;
    font-size: 14px;
}

.form-label-payment {
    font-size: 15px;
}

.paymentSectionWrap {
    border: 1px solid rgb(237 237 237);
}

.paymentSectionWrap .form-wrap {
    padding: 0px 20px;
}

.paymentHead {
    background: rgb(204 204 204 / 47%);
    color: #000;
    margin-bottom: 15px;
    text-align: center;
    padding: 12px;
    font-size: 20px;

}

.buttongroups {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 0px 20px 20px 20px;
}

.buttongroups .button_confirmtext {
    padding: 7px 7px;
    width: 100%;
    border-radius: 5px;
    margin-right: 5px;
}

.buttongroups .button_confirmtext:last-child {
    margin-right: 0px;
}

.textred {
    border: 1px solid rgb(252, 6, 6);
}

.star {
    font-size: 18px;
    top: -4px;
}

.save_confirmBtn {
    background: var(--common-color);
    color: #fff;
}

.locationPopUps {
    background: rgba(0, 0, 0, 0.6);
}

.cancel_confirmBtn {
    background: #171717;
    color: #fff;
}

.confirmWrappers {
    width: 360px;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    margin: auto auto 20px;
    box-shadow: 0 3px 22px 4px hsla(0, 0%, 65.1%, .47);
    text-align: left;
    padding: 15px;
}

.loginHead {
    padding: 25px 50px 25px 50px;
    background-color: var(--common-color);
    position: relative;
    text-align: center;
}

.loginForm {
    padding: 15px 50px;
}

.loginFormList {
    padding-bottom: 15px;
    position: relative;
}

body .inputField {
    width: 100%;
    padding: 1px 10px;
    border: 1px solid rgba(151, 151, 151, 0.37);
    height: 33px;
    outline: 0;
    font-size: 12px;
    border-radius: 5px;
}

body .goButton,
body .goButton:hover {
    width: 50px;
    height: 33px;
    border-radius: 3px;
    border: 0;
    background-color: var(--common-color);
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    line-height: 2.91;
    display: block;
    text-align: center;
    outline: 0;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    text-decoration: none;
}

body .locationClear {
    position: absolute;
    right: 50px;
    width: 20px;
    bottom: 0;
    top: 0;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

.loginClose,
.saveClose {
    cursor: pointer;
    position: absolute;
    right: 23.7px;
    top: 26px;
    z-index: 1;
}

.loginClose img,
.saveClose img {
    width: 14.3px;
    opacity: .7;
}

.loginText {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.03;
    color: #fff;
}

.locationFinder img {
    width: 13px;
}

.locationFinder {
    position: absolute;
    right: 5px;
    top: 2px;
    pointer-events: none;
    opacity: 0.7;
}

.cartCount {
    font-size: 10px;
    width: 23px;
    height: 23px;
    background: var(--header-cart-bg);
    border-radius: 50%;
    display: block;
    color: var(--header-cart-text);
    position: absolute;
    top: 14px;
    right: 8px;
    text-align: center;
    line-height: 22px;
}

.itemFilterSection {
    width: 17%;
    float: left;
    padding: 10px 0 0 0;
}

.itemListSection {
    width: 83%;
    float: right;
    padding: 0 15px;
}

.itemListDiv {
    clear: both;
    overflow: hidden;
}

.filterText {
    display: none;
}

.tagSection {
    float: left;
}

.filterSection {
    float: right;
    padding: 2px 0 0;
}

.itemListTop {
    float: left;
    width: 100%;
    padding: 10px;
    position: relative;
}

body .tag {
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid #ddd;
    font-size: 12px;
    border-radius: 30px;
    margin: 0 3px 5px 0;
    cursor: pointer;
    text-decoration: none;
    color: #000;
}

.sortList {
    width: 150px;
    float: left;
    position: relative;    margin-bottom: 35px;    right: 15px;
}

.sortListDiv {
    position: absolute;
    z-index: 2;
    background: #fff;
    border: 1px solid #ddd;
    right: 0;
    overflow: hidden;
    left: 7px;
    top: 0;
    height: 24px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.sortListActive {
    height: 140px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.sortListDiv span,
.sortListDiv span:hover {
    display: block;
    color: #868686;
    font-size: 11px;
    padding: 3px 10px;
    cursor: pointer;
    text-decoration: none;
}

.sortListDiv span:hover {
    background: #e8e8e8;
}

.sortBy {
    font-size: 12px;
    float: left;
    padding: 1px 0 0;
}

body h5 {
    font-size: 14px;
    color: #808080;
    margin: 0 0 7px;
    padding: 4px 0 4px 15px;
    background: #eaeaea;
}

.fliterList ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.fliterList .filterListHeight {
    max-height: 172px;
    overflow-y: auto;
}

.fliterList ul a {
    color: #7d7d7d;
    text-decoration: none;
}

.fliterList ul li {
    font-size: 12px;
    padding: 0 0 3px 25px;
    clear: both;
    line-height: 16px;
    color: #7d7d7d;
}

.fliterList {
    margin: 0 0 0px;
    border-bottom: 1px solid #e8e8e8;
    padding: 0 0 7px;
}

.checkBoxSquare {
    width: 17px;
    position: relative;
    float: left;
    margin-right: 6px;
}

.checkBoxSquare input[type="checkbox"] {
    display: none;
}

body .checkBoxSquare label {
    cursor: pointer;
    position: relative;
    width: 16px;
    height: 16px;
    padding: 0;
    left: 0;
    margin: 0;
    opacity: 1;
    top: 1px;
    float: none;
    border: 1px solid #969696;
    background: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.checkBoxSquare label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 9.5px;
    height: 4.5px;
    background: transparent;
    top: 3.5px;
    left: 2px;
    border: 2px solid var(--common-color);
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    color: #969696;
}

.checkBoxSquare input[type=checkbox]:checked+label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    border-color: var(--common-color);
}

.checkBoxSquare input[type=checkbox]:checked+label {
    border-color: var(--common-color);
}

ul.mainCategory {
    padding: 0;
    list-style: none;
    margin: 0;
}

.mainCategory li {
    display: block;
    font-size: 12px;
}

.subCategory li {
    font-size: 11px;
}

.subCategory ul,
.subSubCategory ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 50%;
    float: left;
    position: relative;
    height: 100%;
}

.mainCategory li a {
    color: #6d6d6d;
    text-decoration: none;
    display: block;
    padding: 5px 15px;
}

.subCategory {
    position: absolute;
    left: 195px;
    background: #eaeaea;
    top: 0;
    width: 280px;
    bottom: 0;
    box-shadow: 0 5px 10px 0 rgba(23, 84, 116, 0.18);
    padding: 10px 0 0;
    display: none;
    z-index: 1;
}

.subSubCategory {
    position: absolute;
    left: 100%;
    background: #f5f5f5;
    top: -10px;
    width: 280px;
    bottom: 0;
    box-shadow: 0 5px 10px 0 rgba(23, 84, 116, 0.18);
    padding: 10px 0 0;
    display: none;
    z-index: 2;
}

.categoryItemDiv {
    cursor: pointer;
    padding: 9px 20px 11px 10px;
}

.cartDiv {
    position: fixed;
    background: #fff;
    width: 500px;
    right: 0;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
    border: 1px solid #e3e2e2;
    z-index: 1;
    text-align: left;
    top: 55px;
    bottom: 0;
}

.cartProductSection {
    padding: 5px;
    /* max-height: 300px; */
    /* max-height: 340px;
    overflow-y: auto; */
}

.cartItemList {
    max-height: 340px;
    overflow-y: auto;
}

.shopTitle {
    background: #d4efff;
    padding: 5px;
    clear: both;
    overflow: hidden;
    position: relative;
}

.shopLogo img {
    width: 50px;
}

.shopLogo {
    float: left;
}

.shopName {
    float: left;
    width: 80%;
    font-size: 12px;
    padding: 3px 0 0 5px;
}

.shopClose {
    position: absolute;
    right: 5px;
    top: 2px;
    cursor: pointer;
}

.shopClose img {
    width: 17px;
}

.productItemWrap {
    padding: 5px 5px 0;
}

.productItemList {
    border-bottom: 1px solid #efefef;
    clear: both;
    overflow: hidden;
    position: relative;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.productItemImage {
    width: 15%;
    float: left;
}

.productItemImage img {
    width: 100%;
}

.commonProductItemImage img {
    width: auto;
    height: 60px;
}

.commonProductItemImage {
    text-align: center;
}

.orderSection .commonProductItemImage img {
    height: 70px;
}

.productItemDetails {
    width: 85%;
    float: left;
    padding: 0 0 0 10px;
}

.productItemLeft {
    width: 75%;
    float: left;
    color: #6f6f6f;
    position: relative;
}

.productItemRight {
    width: 25%;
    float: left;
    font-size: 12px;
    text-align: right;
}

.productBrand {
    font-size: 10px;
    line-height: 1.2;
}

.productItemName {
    font-size: 11px;
    font-weight: 600;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.productItemPrice {
    font-size: 11px;
}

.removeItem {
    color: #920000;
    font-size: 15px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 20px;
}

.cartPriceLeft {
    width: 50%;
    float: left;
}

.cartPriceItem {
    clear: both;
    overflow: hidden;
}

.productItemList:last-child {
    margin: 0;
    padding: 0;
    border: 0;
}

.cartPriceSection {
    border-top: 1px solid #ddd;
    padding: 5px 10px;
}

.shopPriceName {
    font-size: 12px;
    margin-bottom: 3px;
}

.cartDelivery {
    font-size: 10px;
    color: #929292;
    margin-bottom: 3px;
}

.mrpPriceList {
    font-size: 12px;
    margin-bottom: 3px;
}

.cartPriceRight {
    float: left;
    width: 50%;
    text-align: right;
}

.mrpDiscountList {
    font-size: 10px;
    color: #929292;
    margin-bottom: 3px;
}

.cartSaveAmount {
    font-size: 11px;
    color: #ff0000;
}

.mrpTotalList {
    border-top: 1px solid #ddd;
    font-size: 13px;
    font-weight: 600;
    padding-top: 2px;
}

.cartPriceText {
    font-size: 10px;
    color: red;
    padding-top: 5px;
}

.cartGrandDiv {
    border-top: 1px solid #ddd;
    margin: 7px 0 0;
    text-align: right;
    padding: 5px 15px;
}

.cartGrandTotal {
    font-size: 14px;
    font-weight: 600;
    color: #a00000;
    padding: 3px 0;
}

.mrpTotalList span {
    color: #a00000;
}

body .checkoutButton {
    cursor: pointer;
    text-decoration: none;
    border-radius: 17px;
    border: 0;
    font-size: 11px;
    font-weight: bold;
    line-height: 20px;
    background: var(--header-button-bg);
    color: var(--header-button-text);
    display: inline-block;
    padding: 7px 20px;
    margin: 6px 0 0;
}

.mrpPriceList span,
.mrpDiscountList span,
.mrpTotalList span {
    display: inline-block;
    width: 95px;
}

.banner {
    position: relative;
}

.banner .slick-next,
.banner .slick-prev {
    display: none !important;
}

.banner .slick-dots {
    bottom: 30px;
    position: absolute;
}

.banner .slick-dots li button:before,
.profileSlider .slick-dots li button:before {
    display: none;
}

.banner .slick-dots li,
.profileSlider .slick-dots li {
    background: rgba(255, 255, 255, 0.35);
    border-radius: 50%;
    width: 13px;
    height: 13px;
    margin: 0 3px;
}

.banner .slick-dots li.slick-active,
.profileSlider .slick-dots li.slick-active {
    background: var(--common-color);
}

.profileSlider .slick-dots li {
    background: rgba(206, 206, 206, 0.98);
}

.sidemenu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: var(--common-color);
    width: 300px;
    z-index: 6;
}

.sideMenuDiv {
    display: none;
}

.menuClose {
    position: absolute;
    left: 22px;
    top: 25px;
    cursor: pointer;
}

.menuClose img {
    width: 14px;
}

.menuListView {
    clear: both;
    overflow: hidden;
    padding: 80px 75px 40px 75px;
}

.mainMenuList {
    width: 100%;
    float: left;
    margin-bottom: 20px;
}

.menuName {
    font-size: 17px;
    font-weight: 500;
    line-height: normal;
    color: #ffffff;
    display: block;
    margin-bottom: 15px;
}

.mainMenuList ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mainMenuList ul li {
    padding-bottom: 7px;
}

.mainMenuList li a,
.mainMenuList li a:hover {
    font-size: 14px;
    line-height: 0.95;
    color: #fff !important;
    text-decoration: none;
    cursor: pointer !important;
}

.menufooter {
    padding: 30px 75px 20px 75px;
    clear: both;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.socialSection {
    float: left;
    padding: 3px 0 0;
}

.appSection {
    float: right;
}

.appSection img {
    width: 95px;
}

.appSection a {
    display: inline-block;
    margin: 0 0 0 5px;
}

.socialSection img {
    width: 22px;
}

.socialSection a {
    display: inline-block;
    margin: 0 15px 0 0;
}

.contactList {
    margin-bottom: 10px;
    font-size: 13px;
}

.contactList label {
    display: block;
    color: #fff;
    margin: 0 0 5px;
    font-size: 13px;
}

.contactList span {
    display: block;
    color: #fff;
}

.contactList a {
    color: #fff;
    text-decoration: none;
}

.headerTop {
    clear: both;
    overflow: hidden;
    border-bottom: 1px solid #efefef;
    padding: 5px 30px;
    background: var(--header-top-bg-color);
}

.headerBottom {
    padding: 0 30px 0 30px;
}

.storeNameTop {
    float: left;
    font-size: 14px;
    font-weight: 600;
    color: var(--header-top-color);
    line-height: 16px;
    margin: 0 15px 0 0;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.storeNameTop a,
.storeNameTop a:hover,
.storeNumber a,
.storeNumber a:hover {
    color: var(--header-top-color);
    text-decoration: none;
}

.callIcon img {
    width: 15px;
    margin: 0 7px 0 0;
}

.callIcon {
    display: inline-block;
    padding-right: 7px;
    position: relative;
}

.storeNumber {
    float: right;
    font-size: 14px;
    font-weight: 600;
    color: var(--header-top-color);
}

.whatsapIcon {
    display: none;
}

.loginText span {
    display: block;
    font-size: 13px;
    color: #ffffff;
    padding-top: 8px;
    font-weight: 400;
}

.loginFormList label {
    opacity: 0.47;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    color: #2c2c2c;
    display: block;
    margin: 0;
}

body .inputTextField {
    width: 100%;
    border: 0;
    border-bottom: solid 1px rgba(151, 151, 151, 0.37);
    height: 30px;
    outline: 0;
    font-size: 12px;
}

body .loginButton {
    width: 100%;
    height: 36px;
    border-radius: 3px;
    background-color: var(--common-button-bg);
    border: 0;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    line-height: 36px;
    display: block;
    text-align: center;
    /* outline: 0; */
    cursor: pointer;
}

.formButton {
    margin-bottom: 15px;
    position: relative;
}

.forgotLink {
    text-decoration: none;
    font-size: 10.9px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: #6a75c9;
    margin-bottom: 10px;
}

.forgotLink span {
    cursor: pointer;
}

.orLogin {
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    border-bottom: solid 1px rgba(151, 151, 151, 0.37);
    position: relative;
    margin-bottom: 20px;
}

.orLogin span {
    position: relative;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    background: #fff;
    top: 5px;
    z-index: 2;
    padding: 1px 10px;
    color: rgba(44, 44, 44, 0.43);
}

.faceBook,
.google {
    width: 180px;
    display: inline-block;
    text-align: center;
    height: 36px;
    border-radius: 3px;
    background-color: #4568b2;
    border: 1px solid #4568b2;
    vertical-align: middle;
    padding: 1px 0px;
    font-size: 11px;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    line-height: 2.91;
    color: #fff;
    cursor: pointer;
}

.faceBook {
    margin-right: 11px;
}

.google {
    border: solid 1px #767676;
    background: transparent;
    color: #3a3a42;
}

.faceBook img {
    width: 8px;
    margin-right: 0;
    position: absolute;
    top: 11px;
    left: 48px;
    pointer-events: none;
    z-index: 1;
}

.google img {
    width: 13px;
    margin-right: 0px;
    position: absolute;
    top: 11px;
    left: 36px;
    pointer-events: none;
    z-index: 1;
}

.registrationText {
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #949494;
    margin-top: 20px;
}

.registrationText a,
.registrationText span {
    font-weight: bold;
    color: var(--common-color);
    text-decoration: none;
    cursor: pointer;
    padding-left: 5px;
    font-size: 115%;
}

.locationOpenPopUp {
    display: block;
}

.locationOpenPopUp .popOverlay {
    pointer-events: none;
}


/* .locationOpenPopUp .loginClose {
    display: none;
} */

.shareDiv {
    position: absolute;
    background: var(--common-color);
    width: 140px;
    right: 0;
    padding: 5px 3px 10px;
    border-radius: 5px;
    margin-top: 5px;
}

.shareDiv a,
.shareDiv span {
    display: block;
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    text-decoration: none;
}

.shareDiv img {
    width: 17px;
    margin: 0 10px 0 0;
}

.searchDiv {
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #e8e8e8;
    box-shadow: 0 5px 10px 0 rgba(117, 117, 117, 0.29);
    margin-top: 0.5px;
}

.search a {
    display: block;
    color: #1d1d1d;
}

.searchImage img {
    position: relative;
    right: auto;
    bottom: auto;
    width: 35px;
}

.searchList {
    padding: 5px 10px;
    clear: both;
    overflow: hidden;
    border-bottom: 1px solid #f3f3f3;
}

.search {
    padding: 5px;
}

.searchImage {
    float: left;
    width: 12%;
}

.searchDetails {
    float: left;
    padding: 7px 0 0 10px;
    font-size: 11px;
    width: 88%;
}

.searchQty {
    float: right;
}

.searchItemName {
    float: left;
    max-width: 80%;
}

.headerSearchSubOuter {
    clear: both;
    overflow: hidden;
    border: 1px solid #dadada;
    right: 0;
    position: absolute;
    background: #fff;
    top: 48px;
    left: 30px;
    z-index: 1;
}

.headerSearchSub {
    max-height: 325px;
    overflow-y: auto;
    float: left;
    width: 100%;
}

.headerSearchSub .searchDetails {
    float: left;
    padding: 7px 0 0 10px;
    font-size: 12px;
    width: 74%;
}

.headerSearchSub .searchQty {
    float: left;
    width: 19%;
    padding-right: 1%;
    text-align: center;
}

.headerSearchSub .searchRate {
    float: right;
    width: 19%;
    padding-right: 1%;
}

.headerSearchSub .searchItemName {
    float: left;
    width: 58%;
    padding-right: 2%;
}

.headerSearchSub .searchField input[type=text] {
    width: 100%;
    padding: 2px;
}

.headerSearchSub .qty {
    right: 0;
    background-color: #fff;
    width: 100%;
    padding: 0 4px;
}

.headerSearchSub .cart_clk {
    z-index: -1;
}

.headerSearchSub::-webkit-scrollbar-track,
.cartProductSection::-webkit-scrollbar-track,
.cartItemList::-webkit-scrollbar-track,
.fliterList .filterListHeight::-webkit-scrollbar-track,
.autoSearch::-webkit-scrollbar-track,
.storeContentDiv ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.headerSearchSub::-webkit-scrollbar,
.cartProductSection::-webkit-scrollbar,
.fliterList .filterListHeight::-webkit-scrollbar,
.autoSearch::-webkit-scrollbar,
.storeContentDiv ::-webkit-scrollbar,
.cartItemList::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.headerSearchSub::-webkit-scrollbar-thumb,
.cartProductSection::-webkit-scrollbar-thumb,
.fliterList .filterListHeight::-webkit-scrollbar-thumb,
.autoSearch::-webkit-scrollbar-thumb,
.storeContentDiv ::-webkit-scrollbar-thumb,
.cartItemList::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: var(--common-color);
}

.search a:last-child .searchList {
    border: 0;
}

.profileTagSection {
    padding: 10px;
}

.profileImage img {
    width: 100%;
}

.itemProfile {
    clear: both;
    overflow: hidden;
    padding: 30px 50px;
}

.profileImage {
    width: 40%;
    float: left;
    padding: 0 50px;
}

.profileDetails {
    width: 60%;
    float: left;
    position: relative;
}

.profileSlider {
    margin: auto;
    background: #ffffff;
}

.profileItemBrand {
    font-size: 13px;
    color: #888;
    font-weight: 600;
    margin: 0 0 3px;
}

.profileItemName {
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase;
    color: #565656;
    margin: 0 0 15px;
}

.radioList {
    clear: both;
}

.radioSection {
    position: relative;
    color: #929292;
    font-size: 13px;
}

.radioSection input[type="radio"] {
    display: none;
}

.radioSection label {
    cursor: pointer;
    position: relative;
    width: 16px;
    height: 16px;
    padding: 0;
    left: 0;
    margin: 0 7px 0 0;
    top: 3px;
    float: none;
    border: 1px solid #969696;
    background: #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.radioSection label:before {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: var(--common-color);
    top: 2px;
    left: 0;
    right: 0;
    margin: auto;
    border-right: none;
    color: #969696;
    border-radius: 50%;
}

.radioSection input[type=radio]:checked+label:before {
    /* ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)"; */
    filter: alpha(opacity=100);
    opacity: 1;
}

.radioSection input[type=radio]:checked+label {
    border-color: var(--common-color);
}

body .commonButton {
    cursor: pointer;
    text-decoration: none;
    border-radius: 30px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    height: 40px;
    background: var(--common-button-bg);
    color: var(--common-button-color);
    display: inline-block;
    padding: 10px 35px;
    outline: 0;
    cursor: pointer;
}

body .commonButton:focus,
body .formInputField:focus,
body .formInputTextarea:focus {
    border-color: #565656;
}

.profileButtonSection {
    margin: 0 0 40px;
    position: relative;
}

.profileItemQty {
    margin: 0 0 25px;
}

h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 15px;
    color: #616161;
}

p {
    font-size: 13px;
    margin: 0 0 6px;
    color: #7d7d7d;
}

.productAbout {
    margin: 0 0 15px;
}

h6 {
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 15px;
    color: #616161;
}

.productDetailPrice {
    margin-bottom: 20px;
}

.productDetailPrice .newPrice {
    font-size: 185%;
}

.productDetailPrice .oldPrice {
    font-size: 133%;
    vertical-align: bottom;
}

.productDetailPrice .oldPrice:after {
    top: 16px;
    border-width: 2px;
}

.orderLeft {
    width: 75%;
    float: left;
    padding: 0 20px 0 0;
}

.orderRight {
    width: 25%;
    float: left;
    padding: 30px 25px;
    background: #ffffff;
    position: fixed;
    right: 0;
    top: 115px;
}

.orderSummary {
    clear: both;
    overflow: hidden;
    padding: 0 30px 50px;
}

.titleHeading {
    font-size: 30px;
    padding: 25px 30px;
    color: #585858;
}

.accordionDiv .accordion__item {
    margin-bottom: 5px;
}

.accordionDiv .accordion {
    border: 0;
    border-radius: 0;
}

.accordionDiv .accordion__button,
.orderSummeryTabHeading {
    background: #ffffff;
    padding: 0px 20px 0px 85px;
    position: relative;
    height: 56px;
    /* border-radius: 0 10px 0 0; */
    overflow: hidden;
    font-size: 20px;
    line-height: 52px;
    cursor: pointer;
}

.accordionDiv .accordion__button:hover,
.accordionDiv .accordion__button:focus,
.accordionDiv .accordion__button:active {
    background-color: #ffffff;
}

.accordionDiv .accordion__item+.accordion__item {
    border: 0;
}

.accordionDiv .accordion__panel,
.orderSummeryTabDetails {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-top: 0;
    padding: 20px 0;
}

.orderSummeryTabDetails {
    display: none;
}

.orderSummeryTab {
    margin-bottom: 5px;
}

.accordionDiv .accordion__button:before {
    display: none;
}

.accordionDiv .accordion__button span,
.orderSummeryTabHeading span {
    width: 50px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    background: var(--common-color);
    color: #fff;
    font-weight: 600;
}

.accordionDiv .accordion__button span:after,
.orderSummeryTabHeading span:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 28px 0 28px 20px;
    border-color: transparent transparent transparent var(--common-color);
    position: absolute;
    top: 0;
    bottom: 0;
    right: -20px;
}

.accordionDiv .accordion__button:after,
.orderSummeryTabHeading:after {
    content: "";
    position: absolute;
    right: 0;
    left: 51px;
    background: #e0e0e0;
    height: 1px;
    bottom: 0;
}

.addressList {
    width: 230px;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
}

body .borderButton {
    display: inline-block;
    padding: 8px 30px;
    border: 1px solid var(--common-button-bg);
    border-radius: 30px;
    font-size: 13px;
    cursor: pointer;
}

.addressTitle {
    padding: 6px 20px;
    background: var(--common-color);
    font-size: 15px;
    font-weight: 600;
    color: #fff;
}

.addressDetails {
    padding: 20px 20px 35px;
    position: relative;
}

.addressPanel {
    min-height: 130px;
}

h4 {
    font-size: 16px;
    margin: 0 0 5px;
}

.addressPanel p {
    margin: 0 0 1px;
}

.addressButton .commonButton {
    display: block;
    text-align: center;
    font-weight: 400;
    padding: 7px 15px;
    height: auto;
    border-radius: 17px;
    font-size: 13px;
}

.addressAction {
    position: absolute;
    right: 0;
    bottom: 2px;
    font-size: 13px;
}

.addressAction span {
    display: inline-block;
    margin: 0 10px 0 0;
    cursor: pointer;
}

.addressDelete {
    color: #ff0000;
}

.orderSummaryButton {
    text-align: right;
    padding: 0 20px;
}

.accordion__panel {
    border: 1px solid #e0e0e0;
    padding: 30px;
}

.accordion__button:hover,
.accordion__button:focus {
    background-color: #e8e8e8;
    outline: 0;
}

.orderSection {
    margin: 0 -20px;
}

.orderSection .productItemDetails {
    padding: 10px 0 0 15px;
    width: 93%;
}

.orderSection .productItemImage {
    width: 7%;
}

.orderSection .shopTitle {
    padding: 5px;
}

.orderSection .shopName {
    font-size: 15px;
    /* padding: 35px 0 0 15px; */
    font-weight: 600;
}

.orderSection .shopLogo img {
    width: 105px;
}


/* .orderSection .shopClose {
    top: 15px;
} */

.orderSection .productItemList {
    padding: 5px 20px 8px;
}

.orderSection .productBrand {
    font-size: 11px;
}

.orderSection .productItemName {
    font-size: 12px;
}

.orderSection .productItemLeftCenter .productItemName {
    font-size: 11px;
}

.orderSection .productItemRight {
    font-size: 13px;
    position: relative;
}

.orderPriceLeft {
    width: 50%;
    float: left;
}

.orderPriceRight {
    float: left;
    text-align: right;
    width: 50%;
}

.orderSummaryPrice {
    clear: both;
    overflow: hidden;
    padding: 15px 20px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.orderFreeText {
    font-size: 12px;
    color: #1785d6;
    padding: 0 0 16px;
}

.promoCode {
    font-size: 12px;
    font-weight: 600;
    color: #7d7d7d;
}

.promoText {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    top: -4px;
    position: relative;
}

.promoText input[type="text"] {
    width: 100px;
    padding: 2px 5px;
    height: 25px;
    font-size: 12px;
    border: 1px solid #d4d4d4;
    color: #7d7d7d;
    border-radius: 5px;
    margin: 0 6px 0 0;
    vertical-align: middle;
}

body .promoApply,
body .promoResend {
    cursor: pointer;
    color: #007cff;
    line-height: 25px;
}

.promoError {
    display: block;
    font-weight: 400;
    color: #ff2e2e;
}

.promoResend {
    display: none;
}

.summarySaveText {
    font-size: 12px;
    color: #ff1616;
    font-weight: 600;
    padding: 10px 0 0;
}

.priceListSummary label {
    margin: 0;
}

.priceListSummary {
    font-size: 15px;
    color: #808080;
    font-weight: 600;
    padding: 0 0 3px;
}

.priceListSummary span {
    width: 150px;
    display: inline-block;
}

.priceListTotal {
    padding: 10px 0 0;
    font-size: 15px;
    color: #000;
    font-weight: bold;
}

.summaryTextArea {
    clear: both;
    /* overflow: hidden; */
    padding: 15px 0px 15px 20px;
    float: left;
    width: 100%;
}

.summaryDetailLeft {
    width: 50%;
    float: left;
    color: #4a4a4a;
    padding: 0 20px 10px 0;
}

.summaryDetailLeft label {
    width: 40%;
    float: left;
    margin: 0;
    padding: 5px 10px 0 0;
    font-size: 13px;
    font-weight: 600;
}

.summaryDetailLeft input[type="text"],
.summaryDetailLeft select,
.summaryDetailLeft input[type="text"],
.summaryDetailLeft textarea {
    width: 60%;
    float: left;
    margin: 0;
    height: 30px;
    padding: 2px 10px;
    font-size: 12px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    vertical-align: middle;
}

.summaryDetailLeft textarea {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 5px 10px;
}

.summaryButtonSection {
    padding: 10px 20px;
    text-align: right;
    clear: both;
}

body .continueButton {
    text-decoration: none;
    color: var(--common-color);
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}

body .commonLargeButton {
    padding: 10px 55px;
    margin: 0 0 0 20px;
    text-transform: uppercase;
}

.totalPriceDiv {
    text-align: right;
    padding: 20px 20px;
    font-size: 17px;
    color: #000;
    font-weight: bold;
}

.totalPriceDiv span {
    padding-left: 7px;
}

.totalPriceDiv label {
    margin: 0;
}

.confirmAllSection {
    text-align: center;
}

.paymentSection {
    width: 70%;
    margin: 50px auto;
}

.totalAmount {
    font-size: 27px;
    font-weight: bold;
    padding: 0 0 25px;
}

.paymentList {
    padding-bottom: 10px;
}

.paymentConfirm {
    padding: 30px 0 0;
}

.paymentList .radioSection {
    font-weight: 600;
    color: #6f6f6f;
}

.paymentList .radioSection label {
    top: 3.5px;
}

.paymentConfirm .commonLargeButton {
    margin: 0;
}

.confirmAllSection .commonLargeButton {
    margin: 0;
}

.greyBg {
    background: #f5f5f5;
}

body .autoSearch {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    background: #fff;
    z-index: 1;
    /* left: 0; */
    right: 0;
    border: 1px solid rgba(151, 151, 151, 0.37);
    max-height: 290px;
    overflow: auto;
    width: 60%;
}

body .autoSearchAlignmnt {
    left: 34%;
    width: 38%;
}

body .autoSearch li {
    font-size: 14px;
    display: block;
    padding: 5px 15px;
    cursor: pointer;
}

body .autoSearch li:hover {
    background: #efefef;
}

body .autoSearch-str-prof {
    margin-top: 35px;
    left: 32px;
    border-radius: 17px;
}

.summaryRightTitle {
    font-size: 22px;
    color: #444;
    margin: 0 0 10px;
}

.summaryShopLogo img {
    width: 70px;
    margin: 0 10px 0 0;
}

.summaryShopLogo {
    display: inline-block;
}

.summaryShop {
    clear: both;
    overflow: hidden;
    margin: 15px 0 10px;
}

.summaryShopName {
    display: inline-block;
    width: 65%;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    color: #000;
    vertical-align: middle;
}

.summaryShopList label,
.summaryPayList label {
    margin: 0;
    float: left;
}

.summaryShopList,
.summaryPayList {
    font-size: 14px;
    color: #656565;
    clear: both;
    overflow: hidden;
    margin: 0 0 3px;
}

.summaryShopList span,
.summaryPayList span {
    float: right;
}

body .deliverColor {
    color: #4ac3fd;
    font-size: 12px;
}

.summaryPayList label {
    font-size: 100%;
}

.summaryPayList {
    text-transform: uppercase;
    padding: 20px 0;
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.safeText img {
    width: 30px;
    display: inline-block;
}

.safeText span {
    padding: 0 0 0 15px;
    font-weight: 600;
    color: #949494;
    width: 60%;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.3;
    font-size: 13px;
}

.orderRight p {
    margin: 0;
    color: #949494;
    font-size: 14px;
}

.safeText {
    margin: 0 0 20px;
}

.addressForm {
    padding: 30px;
    width: 70%;
}

.adressFormList {
    margin: 0 0 10px;
    color: #676767;
    font-size: 13px;
    display: flex;
    align-items: center;justify-content: space-between;
}

body .labelField {
    display: inline-block;
    width: 35%;
    text-align: right;
    margin: 0;
    font-weight: 600;
    vertical-align: top;
    padding-top: 3px;
}

.formInputSectionMain {
    width: 65%;
    display: inline-block;
}

.formInputSectionMain .formInputSection {
    width: 100%;
}

.formInputSection {
    display: inline-block;
    width: 65%;
    padding: 0 0 0 15px;
    vertical-align: top;
}

body .formInputField,
body .formInputTextarea {
    width: 100%;
    padding: 1px 10px;
    border: 1px solid rgba(151, 151, 151, 0.37);
    height: 33px;
    outline: 0;
    border-radius: 5px;
    color: #404040;
    font-size: 12px;
    font-weight: 400;
    text-overflow: ellipsis;
    /* overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
}

body .formInputTextarea {
    height: 120px;
    padding: 6px 10px;
    resize: none;
}

.formInputText {
    padding: 3px 0 0;
}

.numberDefult {
    display: block;
    position: relative;
    padding: 7px 0 8px;
    font-size: 12px;
    font-weight: 600;
}

.numberDefult .checkBoxSquare {
    margin: 0 10px 0 0;
}

.numberField .formInputField {
    width: 90%;
    float: right;
    margin: 0;
}

.numberField {
    display: block;
    clear: both;
    overflow: hidden;
    line-height: 30px;
    font-weight: 600;
    color: #000;
    padding: 0;
}

.adressFormButton {
    text-align: right;
    padding: 15px 0 0;
}

.labelField sup {
    color: #ff0000;
}

.relatedSlider {
    clear: both;
    overflow: hidden;
    padding: 30px;
}

.subTitle {
    font-size: 25px;
    font-weight: 600;
    margin: 0 0 15px;
    color: #7d7d7d;
}

.relatedSlider .productItem {
    margin: 0 5px;
}

.relatedSlider .slick-prev,
.relatedSlider .slick-next {
    right: 30px;
    height: 60px;
    width: 30px;
    border-radius: 4px;
    background: rgba(58, 58, 66, 0.6) !important;
    cursor: pointer;
    z-index: 1;
    display: block !important;
}

.relatedSlider .slick-next {
    right: -15px;
}

.relatedSlider .slick-prev,
.relatedSlider .slick-prev:hover {
    left: -15px;
    transform: translate(0, -50%) scaleX(-1);
    -webkit-transform: translate(0, -50%) scaleX(-1);
    -moz-transform: translate(0, -50%) scaleX(-1);
    -ms-transform: translate(0, -50%) scaleX(-1);
}

.relatedSlider .slick-prev:before,
.relatedSlider .slick-prev:before {
    content: "";
    background-image: url(../images/icons/slider-arrow.png);
    width: 13px;
    height: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    display: block;
    margin: auto;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.relatedSlider .slick-next:before,
.relatedSlider .slick-next:before {
    content: "";
    background-image: url(../images/icons/slider-arrow.png);
    width: 13px;
    height: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    display: block;
    margin: auto;
}

.mainHeading {
    font-size: 35px;
    padding: 35px 30px;
    color: #585858;
    text-align: center;
    font-weight: 600;
}

.contactForm {
    padding: 40px 50px;
    width: 50%;
    float: left;
    border-radius: 30px;
    box-shadow: 0px 0px 15px #e2e2e2;
    border: 1px solid #f1f1f1;
}

.contactFormList label {
    display: block;
    margin: 0;
    font-size: 13px;
    color: #7d7d7d;
}

.contactFormList {
    margin-bottom: 20px;
}

.contactFormList input[type="text"],
.contactFormList textarea {
    width: 100%;
    border: 0;
    border-bottom: 1px solid rgba(151, 151, 151, 0.37);
    outline: 0;
    padding: 2px 0;
    font-size: 13px;
    color: #7d7d7d;
    height: 40px;
}

.contactFormList textarea {
    height: 125px;
    border: 1px solid rgba(151, 151, 151, 0.37);
    margin: 10px 0 0;
    border-radius: 7px;
    padding: 5px 10px;
    resize: none;
}

.contactFormButton {
    text-align: right;
}

.contactAddress {
    padding: 40px 50px;
    width: 50%;
    float: left;
}

.contactAddressIcon {
    width: 15%;
    float: left;
}

.contactAddressList {
    clear: both;
    overflow: hidden;
    margin: 0 0 30px;
}

.contactAddressIcon img {
    width: 40px;
}

.contactAddressDetails {
    width: 85%;
    float: left;
}

.contactAddressDetails h6 {
    margin-bottom: 10px;
}

.contactAddressDetails p {
    margin: 0 0 15px;
}

.contactAddressDetails a {
    color: #2d2c2c;
}

.contactCover {
    clear: both;
    overflow: hidden;
    padding: 10px 10px 70px;
}

.topSearch span {
    font-size: 13px;
    display: block;
    margin: 0 0 7px;
    font-weight: 600;
    color: #7d7d7d;
}

.topSearch p {
    font-size: 12px;
    display: inline-block;
    margin-right: 15px;
}

.privacysentence h4 {
    text-align: center;
    color: #757575;
    font-size: 18px;
    margin: 0px 0 15px;
}

.privacyselect {
    padding: 0px 80px;
    text-align: justify;
}

.privacysentence p {
    font-size: 14px;
    margin: 0 0 25px;
    line-height: 24px;
    text-align-last: center;
}

.privacysentenceLast p {
    text-align: center;
    color: #757575;
    margin: 0 0 5px;
}

.errorMsg {
    color: #cc0000;
    margin-bottom: 3px;
    font-size: 13px;
    text-align: center;
}


/* .errorMsg.OtpMsg, .errorMsg.OtpMsgForgot {
    color: #468600;
} */

.saveMore {
    font-size: 12px;
    position: absolute;
    top: -21px;
    right: 0;
    color: #ff0000;
    font-weight: 600;
    cursor: pointer;
}

.offerText {
    font-size: 11px;
    color: #990505;
    text-align: right;
    padding: 0 2px 0 0;
    font-weight: 600;
    position: absolute;
    top: 15px;
    right: 10px;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    width: 95%;
}

body .offerText img {
    width: 16px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    display: inline-block;
}

.showMoreDiv {
    text-align: center;
    margin: 35px 0 0;
    display: none;
}

body .showMore {
    background: var(--header-button-bg);
    color: var(--header-button-text);
    padding: 7px 30px;
    display: inline-block;
    font-size: 13px;
    border-radius: 30px;
    cursor: pointer;
}

.saveMoreDiv {
    width: 80%;
}

.saveMoreContent {
    padding: 15px;
    clear: both;
    overflow: hidden;
}

.saveMoreItem {
    width: 25%;
    float: left;
    padding: 0;
}

.saveMoreSlider {
    float: left;
    width: 75%;
    clear: none;
    padding: 0;
}

.cartButton {
    display: block;
    font-size: 11px;
    color: var(--common-button-color);
    padding: 7px 10px;
    background: var(--common-button-bg);
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: 100%;
    transition: 0.5s width;
    z-index: 1;
    position: relative;
    font-weight: 600;
    transform: scale(1.07);
}

.addCartButton {
    margin: 15px 0 0;
    position: relative;
}

.addcartbtn {
    border: 1px solid var(--common-button-bg);
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    max-width: 230px;
    z-index: 1;
    background: #fff;
}

.cart_clk {
    width: calc(100% - 95px);
}

.qty {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    color: #416AA6;
}

.dec,
.inc {
    cursor: pointer;
    width: 22px;
    display: inline-block;
    color: var(--common-button-color);
    background: var(--common-button-bg);
    text-align: center;
    padding: 0;
    line-height: 20px;
    height: 22px;
    border-radius: 5px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.cartNumber {
    width: 35px;
    display: inline-block;
    text-align: Center;
    font-size: 12px;
    color: #000;
    padding: 7px 0;
}

body .cartInput {
    text-align: center;
    width: 100%;
    border: 0;
    outline: 0;
    user-select: none;
}

.registrationTextBottom {
    margin-top: 5px;
}

.loginFormCheckBox .inputTextField {
    width: 58%;
}

.loginCaptche {
    width: 42%;
    display: inline-block;
    padding-left: 10px;
}

.captcheText {
    background: #ddd;
    padding: 4px 5px;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 600;
    display: inline-block;
    height: 30px;
    vertical-align: top;
    color: #000;
    width: 73%;
    text-align: center;
}

body .refreshIcon {
    float: right;
    margin: 6px 0 0;
    cursor: pointer;
}

.loginFormListText {
    font-size: 11px;
    color: #5a5a5a;
}

.loginFormListText label {
    border-color: #383838;
}

.loginFormListText label:after {
    border: 2px solid #383838;
    border-top: none;
    border-right: none;
}

.itemAlertText {
    position: absolute;
    bottom: 3px;
    left: 0;
    font-size: 11px;
    right: 0;
    text-align: center;
    color: #ff0000;
    font-weight: 600;
    display: none;
}

.itemAdded {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(237, 3, 2, 0.16);
    pointer-events: none;
    display: none;
}

.itemAddedbg {
    background: rgba(202, 200, 200, 0.514);
}

.itemDisplay {
    display: block;
}

.itemAddCount {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 50px;
    font-weight: 900;
    color: #fff;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.68);
}

.homeTab .productList {
    width: 16.666%;
}

.cartDiv .cartButton {
    background: transparent;
    padding: 0;
    text-align: right;
}

.cartDiv .checkoutButton {
    padding: 5px 30px;
}

.userdropdown {
    position: absolute;
    right: 0;
    background: #fff;
    width: 210px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    text-align: left;
    z-index: 1;
}

.userNameTitle {
    font-size: 15px;
    padding: 7px 15px;
    background: #d6d6d6;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
}

.userDropLink a {
    text-decoration: none;
    display: block;
    padding: 5px 15px;
    color: #464646;
    font-size: 13px;
}

.userDropLink {
    padding: 5px 0;
}

body .logoutButton {
    display: block;
    text-align: center;
    font-size: 12px;
    padding: 8px 0;
    cursor: pointer;
    text-transform: uppercase;
    background: var(--header-button-bg);
    color: var(--header-button-text);
    text-decoration: none;
}

.userDropButton {
    padding: 0px 15px 15px;
}

.saveMoreItem .productList {
    width: 100%;
    padding: 0;
}

.saveMoreContent .productItem {
    min-height: 330px;
    /* max-height: 350px; */
    padding: 0;
}

.portal {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
}

.itemProfile img {
    background: #ffffff;
}

.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
    border-radius: 4px;
}

.pagination li {
    display: inline;
    font-size: 13px;
}

.pagination li a,
.pagination li span {
    position: relative;
    padding: 10px 0px;
    text-decoration: none;
    color: var(--common-color);
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    margin: 0 2px;
    display: inline-block;
    vertical-align: middle;
}

.pagination .disabled a {
    color: #777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    z-index: 1;
    color: #fff;
    background-color: var(--common-color);
    border-color: var(--common-color);
    cursor: default;
}

.paginationSection {
    text-align: center;
    margin: 40px 0 10px;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    color: var(--common-color);
    background-color: #eee;
    border-color: #ddd;
}

.mobileSearch,
.mainButton img,
.mobileCategory {
    display: none;
}

.homeHeader {
    padding: 15px 30px;
    float: left;
    width: 100%;
    background: #ffffff;
    box-shadow: 1px 1px 10px #d6d6d6;
    position: relative;
    z-index: 1;
}

.homeHeaderName {
    float: left;
    font-size: 24px;
    font-weight: 600;
}

.homeHeaderName img {
    width: 35px;
    vertical-align: middle;
    display: inline-block;
    margin: 0 10px 0 0;
}

.homeContentWrap {
    clear: both;
}

.homeHeader .storeNumber {
    padding: 5px 0 0;
    font-size: 20px;
}

.homeHeader .callIcon img {
    width: 20px;
}

.homeBanner {
    position: relative;
}

.homeBanner img {
    width: 100%;
}

.homeBannerContent {
    position: absolute;
    left: 0;
    right: 0;
    width: 80%;
    margin: auto;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

.homeBannerContent h2 {
    color: #fff;
    font-weight: 400;
    text-shadow: 1px 1px 10px #000;
    font-size: 41px;
    margin: 0 0 15px;
}

.homeBannerContent p {
    color: #fff;
    font-weight: 400;
    text-shadow: 1px 1px 10px #000;
    font-size: 23px;
    margin: 0 0 25px;
}

.homeBannerContent .startShopping {
    display: inline-block;
}

body .startShopping,
body .startShopping:hover {
    background: var(--header-button-bg);
    color: var(--header-button-text);
    padding: 15px 45px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 2px;
    cursor: pointer;
    text-decoration: none;
}

.homeHeader .toggle {
    width: 25px;
    margin: 14px 25px 0 0;
}

.productDescriptionText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #7b7b7b;
    height: 20px;
    padding: 1px 0 0;
}

body .productReadMore {
    font-size: 11px;
    font-weight: 600;
    color: #00b0f3;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    clear: both;
    overflow: hidden;
    position: relative;
    top: -4px;
}

.productRestaurantDescription {
    line-height: 13px;
    height: 30px;
    padding: 1px 0 0;
}

.productDescriptionDiv {
    line-height: 13px;
    height: 30px;
    padding: 1px 0 0;
}

.productRestaurantPrice {
    font-size: 13px;
    font-weight: 600;
    color: var(--price-color);
    height: 25px;
    line-height: 25px;
}

.productRestaurantPrice select {
    width: 100%;
    border: 0;
    outline: 0;
    background: #e6e6e6;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;
}

.restaurantItem .addCartButton {
    margin: 20px 0 0;
}

.largeCartButton .cartButton {
    font-size: 16px;
}

.restaurantItem .saveMore {
    color: #02b14d;
}

.largeRadio label {
    width: 20px;
    height: 20px;
    top: 2px;
    margin: 0 3px 0 0;
}

.largeRadio label:before {
    width: 14px;
    height: 14px;
    background: var(--common-color);
}

body .largeCheck label {
    width: 20px;
    height: 20px;
    margin: 0 3px 0 0;
}

.largeCheck {
    width: auto;
    margin: 0;
}

.largeCheck label:after {
    width: 11.5px;
    height: 6px;
    left: 3px;
    top: 5px;
}


/************ loader ************/

.loader {
    width: 250px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-family: helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: var(--common-color);
    letter-spacing: 0.2em;
    z-index: 2;
}

.loader::before,
.loader::after {
    content: "";
    display: block;
    width: 15px;
    height: 10px;
    background: var(--common-color);
    position: absolute;
    -webkit-animation: load .7s infinite alternate ease-in-out;
    -moz-animation: load .7s infinite alternate ease-in-out;
    -ms-animation: load .7s infinite alternate ease-in-out;
    animation: load .7s infinite alternate ease-in-out;
}

.loader::before {
    top: 0;
}

.loader::after {
    bottom: 0;
}

@-webkit-keyframes load {
    0% {
        left: 0;
        height: 25px;
        width: 15px;
    }

    50% {
        height: 8px;
        width: 40px;
    }

    100% {
        left: 235px;
        height: 25px;
        width: 15px;
    }
}

@keyframes load {
    0% {
        left: 0;
        height: 25px;
        width: 15px;
    }

    50% {
        height: 8px;
        width: 40px;
    }

    100% {
        left: 235px;
        height: 25px;
        width: 15px;
    }
}


/************ loader ************/

.popMediumDiv {
    width: 750px;
}

.popMediumDiv {
    width: 750px;
}

.customizeContent {
    padding: 25px 50px 25px 50px;
}

.restaurantText {
    font-size: 14px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: 0.5px;
}

.cart .cartButton {
    display: block;
}

.cart .addcartbtn {
    height: 22px;
    width: 81px;
}

.cart .qty {
    right: 0;
}

.cart .cartNumber {
    padding: 0;
}

.customizeItemName {
    color: #000;
    font-size: 24px;
    padding: 0 0 25px;
}

.customizeItemExtra h4 {
    font-size: 15px;
    color: #3c3c3c;
    margin-bottom: 15px;
    font-weight: 400;
}

.customizeItemExtra ul {
    padding: 0 0 0 15px;
    list-style: none;
    margin: 0;
}

.customizeItemExtra ul li {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 0 0 10px;
}

.customizeExtraName {
    display: inline-block;
    width: 93%;
    padding: 0 0 0 10px;
    font-size: 14px;
    font-weight: 300;
    vertical-align: top;
}

.customizePrice {
    text-align: right;
    padding: 30px 0 10px;
}

.customizePriceText {
    font-size: 22px;
    font-weight: 300;
    padding-right: 25px;
}

.restaurantItem .productImage img {
    width: 100%;
}

.restaurantItem .productImage {
    padding: 30px 15px 5px;
}

.vegFood,
.nonVegFood {
    display: inline-block;
    width: 17px;
    height: 17px;
    border: 1px solid #00923f;
    position: relative;
    margin: 0 0 0 4px;
}

.restaurantFoodType {
    text-align: right;
    margin-top: -7px;
    line-height: 19px;
    min-height: 20px;
}

.vegFood:after,
.nonVegFood:after {
    content: "";
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-radius: 50%;
    background: #00923f;
}

.nonVegFood {
    border-color: #da251e;
}

.nonVegFood:after {
    background: #da251e;
}

.wishList input[type="checkbox"] {
    display: none;
}

.wishList img {
    width: 17px;
    opacity: 0.5;
}

.wishListActive img {
    opacity: 1;
}

.wishList {
    position: absolute;
    left: 15px;
    top: 5px;
    line-height: 17px;
    cursor: pointer;
}

.wishList label {
    margin: 0;
    cursor: pointer;
    opacity: 0.5;
}

.wishList input[type="checkbox"]:checked+label {
    opacity: 1;
}

.spicySec img {
    width: 15px;
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.spicySec-cake img {
    width: 20px;
}

.spicySec {
    display: inline-block;
    position: relative;
    margin: 0 0 0 4px;
}

.productItemRight .addcartbtn {
    height: 24px;
    width: 81px;
    float: right;
}

.productItemRight .cartNumber {
    padding: 0;
}

.productItemRight .qty {
    right: 0;
    top: 20px;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
}

.dashboardmenu {
    width: 20%;
    display: inline-block;
    vertical-align: top;
    padding: 20px 0 0;
    background: #f7f7f7;
    min-height: 750px;
}

.dashboardContent {
    width: 80%;
    display: inline-block;
    vertical-align: top;
    padding: 20px 0px;
}

.dashTitle {
    font-size: 30px;
    color: #000000;
    font-weight: 300;
    padding: 0 30px 25px;
    /* padding-left: 0; */
}

.dashSection {
    background: #fff;
    padding: 0 30px;
}

.dashboardmenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dashboardmenu li a {
    font-size: 15px;
    text-decoration: none;
    padding: 9px 5px 9px 35px;
    display: block;
    color: #424242;
    font-weight: 300;
    outline: 0;
}

.dashboardmenu .logoutLink a {
    color: var(--common-color);
    font-weight: 600;
}

.dashboardmenu li li a {
    padding: 7px 5px 7px 70px;
    font-size: 14px;
}

.dashboardActive {
    background: #e0f5f3;
}

.dashboardActive:after {
    content: "";
    background: var(--common-color);
    left: 0;
    width: 8px;
    top: 0;
    bottom: 0;
    position: absolute;
}

.dashboardmenu li {
    position: relative;
}

.adressButtonTop {
    padding-top: 50px;
}

.logoutLink {
    padding-top: 30px;
}

.radioSectionRight {
    padding-top: 6px;
}

.radioSectionRight .radioSection {
    width: 80px;
    display: inline-block;
}

.analyticSection select {
    padding: 10px;
    margin: 0 15px 0 0;
    font-size: 14px;
}

.analyticSection {
    font-size: 16px;
}

.addressFormFull {
    width: 100%;
}

.fileUploadSec input[type="file"] {
    display: none;
}

.fileUploadSec label {
    border: 1px solid #8c8c8c;
    padding: 5px 25px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    margin: 0;
}

.fileUploadSec {
    display: inline-block;
}

.fileUploadSec label:hover {
    background: var(--common-color);
    border-color: var(--common-color);
    color: #fff;
}

.dashBoardCenterText {
    text-align: center;
    font-size: 14px;
    color: #5f5f5f;
}

.searchSectionDiv {
    padding: 25px 0px 0px;
    margin-left: -7px;
    margin-right: -7px;
}

.searchItemDiv {
    width: 16%;
    display: inline-block;
    padding: 7px;
    vertical-align: top;
}

body .searchInputField {
    width: 100%;
    height: 35px;
    background: #f5f5f5;
    border: 1px solid #e6e6e6;
    font-size: 12px;
    padding: 2px 12px;
    border-radius: 5px;
    outline: 0;
}

.monthlyItem {
    display: inline-block;
    width: 20%;
    padding: 10px;
    vertical-align: top;
}

.monthlyItemLarge {
    display: inline-block;
    width: 40%;
    padding: 10px;
    vertical-align: top;
}

.monthlyItem label,
.monthlyItemLarge label {
    display: block;
    margin: 0 0 5px;
    font-weight: 600;
    font-size: 14px;
    color: #000;
}

.monthlyList {
    background: #f1f1f1;
    margin: 15px 0;
}

.monthlyItem span {
    font-size: 13px;
}

.monthlyItem select,
.monthlyItemLarge select {
    width: 100%;
    padding: 1px 10px;
    border: 1px solid rgba(151, 151, 151, 0.37);
    height: 33px;
    outline: 0;
    border-radius: 5px;
    color: #404040;
    font-size: 12px;
    font-weight: 400;
}

.trackOrderList {
    margin: 0 0 20px;
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px;
}

.storeTrack {
    font-size: 15px;
    font-weight: 600;
    color: #000;
}

.storeTrackDate {
    font-size: 12px;
    color: #848484;
}

.storeTrackDate span {
    display: inline-block;
    margin: 0 30px 0 0;
}

.trackPath {
    margin: 20px 0 0;
    display: flex;
    align-items: flex-start;
}

.trackPath span {
    display: inline-block;
    width: 11%;
    font-size: 12px;
    position: relative;
    padding: 20px 0 0;
    color: #5f5f5f;
    font-weight: 600;
}

.trackPath span:before {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    background: #b7b7b7;
    border-radius: 50%;
    z-index: 1;
    top: 0;
    left: 0;
}

.trackPath span:after {
    content: "";
    position: absolute;
    left: -100%;
    width: 100%;
    top: 6px;
    height: 2px;
    background: #b7b7b7;
}

.trackPath span:first-child:after {
    display: none;
}

.trackPath .trackActive:before,
.trackPath .trackActive:after {
    background: var(--common-color);
}

.trackPath .trackActive {
    color: var(--common-color);
}

body .cartdisable {
    pointer-events: none;
    opacity: 0.6;
}

.cartEmpty {
    height: auto;
    padding: 0;
    font-size: 14px;
    text-align: center;
    color: #8a8a8a;
    font-style: italic;
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

.cartEmpty span {
    display: block;
    margin: 0 0 15px;
}

.cartEmpty img {
    width: 80px;
    opacity: 0.3;
}

.faceBook span {
    display: block;
}

.faceBook .kep-login-facebook.metro {
    padding: 0;
    text-transform: capitalize;
    height: 33px;
    font-size: inherit;
    width: 100%;
    outline: 0;
}

.google button {
    height: 34px;
    box-shadow: none !important;
    font-size: inherit !important;
    border: 0 !important;
    display: block !important;
    text-align: center;
    width: 100%;
    outline: 0;
}

.google * {
    padding: 0 !important;
}

.google span {
    font-size: inherit;
}

.google div {
    display: none;
}

.productItemLeft .productBrand {
    padding-top: 2px;
}

.productItemLeft .productItemPrice {
    padding-top: 4px;
}

.dashboardTable .ReactTable .rt-thead .rt-tr,
.dashboardTable .ReactTable .rt-tbody .rt-td {
    text-align: left;
    font-size: 13px;
    font-weight: 600;
}

.dashboardTable .ReactTable .rt-thead .rt-th,
.dashboardTable .ReactTable .rt-thead .rt-td,
.dashboardTable .ReactTable .rt-tbody .rt-td {
    padding: 10px;
}

.dashboardTable .ReactTable .rt-tbody .rt-td {
    font-weight: 400;
}

body .dashButton {
    padding: 5px 10px;
    display: block;
    text-align: center;
    background: var(--common-color);
    margin: 0 0 4px;
    border-radius: 5px;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

.nodataItem {
    border: 1px solid rgba(151, 151, 151, 0.37);
    text-align: center;
    padding: 70px 0;
    border-radius: 5px;
    font-size: 16px;
    color: #848484;
}

.contactButton {
    border: 1px solid var(--common-button-bg);
    border-radius: 5px;
    overflow: hidden;
    max-width: 230px;
    font-size: 11px;
    color: var(--common-button-color);
    padding: 7px 10px;
    background: var(--common-button-bg);
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    width: 100%;
    z-index: 1;
    position: relative;
    font-weight: 600;
    text-transform: uppercase;
}

.listingBanner img {
    width: 100%;
}

.itemCoverDiv {
    padding: 0 0 40px;
}

.priceRanger {
    padding: 15px;
}

.priceRanger .rc-slider-track {
    background: var(--common-color);
}

.priceRanger .rc-slider-handle {
    border-color: var(--common-color);
}

.priceRanger .rc-slider-rail {
    background-color: #e4e4e4;
}

.priceRanger .rc-slider-handle:active,
.priceRanger .rc-slider-handle:hover,
.priceRanger .rc-slider-handle:focus {
    border-color: var(--common-color);
    box-shadow: 0 0 5px var(--common-color);
}

.dashboardToggle,
.dashBoardMenuMobile {
    display: none;
}


/*** Invoice Page ***/

.invoice-div {
    padding-left: 0;
    width: 750px;
    margin: 0 auto 40px;
}

.invoice-div .orderSection .productItemList {
    border-bottom: 1px solid #eaeaea;
    padding: 5px 5px 8px 20px;
}

.invoice-div .orderSection {
    border: 2px solid #efefef;
    background: #fff;
}

.invoice-div .titleHeading {
    padding-left: 0;
}

body .invoice-div .continueButton {
    padding: 5px;
    border: 1px solid var(--common-color);
    border-radius: 17px;
}

body .invoice-div .summaryButtonSection {
    margin-top: 15px;
}

.invoice-div .orderSummaryPrice {
    border-bottom: 0;
}

.invoice-div .orderSection .shopName {
    font-size: 19px;
    font-weight: 600;
    width: 50%;
    padding: 0 0 0 10px;
}

.invoice-div .shopLogo img {
    width: 95px;
}

.invoice-div .shopName {
    padding: 18px 0 0 15px;
}

.invoice-div .orderSection {
    margin: 0;
}

.invoice-div .productItemLeft {
    width: 60%;
}

.invoice-div .productItemRight {
    width: 40%;
}

body .commonActionButton {
    padding: 5px 20px;
    background: #5bc0de;
    border-radius: 5px;
    line-height: 21px;
    display: inline-block;
    height: 35px;
    cursor: pointer;
    margin: 0 5px 0 0;
}

body textarea.inputTextField {
    height: 100px;
    resize: none;
}

.dashboradButton {
    margin: 10px 0 20px;
}

.addressLeftForm {
    display: inline-block;
    vertical-align: top;
}

.addressRightShow {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    padding: 30px 15px;
}

.addressListFull {
    width: 100%;
    display: block;
    margin: 0;
}

.addressRightShow .slick-dots li button:before {
    display: none;
}

.addressRightShow .slick-dots li button {
    background: #cacaca;
    border-radius: 50%;
    width: 11px;
    height: 11px;
    margin: auto;
}

.addressRightShow .slick-dots li {
    margin: 0;
    text-align: center;
    cursor: default;
    width: 17px;
    height: 17px;
    padding: 3px 0 0;
}

.addressRightShow .slick-dots li.slick-active button {
    background-color: var(--common-color);
}

.headerDropLink {
    float: left;
    margin: 0 0 0 20px;
}

.headerDropText {
    float: left;
    padding: 0 25px 13px 0;
    position: relative;
    margin: 0 20px 0 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    cursor: pointer;
}

.headerDropText .categoryArrow {
    top: 7px;
}

.elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.headerDropText p {
    color: inherit;
    margin: 0;
    max-width: 200px;
    font-size: 12px;
}

.headerDropText span {
    font-size: 11px;
    text-transform: initial;
    color: #777777;
    font-weight: 400;
    position: absolute;
    top: 19px;
}

.itemCategory {
    padding: 30px;
    clear: both;
    overflow: hidden;
}

.storeTitle {
    font-size: 45px;
    color: #000000;
    margin: 0;
    padding-bottom: 30px;
    font-weight: 300;
    text-align: center;
}

.storeListDiv {
    margin: 50px 0;
}

.storeTitle span {
    color: var(--common-color);
    font-weight: 400;
}

.storeList {
    width: 33.333%;
    display: inline-block;
    vertical-align: top;
    padding: 5px 5px;
}

.storeList a {
    display: block;
    text-decoration: none;
}

.storeListContent {
    clear: both;
    overflow: hidden;
    border: 1px solid #ddd;
    background: #fff;
    cursor: pointer;
    /* min-height: 140px; */
}

.storeListLogo {
    width: 35%;
    display: inline-block;
    padding: 0px 10px;
    position: relative;
    /* min-height: 140px; */
}

.storeListLogo img {
    width: 100%;
}

.storeListDetails {
    width: 65%;
    line-height: 23px;
    display: inline-block;
    padding: 20px 13px 0;
    border-left: 1px solid #ddd;
    min-height: 120px;
    vertical-align: middle;
}

body .comingSoon {
    border-radius: 3px;
    font-size: 13px;
    border: 1px dashed rgb(247, 167, 0);
    color: #f7a700;
    padding: 2px 10px;
    display: inline-block;
    margin: 2px 0 0;
}

.storeListName {
    font-size: 19px;
    font-weight: 500;
    color: #000;
    margin: 0 0 5px;
}

.storeListLocation {
    font-weight: 300;
    font-size: 14px;
    color: #484848;
    margin: 0 0 5px;
}

.storeRightCategory {
    font-size: 11px;
    color: #696969;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.storeListTime img {
    width: 14px;
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: text-bottom;
}

.storeListTime {
    font-size: 11px;
    color: #696969;
}

.priceRangerDiv {
    width: 300px;
    margin: 100px;
}

.priceRangerDiv .input-range__track,
.priceRanger .input-range__track {
    background: #dedede;
}

.priceRangerDiv .input-range__slider,
.priceRanger .input-range__slider {
    background: var(--common-color);
    border-color: var(--common-color);
}

.priceRangerDiv .input-range__track--active,
.priceRanger .input-range__track--active {
    background: var(--common-color);
}

.priceRangerDiv .input-range__label,
.priceRanger .input-range__label {
    color: #000000;
}

.bookTable {
    width: 750px;
}

.popFormHalf {
    width: 50%;
    display: inline-block;
}

.formImage {
    width: 18px;
    position: absolute;
    left: 35px;
    top: 7px;
    z-index: 1;
    pointer-events: none;
}

body .formInputLabel {
    font-size: 13px;
    width: 70px;
    margin: 0;
    color: #525252;
    vertical-align: top;
}

.formInputLabel sup {
    color: red;
    padding: 0 0 0 4px;
}

.popFormList {
    margin: 0 0 10px;
}

.popFormHalf .formInputField {
    padding-left: 30px;
}

.popFormList .formInputTextarea {
    height: 75px;
}

.formWidthSection {
    display: inline-block;
    position: relative;
    padding: 0 30px;
    width: 580px;
}

.popFormHalf .formWidthSection {
    width: 255px;
}

.bookButtonSection {
    border-top: 1px solid #ddd;
    padding: 20px 50px;
}

.bookButtonSection p {
    color: red;
    font-size: 12px;
    width: 50%;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
}

body .mainPopButton {
    font-size: 14px;
    color: var(--common-button-color);
    padding: 7px 20px;
    background: var(--common-button-bg);
    float: right;
    cursor: pointer;
}

.innerBanner {
    background: #fff;
    margin-bottom: 10px;
}

.innerBanner .bannerLogo,
.innerBanner .listBanner {
    padding: 0;
}

.bannerLogo {
    text-align: center;
    background: #fff;
    padding: 0px 10px 0px 25px;
}

.bannerLogo h4 {
    font-size: 22px;
    font-weight: 400;
    color: #000;
    margin: 0;
    line-height: 36px;
}

.bannerLogo p {
    font-weight: 300;
    font-size: 19px;
}

.innerBanner .carousel-inner>.item>a>img {
    max-width: 100%;
    min-width: 100%;
}

.bannerItemDiv {
    position: relative;
}

.searchFieldHide {
    opacity: 0;
    z-index: -9999;
    pointer-events: none;
}

.filterListName {
    font-weight: 700;
}

.saveMoreContent .saveMoreItem .productList {
    width: 100%;
}

.saveMoreContent .slick-track {
    margin-left: 0;
}

.productItemRight-top {
    margin-top: 0px;
}

.summaryDetailLeft-textarea {
    clear: both;
}

.titleHeading-succes {
    color: green;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    padding: 30px 0 0;
}

.orderPriceRight-wdth100 {
    width: 100%;
}

.shopNameLeft {
    width: 62%;
    float: left;
}

.shoporderNumberr {
    width: 105px;
    float: right;
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
}

.shopTitle .shoporderNumberr {
    width: 28%;
    text-align: right;
    font-weight: 600;
}

.invoice-div .shopTitle .shoporderNumberr {
    width: 37%;
    margin: 1px 0 0;
    font-size: 17px;
}

.invoice-div .productItemDetailsInvoice .productItemLeft {
    width: 45%;
}

.invoice-div .productItemDetailsInvoice .productItemRight {
    width: 25%;
}

.invoice-div .productItemDetailsInvoice .productItemLeftCenter {
    width: 30%;
}

.searchList .addcartbtn {
    max-width: 90px;
}

body .tagActive {
    background: var(--category-bg);
    color: var(--category-color);
}

.priceRanger .input-range__label-container {
    display: none;
}

.priceRanger .input-range__track .input-range__label-container {
    display: block;
}

.cartProductSection .loading-padding {
    padding: 45px 0;
    color: var(--common-color);
}

.orderPriceFull {
    font-size: 14px;
    padding-bottom: 2px;
}

.invoice-div .priceListTotal {
    padding: 0 0 0;
}

.headerDropText svg,
.headerImage svg,
.headerDropLink svg {
    height: 30px;
    margin: 0 0 0 5px;
    font-size: 20px;
    vertical-align: middle;
}

.headerCartBtn {
    background: var(--category-bg);
    color: var(--category-color);
    ;
    padding: 5px 15px;
    border-radius: 5px;
    position: relative;
}

.cartCount {
    font-size: 16px;
    width: 26px;
    height: 26px;
    top: -10px;
    right: -13px;
    line-height: 25px;
}

.headerIcons {
    margin: 0 0 0 25px;
    vertical-align: middle;
}


/* .cartDefault img {
    width: 100%;
    height: auto;
} */

.cartDefault {
    text-align: center;
}

body .cartDefault img {
    width: 100%;
    height: 300px;
    object-fit: contain;
}

.locationTextDiv {
    display: block;
    font-size: 12px;
    color: var(--header-text-color);
    padding: 4px 0px 0 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    right: 0;
    max-width: 200px;
}

.headerCartBtn svg {
    margin: 0;
}

.logo a {
    outline: 0;
    display: block;
}

.addressSection {
    padding: 10px 0 10px 20px;
}

.orderSection {
    /* margin: 0 -10px; */
    margin: 0px;
}

.searchItemDiv .commonButton {
    height: 35px;
    padding: 8px 30px;
}

.sugCategoryDrop {
    display: none;
}

body .filterCategoryName {
    cursor: pointer;
}

body .no-item-found {
    padding: 30px 5px;
    font-size: 22px;
    text-align: center;
    color: red;
    display: block;
    margin: 0;
}


/*** html Table***/

.dashboardTableMain {
    overflow-X: auto;
    width: 100%;
    padding: 30px 0 0;
}

.dashboardTableMain .table {
    text-align: left;
    font-size: 13px;
}

.dashboardTableMain .table thead {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.dashboardTableMain .table th {
    font-weight: 600;
}


/*** html Table***/

.storeContentDiv {
    padding: 0 30px 30px;
    text-align: center;
}

.storeContentHeight {
    max-height: 400px;
    overflow-y: auto;
}

.saveMoreSlider .slick-next {
    right: 0;
}

.saveMoreSlider .slick-prev {
    left: 0 !important;
}

body .otpButton {
    padding: 6px 15px;
    display: inline-block;
    font-size: 12px;
    background: #ff8d00;
    color: #fff;
    border-radius: 3px;
    margin: 0;
    cursor: pointer;
    font-weight: 600;
    width: 115px;
    text-align: center;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
}

.profilePackQty {
    display: inline-block;
    min-width: 45px;
}

.profilePackQtyPrice {
    margin: 0 0 0 5px;
}

.orderSummaryPriceSection {
    background: #fff;
    margin: -7px 0 5px;
    border: 1px solid #e0e0e0;
    padding: 0 0 20px;
    border-left: 0;
    border-right: 0;
}

.orderSummaryPriceSection .orderSummaryPrice {
    border-top: 0;
}

.adressActive {
    width: 30px;
    height: 30px;
    padding: 5px;
    background: var(--common-button-bg);
    border-radius: 50%;
    right: 5px;
    top: 5px;
    position: absolute;
    text-align: center;
    color: #fff;
    line-height: 21px;
    font-size: 15px;
    display: none;
}

.addressListActive {
    border-color: var(--common-color);
}

.addressListActive .adressActive {
    display: block;
}

.reorder-td-img {
    display: inline-block;
    vertical-align: top;
}

.reorder-td-desc {
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
}

.reorder-td-desc .reorder-td-desc-head {
    font-size: 14px;
    font-weight: 500;
}

.profileDetails .itemAlertText {
    bottom: auto;
    left: auto;
    right: auto;
    text-align: left;
    margin-top: 4px;
}

.sizeWrap {
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: 30px;
    background: #fff;
    font-size: 12px;
    display: none;
    padding: 10px 7px;
    text-align: left;
    margin: 32px 0 0;
}

.sizeDiv {
    text-align: left;
    height: 20px;
    line-height: 15px;
}

.addCartButton .sizeDiv {
    height: auto;
    text-align: center;
}

.itemSizeClick {
    font-size: 13px;
    color: var(--common-color);
    display: block;
    position: relative;
}

.addCartButton .itemSizeClick {
    text-align: center;
}

.sizeList {
    display: inline-block;
    padding: 10px 0px;
    /* border: 1px dashed #9e9e9e; */
    margin: 2px;
    font-size: 11px;
    border-radius: 5px;
    width: 55px;
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    color: #000;
    /* background: #fff; */
    border: 1px solid #3771a2;
    background: #dddddd;
}

.sizeListActive {
    background: var(--common-color);
    color: #fff;
}

.productclose {
    color: #000;
    font-size: 16px;
    position: absolute;
    right: 20px;
    line-height: 19px;
    cursor: pointer;
}

.itemSizeShow {
    font-size: 12px;
    height: 20px;
    font-weight: 600;
    color: #535766;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.itemSizeShow span {
    margin: 0 0 0 5px;
}

.noDataDiv img {
    width: 150px;
}

.noDataText img {
    width: 50px;
    margin: -6px 10px 0 0;
}

.noDataText {
    color: #888;
    text-transform: uppercase;
    padding: 15px 0 35px;
}

body .otpTextField::placeholder {
    color: red;
    font-weight: bold;
}

body .otpTextField::-ms-placeholder {
    color: red;
    font-weight: bold;
}

body .otpTextField::-moz--placeholder {
    color: red;
    font-weight: bold;
}

.colorList {
    background: #fff;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin: 0 5px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px #828282;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.colorDiv {
    padding: 8px 0 0;
}

.colorItem {
    width: 14px;
    height: 14px;
    background: #ddd;
    display: inline-block;
    border-radius: 50%;
    margin: auto;
}

.blakColor {
    background: #000;
}

.whiteGradient {
    background: #ffffff;
    background: -moz-linear-gradient(top, #ffffff 0%, #000000 100%);
    background: -webkit-linear-gradient(top, #ffffff 0%, #000000 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #000000 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0);
}

.colorDivFilter .colorItem {
    width: 19px;
    height: 19px;
    display: block;
}

.colorDivFilter .colorList {
    display: flex;
    margin: 0 7px 5px 0;
    width: 25px;
    height: 25px;
    box-shadow: 0px 0px 3px #828282;
    justify-content: center;
    align-items: center;
}

.colorDivFilter {
    padding: 0px 0 0 20px;
    display: flex;
    flex-wrap: wrap;
}

.isOutOfStock {
    color: red;
    width: 25%;
    float: left;
    font-size: 15px;
    text-align: center;
    padding: 23px 0 0;
    font-weight: 600;
}

.orderSection .isOutOfStock {
    width: 15%;
    padding-left: 20px;
}

.headerSearchSubOuterRestaurant {
    width: 76.6%;
}

.cartButtonRestaurant {
    padding: 7px 3px;
}

.cartButtonRestaurant.cart_clk {
    width: calc(100% - 90px);
}


/***** order management *****/

.orderm-coverWraper .contentWrap {
    padding-top: 110px;
}

.orderm-search-wrapper {
    width: 90%;
    margin-top: 30px;
}

.orderm-search-wrapper .formImage {
    right: 20px;
    left: auto;
}

.orderm-ContentForm .date-formImage {
    bottom: 24px;
    top: auto;
    right: 30px;
    left: auto;
}

.orderm-field {
    height: 35px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
    padding: 2px 6px;
    border: 1px solid rgba(151, 151, 151, 0.37);
    margin: 0 0 15px;
    background: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    width: 100%;
}

textarea.orderm-field {
    height: 100px;
    resize: none;
}

.orderm-search-wrapper .borderButton {
    padding: 8px 30px;
}

body .border-radius-3 {
    border-radius: 3px;
    color: var(--common-button-bg);
    padding: 8px 10px;
    background-color: #fff;
}

body .border-radius-25 {
    border-radius: 25px;
    color: var(--common-button-bg);
    padding: 8px 10px;
    background-color: #fff;
}

.orderm-bordertop {
    border-bottom: 1px solid #b9b9b9;
    margin: 10px 0;
}

.orderm-table table td {
    vertical-align: middle;
}

.orderm-table .table thead {
    box-shadow: none;
}

.orderm-adr-left {
    border: 1px solid #dcdcdc;
    position: relative;
    font-size: 12px;
}

.orderm-addressHoldr {
    float: left;
    width: 300px;
    height: auto;
    margin-bottom: 5px;
    padding-left: 10px;
}

.orderm_buyers_name {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    max-width: 250px;
    margin-top: 5px;
}

.orderm_buyers_adrs {
    clear: both;
    color: #000;
    display: block;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: 1.4em;
    text-align: left;
    max-width: 250px;
}

.orderm_buyers_phN {
    color: #ad0101;
    font-size: 11px;
    font-weight: 600;
}

.orderm_buyers_Eml {
    color: #ad0101;
    font-size: 11px;
    font-weight: 600;
}

.orderm_shop {
    overflow: hidden;
    clear: both;
    padding: 3px 0;
    font-size: 11px;
    color: #19736b;
}

.orderm-printLabelDiv {
    display: inline-block;
    position: relative;
}

.orderm-printLabelDiv .orderm-printMode {
    text-align: left;
    cursor: pointer;
    color: var(--common-color);
    font-weight: 400;
    font-size: 12px;
    padding-right: 22px;
}

.orderm-printLabelSize {
    display: none;
    position: absolute;
    width: 130px;
    top: 0;
    background: #fff;
    left: 75px;
}

a.orderm-adressBtnLink {
    display: inline-block;
    color: var(--common-color);
    padding: 1px 13px;
    font-size: 10px;
    border: 1px solid;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
}

.orderm-printLabelDiv:hover .orderm-printLabelSize {
    display: block;
}

.orderm-deliverySlot {
    background: var(--common-button-bg);
    padding: 2px 5px 4px 20px;
    width: auto;
    display: block;
    text-align: center;
    float: right;
    color: #fff;
    height: 18px;
    position: absolute;
    right: 0;
    font-size: 10px;
}

.orderm-deliverySlot:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 0 9px 10px;
    border-color: transparent transparent transparent #ffffff;
    position: absolute;
    left: 0;
    top: 0;
}

.orderm-addressHoldrSts {
    width: 125px;
    height: auto;
    display: block;
    text-align: center;
    position: absolute;
    right: 0;
    font-size: 10px;
    top: 20px;
}

.orderm-status {
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 100%;
    clear: both !important;
    text-align: center;
    font-size: 14px;
}

.orderm-green {
    color: green;
}

.orderm-invoice {
    display: block;
    margin-bottom: 2%;
    margin-top: 2%;
    width: 100%;
    clear: both !important;
    color: #000;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline !important;
    text-align: center;
    background: 0;
    border: 0;
}

.orderm-paymentDetailDiv {
    clear: both;
    padding-top: 10px;
}

.orderm-apaymentMode {
    display: block;
    text-align: center;
    color: #ff4e0a;
    font-size: 12px;
    padding-top: 4px;
    font-weight: 600;
}

.orderm-couponBox {
    width: 98%;
    clear: both;
    height: auto;
    background-color: #f2f2f2;
    padding: 1%;
    margin: 1%;
    overflow: hidden;
}

.orderm-couponfieldholder {
    float: left;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
}

.orderm-ContentDiv {
    padding: 0 30px 30px;
    text-align: right;
}

.orderm-orderReview .loginPop {
    width: 80%;
}

.orderm-table-amt {
    font-weight: 600;
}

.orderm-table-tr-active {
    background-color: rgba(67, 176, 104, .2);
}


/* #orderm-table-list td {
    cursor: pointer;
} */

.orderm-paginationSection {
    text-align: right;
    margin-top: 0;
}

.orderm-paginationSection .pagination li {
    text-align: center;
}

.orderm-coverWraper .pagination li a,
.orderm-coverWraper .pagination li span {
    height: 35px;
    width: 35px;
    padding: 6px 0px;
}

.orderm-storeSelect-main {
    position: relative;
    margin-top: 10px;
    cursor: pointer;
}

.orderm-storeSelect {
    padding: 0;
    font-size: 17px;
    color: var(--common-color);
    font-weight: 600;
}

.userdropdown.orderm-storedropdown {
    left: 0;
    right: auto;
}

.orderm-storedropdown .userDropLink a:hover {
    cursor: pointer;
    color: var(--common-color);
}

.orderm-search-wrapper .react-datepicker-wrapper {
    width: 100%;
}

.orderm-coverWraper .sideMEnu {
    background-color: #000;
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 60px;
    overflow: hidden;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.orderm-coverWraper .sidemenu-close {
    display: none;
}

.orderm-coverWraper .sideMEnu ul {
    padding: 0;
    margin: 85px 0 0;
    list-style: none;
    max-height: 570px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 279px;
}

.orderm-coverWraper .sidetoggle {
    padding: 5px;
    overflow: hidden;
    transition: all .5s ease-in-out;
    display: none;
    background-color: rgba(0, 0, 0, 0.2);
    width: 30px;
    margin-top: 7px;
    margin-left: 5px;
}

.orderm-coverWraper .sidetoggle img {
    width: 20px;
    margin-top: -4px;
}

.orderm-coverWraper .sideMEnu ul li {
    /* padding-bottom: 5px; */
    clear: both;
    overflow: hidden;
    font-weight: 500;
}

.sideMEnu ul li:active {
    background-color: #696969;
}

.orderm-coverWraper .sideMEnu ul a {
    text-decoration: none;
    color: #fff;
    padding: 8px 10px 8px 18px;
    clear: both;
    cursor: pointer;
    display: block;
    overflow: hidden;
}

.orderm-coverWraper .sideMEnu ul img {
    width: 22px;
    height: auto;
    float: left;
}

.orderm-coverWraper .sideMEnu ul span {
    font-size: 14px;
    vertical-align: top;
    padding: 4px 0 0 25px;
    float: left;
    width: 212px;
}

.sideMEnu-ul li.active {
    /* background: #18786f */
    /* background: #ffffff63; */
    border-left: 5px solid #18786f;
}

.contentSection {
    padding: 15px 10px 10px 75px;
}

.orderm-coverWraper .sideMEnu ::-webkit-scrollbar {
    width: 8px;
    border-radius: 30px;
}

.orderm-coverWraper .sideMEnu ::-webkit-scrollbar-thumb {
    background-color: #efefef;
}

.orderm-coverWraper .sideMEnu:hover,
.sideMEnu:hover .sideLogo {
    width: 280px;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.orderm-coverWraper .sideHeading {
    color: #fff;
    font-size: 23px;
    padding: 15px 5px 15px 10px;
    margin-bottom: 30px;
}

.orderm-right-section {
    padding: 15px 10px 10px 75px;
    width: 92%;
}

.orderm-ContentForm {
    text-align: left;
    font-size: 14px;
    color: #676767;
    /* border: 1px solid rgba(151, 151, 151, 0.37);
    border-radius: 20px; */
    padding: 40px;
}

.orderm-head {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 25px;
    color: #616161;
    text-align: center;
}


/* .orderm-ContentForm .orderm-field{
    width:300px;
} */

.orderm-right-section .radioSection {
    width: auto;
    padding-right: 30px;
    font-size: 14px;
    float: left;
    color: #676767;
    margin: 0 0 15px;
}

.orderm-right-section .checkBoxSquare {
    width: auto;
    padding-right: 30px;
    margin: 0 0 15px;
}

.orderm-right-section .checkBoxSquare label {
    margin: 0 7px 0 0;
}

.orderm-formList {
    margin: 0 0 10px;
    color: #676767;
    font-size: 13px;
}

.orderm-formList .labelField {
    text-align: left;
}

.orderm-label {
    font-weight: 500;
}

.orderm-ContentForm .orderm-field {
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.storeListContentDisable {
    pointer-events: none;
}

.forgotHomeLogin .popOverlay {
    display: none;
}


/* .accountReorderDiv {
    padding: 30px 0;
} */

.trackOrder {
    padding: 30px 0;
}

.profileDetails .offerTag {
    position: relative;
    border-radius: 3px 0 0 3px;
    right: auto;
    font-size: 15px;
    height: 22px;
    padding: 0px 30px 0px 11px;
    top: auto;
    margin: 0 0 5px;
}

.profileDetails .offerTag:after {
    border-width: 11px 12px 11px 0;
    border-color: transparent #ffffff transparent transparent;
    left: auto;
    right: 0;
}

.retrievePasswordLogin {
    display: none;
}

.searchDetails .offerText {
    position: initial;
    width: 100%;
    display: block;
    padding: 0;
}

.searchDetails .offerText img {
    width: 12px;
}

.productsizeFilter {
    padding: 0px 0 0 20px;
}

.productsizeFilter .sizeList {
    font-size: 10px;
}

.productcolor h5 {
    padding: 0;
}

.profileSizechart {
    margin: 0 0 20px;
}

.profileSizechart .sizeList {
    width: auto;
    min-width: 55px;
    font-size: 15px;
}

.profileMargin {
    margin: 0 0 10px;
}

.productcolor .colorDivFilter,
.availableColor .colorDivFilter {
    padding: 0;
}

.productAboutLine h3 {
    display: inline-block;
    padding-right: 10px;
}

.productInlineData {
    display: inline-block;
    vertical-align: middle;
}

#profileSizeValidation {
    color: red;
    font-size: 20px;
    font-weight: 700;
}

#profileSizeSuccess {
    color: green;
    font-size: 20px;
    font-weight: 700;
}

.similarItemSlider .slick-track {
    margin-left: 0;
    margin-right: 0;
}

.productAboutLine {
    font-size: 13px;
    color: #7d7d7d;
}

.similarItem img {
    width: 85px;
    margin: auto;
}

.similarItem {
    padding: 10px 0 0;
    border: 1px solid #efefef;
    margin: 0 2px;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
}

.similarItemSlider .slick-dots,
.homeBanner .slick-prev,
.homeBanner .slick-next {
    display: none !important;
}

.similarPrice {
    margin: 10px 0 5px;
    font-weight: 600;
    color: var(--price-color);
    font-size: 15px;
}

.similarSlider {
    margin: 25px 0;
}

.restaurantHomeBannerItem {
    position: relative;
}

.homeBanner .slick-slide {
    margin: 0 0 -7px;
}

.restaurantSearchVeg {
    border: 0;
    width: 10%;
    float: right;
    text-align: center;
    padding: 7px 0 0;
}

.summaryDetailLeft.popFormList {
    float: right;
}

.scheduleHalf {
    width: 30%;
    padding: 0 2px;
}

.scheduleHalf .scheduleHalfSection {
    width: 100%;
    padding: 0;
}

body .react-datepicker-wrapper {
    display: block;
}

.scheduleHalfSection input.formInputField {
    width: 100%;
    padding: 2px 10px 2px 35px;
}

.scheduleHalfSection .formImage {
    left: 10px;
}

.disclaimerText {
    display: block;
    font-size: 12px;
    color: red;
    padding: 0 2px;
    width: 60%;
    float: right;
}

.fliterList h5 {
    cursor: pointer;
}

.productItemNameLeft {
    padding: 0 0 0 15px;
}

.headerSearchFullView .searchField input[type=text] {
    border: 1px solid #afafaf;
}

.productDetails a {
    text-decoration: none;
}

.headerSearchFullView .headerSearchSubOuter {
    width: 77%;
}

.deliveryTime {
    color: var(--common-color);
    padding: 5px 0px 0 0;
    font-size: 13px;
    font-weight: 600;
    text-align: right;
}

.orderSummaryBtmText {
    font-size: 12px;
    padding: 25px;
    text-align: justify;
}

.orderPriceLeft.deliveryTime {
    text-align: left;
    font-size: 12px;
    padding: 0;
}

.colorList .fa-check {
    position: absolute;
    font-size: 10px;
    color: #ddd;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    display: none;
}

.colorListActive .fa-check {
    display: block;
}

.itemAddAnimation {
    background: var(--common-color);
    color: #fff;
    padding: 8px 15px;
    border-radius: 7px;
    position: absolute;
    font-size: 12px;
    left: 0;
    right: 0;
    bottom: 0;
    font-weight: 600;
    text-align: center;
    transition: all 0.3s;
    opacity: 0;
}

.itemAddAnimationOpen {
    bottom: 125%;
    opacity: 1;
}

.profileButtonSection .itemAddAnimation {
    bottom: 15px;
    right: auto;
    top: auto;
    padding: 8px 15px;
}

.profileButtonSection .itemAddAnimation.itemAddAnimationOpen {
    opacity: 1;
    bottom: 40%;
}

.orderSummaryDisable {
    pointer-events: none;
    opacity: 0.7;
}

.cartMultipleStore .cartProductSection {
    max-height: 250px;
    overflow-y: auto;
}

.cartMultipleStore .cartItemList {
    max-height: 100%;
}

.cartMultipleStore .mrpTotalList span,
.cartMultipleStore .cartGrandTotal {
    color: #000;
}

.summaryButtonSection .cartGrandTotal {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 10px;
}

.invoice-div .priceListSummary {
    padding: 0 0 5px;
}

.mobileFilter {
    display: none;
}


/**************** cart ******************/

.cartDiv {
    position: fixed;
    left: 0;
    right: 0;
    width: auto;
    top: 78px;
    background: rgb(0 0 0 / 70%);
    border: 0;
    box-shadow: none;
    padding: 3px 0 0;
}

.cartDivSection {
    width: 900px;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    /* overflow: hidden; */
    padding: 20px;
    min-height: 300px;
    position: relative;
}

.cartContentCover {
    display: flex;
}

.cartStoreProduct {
    width: 70%;
    max-height: 450px;
    overflow-y: auto;
    padding: 0 10px 0 0;
    margin: 0 10px 0 0;
}

.cartGrandTotalDiv {
    width: 30%;
    background: #efefef;
    padding: 20px;
    border-radius: 10px;
}

.cartStoreList {
    padding: 7px 7px 0px;
}

.cartStoreListItem {
    border-bottom: 10px solid #f0f0f0;
    padding: 0 0px 10px;
}

.cartStoreList:last-child .cartStoreListItem {
    border: 0;
}

.cartStoreDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cartStoreLogo {
    display: flex;
    align-items: center;
    width: 62%;
}

body .cartStoreLogo img {
    height: 100%;
    width: 100%;
}

.cartStoreLogo a {
    height: 60px;
    width: 60px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 7px #b9b9b9;
    display: block;
    overflow: hidden;
    padding: 3px;
}

.cartStoreName {
    padding: 0 0 0 15px;
    color: #515151;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

body .cartCommonButton {
    width: 100px;
    text-transform: uppercase;
    font-size: 12px;
    background: var(--heading-color);
    color: #fff;
    display: inline-flex;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    cursor: pointer;
    text-decoration: none;
}

.cartStoreBtns {
    display: flex;
    width: 38%;
    justify-content: flex-end;
}

body .cartCommonCancel {
    background: #ee1b27;
}

.cartStoreProduct::-webkit-scrollbar-track,
.grandTotalMultiple::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.cartStoreProduct::-webkit-scrollbar,
.grandTotalMultiple::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
}

.grandTotalMultiple::-webkit-scrollbar {
    width: 2px;
    background-color: #efefef;
}

.grandTotalMultiple::-webkit-scrollbar-track {
    background-color: #efefef;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.cartStoreProduct::-webkit-scrollbar-thumb,
.grandTotalMultiple::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #5d5d5d;
}

.cartStoreProduct::-webkit-scrollbar-thumb {
    background: #b5b5b5;
}

.grandTotalMultiple::-webkit-scrollbar-thumb {
    background: #dcdcdc;
}

.cartProductItemList {
    display: flex;
    border: 1px solid #ececec;
    margin: 5px 0 14px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    /* align-items: center; */
}

.cartProductItemWrap {
    padding: 10px 0;
}

.cartProductItemImage {
    width: 14%;
    border-right: 1px solid #ececec;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
}

body .cartProductItemImage img {
    width: 100%;
    height: auto;
}

body .cartProductItemImageRestaurant img {
    height: 60px;
}

.cartProductItemDetails {
    width: 86%;
    padding: 0 45px 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

body .cartRemoveItem img {
    width: 20px;
    height: auto;
}

.cartRemoveItem {
    position: absolute;
    cursor: pointer;
    right: 10px;
}

.cartProductItemName,
.cartProductBrand {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin: 0 0 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* max-width: 305px; */
}

.cartProductItemLeft {
    width: 73%;
}

.cartProductItemLeft .newPrice {
    font-size: inherit;
    font-weight: inherit;
    margin: 0 5px 0 5px;
    color: inherit;
}

.cartProductItemPrice {
    color: var(--common-color);
    font-size: 15px;
    font-weight: 400;
}

.cartQtyBtn {
    display: flex;
    align-items: center;
    padding: 0 10px 0 0;
}

body .cartQtyInput {
    width: 35px;
    font-size: 15px;
    font-weight: 500;
    border: 0;
    height: 100%;
    text-align: center;
    outline: 0;
}

.cartQtyDec,
.cartQtyInc {
    width: 23px;
    height: 23px;
    background: var(--common-color);
    border-radius: 50%;
    /* display: flex;
    align-items: center; */
    justify-content: center;
    color: #fff;
    font-size: 25px;
    font-weight: 300;
    cursor: pointer;
    user-select: none;
    line-height: 19px;
    text-align: center;
}

.grandTotalMultiple {
    max-height: 250px;
    min-height: 250px;
    overflow-y: auto;
    padding: 0 3px 0 0;
    margin: 0 0 25px;
}

.cartPriceStoreName {
    padding: 0 0 7px;
    color: var(--heading-color);
    font-weight: bold;
    font-size: 16px;
}

.cartMrpSection {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 4px;
    font-weight: 400;
    color: #5f6c7c;
}

.cartStorePrice {
    margin: 0 0 15px;
}

.cartStorePrice:last-child {
    margin: 0;
}

.cartGrandPrice {
    font-weight: 800;
    font-size: 15px;
    text-align: right;
    margin: 0 0 10px;
    color: var(--common-color);
}

.cartGrandButtonDiv .commonButton {
    font-size: 13px;
    display: block;
    text-align: center;
    padding: 7px 5px;
}

.cartGrandButtonDiv a {
    text-decoration: none;
}


/**************** cart ******************/


/**************** order summary ****************/

.orderSummarySection {
    padding: 40px 100px;
    background: #fff;
}

.orderIndication {
    width: 500px;
    margin: 0 auto 40px;
    display: flex;
    justify-content: center;
}

.orderIndicationBar {
    width: 25%;
    background: #ebebee;
    height: 6px;
    position: relative;
    display: flex;
    align-items: center;
}

.orderIndicationCircle {
    width: 23px;
    height: 23px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px #a7a7a7;
    display: flex;
    position: absolute;
    right: -12.5px;
    z-index: 1;
    align-items: center;
    justify-content: center;
}

.orderIndicationBar:first-child {
    width: 10px;
}

.orderIndicationCircle span {
    width: 17px;
    height: 17px;
    background: #c7c7c7;
    border-radius: 50%;
    display: flex;
}

.orderIndicationActive .orderIndicationCircle span {
    background: var(--common-color);
}

.orderIndicationActive {
    background: var(--common-color);
}

.orderIndicationBar:first-child .orderIndicationCircle {
    right: -10px;
}

.checkoutTitle {
    text-align: center;
    color: #000;
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 35px;
}

.checkoutOrderHead {
    border-bottom: 1px solid #d9d9d9;
    font-size: 16px;
    text-transform: uppercase;
    padding: 0 0 5px;
    font-weight: 400;
    color: #67667a;
}

.checkoutOrderRow {
    display: flex;
    flex-wrap: wrap;
}

.checkoutItemDetail {
    display: flex;
    width: 63%;
}

.checkoutStoreColumn {
    width: 37%;
}

.checkoutItemColumn {
    width: 53%;
    display: flex;
    align-items: center;
}

.checkoutVolumeColumn {
    width: 15%;
    text-align: center;
}

.checkoutQtyColumn {
    width: 15%;
    text-align: center;
}

.checkoutPriceColumn {
    width: 32%;
    padding: 0 36px 0 0;
    text-align: center;
}

.checkoutOrderBody .checkoutOrderRow {
    padding: 70px 0;
    border-bottom: 10px solid #f0f0f0;
}

.checkOutStoreLogo {
    height: 70px;
    width: 70px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 7px #b9b9b9;
    display: block;
    overflow: hidden;
    margin: 0 auto 15px;
}

.checkOutStoreLogo img {
    width: 100%;
    height: 100%;
}

.checkOutStoreDetails {
    text-align: center;
    width: 42%;
    padding: 0 20px 0 0;
}

.checkOutStore {
    display: flex;
    padding: 0 25px 0 0;
}

.checkOutStoreDetails .cartCommonButton {
    width: auto;
    display: flex;
    margin: 0 0 8px;
}

.checkOutStoreDetails h4 {
    margin: 0 0 15px;
    font-size: 16px;
    font-weight: bold;
}

.checkOutInvoice {
    width: 58%;
    background: #efefef;
    padding: 25px 15px;
    border-radius: 10px;
}

.checkOutStoreName {
    padding: 0 0 8px;
    color: var(--heading-color);
    font-weight: bold;
    font-size: 16px;
}

.checkOutMrpSection {
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 5px;
    font-weight: 400;
    color: #5f6c7c;
}

.checkOutItemName {
    width: 75%;
    padding: 0 0 0 10px;
    color: #000;
    font-weight: 500;
    line-height: 21px;
    font-size: 15px;
}

.checkoutPriceColumn .cartProductItemPrice {
    font-size: 15px;
}

.checkoutPriceColumn .oldPrice:after {
    top: 9px;
}

.checkoutItemDetailList {
    width: 100%;
    display: flex;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    padding: 0;
    position: relative;
    align-items: center;
    margin: 0 0 20px;
}

.checkOutImage img {
    width: 100%;
}

.checkOutImage {
    width: 25%;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
    padding: 7px;
    border-right: 1px solid #ebebeb;
}

.checkoutItemColumn span {
    width: 70%;
    padding: 0 0 0 10px;
    color: #000;
    font-weight: 500;
    line-height: 21px;
    font-size: 16px;
}

body .checkoutDelete {
    position: absolute;
    cursor: pointer;
    right: 10px;
}

.checkoutDelete img {
    width: 20px;
    height: auto;
}

.checkoutQtyColumn .cartQtyBtn {
    padding: 0;
    justify-content: center;
}

.checkoutOrderBody .checkoutItemDetail {
    display: block;
    flex-wrap: wrap;
}

.checkOutQty {
    font-size: 14px;
    font-weight: 400;
    color: var(--common-color);
}

.checkoutPriceColumn .newPrice {
    font-size: inherit;
    font-weight: inherit;
}

.checkoutSlotSection {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0px 0;
    width: 100%;
}

.checkoutSlotItem {
    margin: 0 0 25px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.checkoutCommonButton {
    width: 100%;
    text-align: right;
}

.checkoutSlotItem .inputTextField {
    text-align: left;
    padding: 10px 15px;
    height: 105px;
    border: 1px solid #d8d8d8;
    border-radius: 7px;
}

.checkoutSlotItem label {
    width: 40%;
    color: #000;
    font-weight: 500;
    line-height: 21px;
    font-size: 16px;
    vertical-align: top;
}

.checkoutSlotItem .inputField {
    width: 60%;
    border: 1px solid #d8d8d8;
    height: 40px;
    border-radius: 7px;
    vertical-align: top;
}

.checkoutCommonButton .commonButton {
    margin: 0 0 0 20px;
}

.checkoutGrandTotal {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.checkoutGrandSec {
    padding: 45px 0 20px;
}

.checkoutGrandPrice {
    font-size: 21px;
    font-weight: bold;
    color: var(--heading-color);
    margin: 0 30px 0 0;
}

body .checkBorderBtn,
body .commonButton.checkBorderBtn {
    border: 1px solid var(--heading-color);
    background: transparent;
    color: var(--heading-color);
}

.checkoutSlotHalf {
    width: 50%;
}

.checkoutSlotHalf:nth-child(odd) {
    padding: 0px 30px 0 0;
}

.shippingAddressForm {
    display: flex;
    flex-wrap: wrap;
    width: 82%;
    margin: 0 auto 0;
}

.shippingAddresItem {
    width: 50%;
    padding: 0 15px;
    margin: 0 0 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 15px;
    font-weight: 400;
    align-items: center;
}

body .checkInputField {
    width: 100%;
    height: 40px;
    border: 1px solid #dddddd;
    border-radius: 7px;
    padding: 0 15px;
    font-weight: 400;
    color: #393939;
}

.checkInputField::placeholder {
    color: #393939;
    text-transform: uppercase;
}

.shippingAddreshalf {
    width: 48%;
}

.shippingNumberDefult {
    width: 50%;
    margin: 0;
    font-size: 12px;
    padding: 0 0 0 10px;
    display: flex;
    align-items: center;
}

.shippingNumberField {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shippingNumberDefult .checkBoxSquare {
    margin: 0 7px 0 0;
    float: none;
    width: 20px;
    height: 20px;
}

.shippingNumberField .checkInputField {
    margin: 0 0 0 7px;
    width: 85%;
}

.shippingButton {
    width: 100%;
    text-align: center;
    margin: 15px 0 0;
}

.shippingButton .commonButton {
    margin: 0 10px;
}

.shippingAddressListDiv {
    width: 82%;
    margin: 0 auto 0;
}

.shippingAddressList {
    display: flex;
    flex-wrap: wrap;
}

.shippingButtonAdd {
    text-align: right;
    margin: 0 0 45px;
}

.shippingAddressItem {
    width: 282px;
    border: 1px solid #ddd;
    margin: 0 15px 30px;
    background: #f5ffff;
    border-radius: 15px;
    position: relative;
    box-shadow: 0 0 5px #ddd;
    overflow: hidden;
}

.shippingAddressContent {
    padding: 20px 15px 40px;
}

.shipAddressName {
    font-weight: 600;
    text-transform: uppercase;
    color: #393939;
    margin: 0 0 10px;
}

.shippingAddressItem p {
    font-size: 14px;
    margin: 0 0 4px;
    color: #393939;
}

.shipAddressAction {
    font-size: 13px;
    font-weight: 400;
    position: absolute;
    right: 15px;
    bottom: 5px;
}

body .shipAddressEdit {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

body .shipAddressRemove {
    color: red;
    margin: 0 0 0 10px;
    cursor: pointer;
    text-decoration: none;
}

.shippingAddressActive {
    border-color: var(--common-color);
    background: #dcffff;
}

.checkoutPayment {
    width: 50%;
    margin: auto;
}

.paymentMethod {
    padding: 20px 0 40px;
    width: 460px;
    margin: auto;
}

.paymentMethodList {
    font-size: 20px;
    margin: 0 0 15px;
    padding: 25px 20px;
    border-bottom: 5px solid #dadada;
    background: #f5f5f5;
    border-radius: 10px;
}

.paymentMethodList .radioSection {
    font-size: inherit;
    color: #424242;
}

.paymentMethodList .radioSection label {
    width: 22px;
    height: 22px;
    margin: 0 15px 0 0;
    display: inline-block;
}

.paymentMethodList .radioSection label:before {
    width: 16px;
    height: 16px;
}

.successData {
    text-align: center;
    margin: 75px 0 60px;
}

.successData img {
    width: 95px;
    margin: 0 0 20px;
}

.successData h4 {
    font-size: 32px;
    color: #000;
    font-weight: 400;
    margin: 0 0 7px;
}

.successData p {
    color: #404040;
    font-size: 20px;
    font-weight: 300;
}

.checkoutItemSaveDiv {
    padding: 15px 25px 0 0;
}

.checkoutItemSave {
    background: #ffe0e0;
    border-radius: 10px;
    padding: 15px;
}

.checkoutItemText {
    font-size: 13px;
    font-weight: 400;
    color: #000;
    margin: 0 0 3px;
}

.checkoutItemSave .summarySaveText {
    padding: 0;
}

.checkoutSlotItemField {
    width: 60%;
}

.checkoutSlotItemField .inputField {
    width: 100%;
}

.shippingButtonAdd .commonButton {
    margin: 0 0 0 15px;
}

.shippingAddressContent .commonButton {
    margin: 20px 0 5px;
    display: block;
    text-align: center;
    font-size: 15px;
    padding: 10px 25px;
}

.cartSaveItemDiv {
    font-size: 11px;
    color: red;
    font-weight: 400;
}

.checkOutStoreDetails a {
    text-decoration: none;
}

.checkoutPayment #orderProcessing {
    margin: 25px 0 0;
    cursor: default;
}

.checkoutCommonButton .checkoutGrandPrice {
    margin: 15px 0 25px;
}

.invoice-div .shopTitleCircle .shopName {
    width: 54%;
}

.shopTitleCircle .shopLogo {
    float: left;
    height: 60px;
    width: 60px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 7px #b9b9b9;
    display: block;
    overflow: hidden;
}

.orderSection .shopTitleCircle {
    display: flex;
    align-items: center;
}

.shopTitleCircle .shopLogo img {
    width: 100%;
    height: 100%;
}

.checkOutInvoice .priceListSummary {
    padding: 0;
}

.checkOutInvoice .priceListSummary span {
    width: auto;
    display: initial;
}

.orderProcessingDisable {
    pointer-events: none;
}

.cartProductItemDetails .isOutOfStock {
    float: none;
    width: auto;
    padding: 0 10px 0 0;
}

.checkoutItemDetailList .isOutOfStock {
    float: none;
    padding: 0;
    width: 15%;
}

.contentWrapWhite {
    background: #fff;
}

.profileTagSection .tag {
    border: 0;
    padding: 0 8px 0 0;
    margin: 0;
    text-transform: capitalize;
    color: #101010;
    font-weight: 600;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
}

.profileTagSection .tag:after {
    content: ">";
    font-size: 120%;
    margin: 0 0 0 3px;
}

.profileTagSection .tag:last-child:after {
    display: none;
}

.profileInnerContainer {
    background: #fff;
}

.cartProductItemList .itemAlertText {
    left: auto;
    right: 10px;
}

.checkoutDetailLeft {
    width: 100%;
    display: flex;
}

.checknonScheduleDelivery {
    width: 100%;
    margin: 10px 0 0;
}

.checkoutDetailPicker {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.checknonScheduleDelivery .inputField {
    width: 100%;
}

.checkoutDetailPickerHalf {
    width: 48%;
    position: relative;
    margin: 0 0 10px;
}

.checkoutDetailPicker .formImage {
    left: auto;
    right: 7px;
    width: 22px;
    top: 8px;
}

.checkoutDetailTime {
    position: relative;
    width: 49%;
}


/**************** order summary ****************/


/***************** promocode ******************/

.promoCodeWrap {
    padding: 65px 30px;
    width: 750px;
}

.promoCodeTitle {
    font-size: 37px;
    font-weight: 600;
    margin: 0 0 25px;
}

.promoCodeWrap .react-tabs__tab-list {
    border: 0;
    margin: 0 0 30px;
    display: flex;
    justify-content: space-evenly;
}

.promoCodeWrap .react-tabs__tab-list li {
    border: 0;
    border-bottom: 5px solid var(--common-color);
    padding: 15px 0px 11px;
    margin: 0;
    background: #ddd;
    border-radius: 0;
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
    bottom: 0;
    text-align: center;
    outline: 0;
    width: 50%;
}

.promoCodeWrap .react-tabs__tab-list li.react-tabs__tab--selected {
    background: var(--common-color);
    color: #fff;
    pointer-events: none;
}

.promoStoreCount {
    font-size: 22px;
    background: #f1fbfa;
    padding: 10px 15px;
    margin: 0 0 20px;
    color: #000;
}

.promoStoreList {
    margin: 0 0 30px;
}

body .promoOfferListLink {
    text-decoration: none;
    display: flex;
    color: #fff;
    border-radius: 6px;
    overflow: hidden;
    min-height: 145px;
}

.promoStoreLogo img,
.promoOfferLogo img {
    height: 80px;
    width: auto;
    margin: auto;
}

.promoStoreLogo {
    width: 30%;
    padding: 50px 30px;
    background: #bde528;
    display: flex;
    align-items: center;
    position: relative;
}

.promoStoreName {
    width: 70%;
    display: flex;
    align-items: center;
    background: #ee2e4d;
    padding: 0 30px;
    font-size: 26px;
    font-weight: 600;
    position: relative;
    border-left: 3px dotted #fff;
}

.promoStoreName:after,
.promoStoreLogo:after,
.promoOfferLogo:after,
.promoOfferDetails:after {
    content: "";
    position: absolute;
    right: -17px;
    width: 28px;
    height: 41px;
    background: #fff;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
}

.promoStoreLogo:after,
.promoOfferLogo:after {
    left: -17px;
    right: auto;
}

.promoOfferLogo {
    width: 30%;
    padding: 30px 30px;
    background: #bde528;
    display: flex;
    align-items: center;
    position: relative;
}

.promoOfferList {
    margin: 0 0 30px;
}

.promoOfferDetails {
    width: 70%;
    display: flex;
    align-items: center;
    background: #ee2e4d;
    padding: 0 30px 15px;
    font-size: 26px;
    font-weight: 600;
    position: relative;
    flex-wrap: wrap;
    border-left: 3px dotted #fff;
}

.promoOfferDetails p {
    width: 100%;
    align-self: flex-end;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    margin: 0;
}

.promoCodeText {
    border: 2px dashed #fff;
    padding: 5px 20px;
    font-size: 20px;
    font-weight: 400;
    border-radius: 5px;
    color: #fff;
}

.clickPromoCodeAvail,
.clickNoPromoCodeAvail {
    border: 1px dashed #a8f796;
    width: 100%;
    padding: 10px 10px;
    margin-top: 20px;
    border-radius: 10px;
    background-color: #edffe9;
    text-align: center;
    font-weight: 400;
    color: #008033;
    cursor: pointer;
}

.clickNoPromoCodeAvail {
    cursor: default;
    color: #fb6868;
    background: #fff;
    border-color: #d0d0d0;
}

body .checkoutAvailPromo .promoOfferLinkLokal {
    border: 1px dashed #5dc15c;
    overflow: visible;
    margin-bottom: 30px;
    border-radius: 0;
}

.checkoutAvailPromo .promoOfferLinkLokal .promoOfferDetails {
    background: #fff;
    padding-right: 10px;
}

.checkoutAvailPromo .promoOfferLinkLokal.promoOfferListLink p {
    font-weight: 600;
    color: gray;
    width: 100%;
}

.promoOfferLinkLokal .promoCodeText {
    background-color: #5dc15c;
    color: #fff;
    position: absolute;
    top: -21px;
}

.promoOfferLinkLokal .promoOfferDetails:after {
    background-color: transparent;
}


/***************** promocode ******************/

.cartDivSection .cartClose {
    right: -55px;
    top: 3px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.cartDivSection .cartClose img {
    width: 30px;
    /* filter: brightness(-300%);
    -webkit-filter: brightness(-300%);
    -ms-filter: brightness(-300%);
    -moz-filter: brightness(-300%); */
    position: relative;
    left: 1px;
    top: 1px;
}

.checkOutNodata {
    text-align: center;
    padding: 30px;
}

.checkOutNoFound {
    padding: 0 0 45px;
}

.checkOutNoFound span {
    border: 1px dashed #b5b5b5;
    width: 475px;
    font-size: 19px;
    border-radius: 7px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 95px;
    text-transform: uppercase;
    color: #5d5d5d;
}

.checkoutSlotItem .inputField:focus,
.checkoutSlotItem .inputTextField:focus {
    border-color: #525252;
}

#clearAllFilter .filterCategoryName {
    background: #ffcfcf;
    text-align: justify;
    justify-content: center;
}

.cartProductItemLeft .productItemNameLeft,
.checkOutItemName .productItemName {
    padding: 0 0 0 30px;
}

.cartProductItemLeft .productItemName.newPrice,
.checkOutItemName .productItemName.newPrice {
    padding: 0px 0 0 15px;
    margin: 0;
}

.checkoutAvailPromo {
    margin: 10px 0 0;
    width: 100%;
    background: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    z-index: 1;
}

.checkoutAvailPromo .promoOfferListLink {
    margin: 0 0 10px;
    min-height: auto;
    cursor: pointer;
}

.checkoutAvailPromo .promoOfferLogo {
    padding: 10px 30px;
}

.checkoutAvailPromo .promoStoreLogo img,
.checkoutAvailPromo .promoOfferLogo img {
    height: 55px;
}

.checkoutAvailPromo .promoCodeText {
    font-size: 15px;
    cursor: pointer;
    border: 1px dashed #fff;
    padding: 5px 10px;
    min-width: 135px;
    text-align: center;
}

.checkoutAvailPromo .promoOfferDetails p {
    font-size: 14px;
    margin: 5px 0 5px;
    font-weight: 300;
    width: 65%;
    align-self: auto;
}

.checkoutAvailPromo .promoOfferDetails {
    width: 100%;
    background: #5dc15c;
    padding: 15px 35px 15px 20px;
    justify-content: space-between;
}


/**************************CONTACT US******************************/

.ContactTab {
    width: 80%;
    margin: -80px auto -150px;
    background: #fff;
    padding: 50px 50px;
    border-radius: 40px;
    position: relative;
    z-index: 1;
}

.contactWrap {
    border: 1px solid #ddd;
    border-radius: 40px;
}

.contactWrap input,
button,
select,
textarea {
    border: 1px solid #ddd;
    border-radius: 4px;
}

.contactWrap .contactRightBtn button {
    background: #23a69b;
    border: 0;
    color: #fff;
    border-radius: 9px;
    padding: 10px 40px;
}

.contactParts {
    width: 48%;
    display: inline-block;
    vertical-align: top;
}

.contactList {
    clear: both;
    overflow: hidden;
    margin-bottom: 50px;
}

.contactTxt {
    width: 80%;
    float: left;
    padding-left: 0;
    color: #4e4e4e;
}

.contactTxt span {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
    color: #2d2c2c;
}

.contactTxt a {
    color: #2d2c2c;
    text-decoration: none;
    outline: 0;
    font-size: 16px;
}

.addressWrap {
    padding: 60px 0;
}

.contactFull {
    width: 70%;
}

.contactImg {
    width: 60px;
    float: left;
}

.contactParts {
    width: 48%;
    display: inline-block;
    vertical-align: top;
}


/**************************CONTACT US******************************/

.checkoutSlotItemPromoDiv {
    align-items: flex-start;
}

.checkoutSlotItemPromo {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.checkoutSlotItemPromoDiv label {
    margin-top: 6px;
}

.checkoutSlotItemPromo .inputField {
    width: 63%;
}

.checkoutAvailPromoBtn .commonButton {
    height: 40px;
    padding: 10px 25px;
    font-size: 14px;
}

body .commonButton.commonPromoButton {
    background: var(--common-color);
}

body .commonButton.commonPromoDel {
    background: #f93e3e;
}

.promoCodeDisable {
    pointer-events: none;
    opacity: 0.6;
}

.orderSummaryConsumerCard {
    margin: 8px 0 0;
}

.orderSummaryRed {
    padding: 10px 25px 0;
    color: #ff0000;
}

.promocodeAppliedItem {
    margin: 0 0 8px;
    color: #4db300;
}


/************************** Set Locations ******************************/

.setLocation-wrapper {
    border: 1px solid #dedede;
    border-radius: 20px;
    padding: 0px 50px 30px;
}

.form-head {
    font-size: 22px;
    font-weight: 700;
    color: #4d4d4d;
    margin-bottom: 25px;
}

.form-section-brdr {
    border: 1px solid #dedede;
    border-radius: 20px;
    padding: 10px;
    margin: 20px 0 30px;
    position: relative;
}

.form-section {
    font-size: 16px;
    position: relative;
    margin: 20px 0 30px;
}

.checkbox-main {
    padding: 0 10px;
    position: relative;
}

.checkbox-main input,
.drListHeart input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-main .checkbox {
    position: absolute;
    top: -5px;
    left: 0;
    height: 17px;
    width: 17px;
    background-image: url(../images/icons/checkbox.png);
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transition: 1s;
    transition: 1s;
}

.checkbox-main input:checked~.checkbox {
    background-image: url(../images/icons/checkboxact.png);
}

.checkbox-main .checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-main input:checked~.checkbox:after {
    display: block;
}

.input-design {
    border: 1px solid #dcdcdc;
    background-color: #fff;
    width: 100%;
    padding: .25rem 1rem;
    resize: none;
    border-radius: 20px;
    color: #989898;
    font-weight: 300;
}

textarea.input-design {
    height: 100px;
}

.input-box-w-85 {
    width: 80px;
    padding: .05rem 0.5rem;
}

.input-box-w-85width {
    width: 93px;
}

.form-subhead {
    font-size: 18px;
    font-weight: 700;
    color: #4d4d4d;
    margin-bottom: 15px;
}

.setLocImgOutr {
    position: relative;
}

.setLocImgOutr .setLocImg {
    position: absolute;
    right: 20px;
    top: 5px;
    width: 25px;
}

.setLocsub {
    width: 19%;
    margin-right: 2%;
    display: inline-block;
    border: 1px solid #dcdcdc;
    border-radius: 20px;
    position: relative;
}

.setLocSubImg {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    cursor: pointer;
}

.setLocsubSpan {
    padding: 5px 10px;
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical
}

.setLocsubSm {
    width: auto;
    margin-right: 2%;
    display: inline-block;
    border: 1px solid #dcdcdc;
    border-radius: 20px;
    position: relative;
}

.setLocsubSpanSm {
    padding: 5px;
    padding-right: 40px;
}

.addGroupBtn {
    float: right;
    color: #148109;
    border: 1px solid #148109;
    border-radius: 20px;
    padding: 4px 5px;
    margin-top: -22px;
    margin-right: 5px;
    cursor: pointer;
}

body div .cancelButton {
    background: #615c5c;
}

.form-section .label-text,
.form-section-brdr .label-text {
    color: #606060;
    position: relative;
    margin-bottom: 3px;
}

.row-margin {
    margin-bottom: 10px;
}

.set-weeks {
    width: auto;
    padding: 8px 15px;
    display: inline-block;
}

.input-design:focus {
    outline: 0px;
}

.btn_upload {
    background-color: #fff;
    border: 1px solid gray;
    color: gray;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 14px;
}

.note_smal {
    font-style: italic;
    margin-left: 10px;
}

.fileUploadField {
    width: 32.33%;
    display: inline-block;
    position: relative;
    margin: 0.5%;
    padding: 5px 0 5px 5px;
    border: 1px solid #efefef;
    border-radius: 10px;
}

.fileUploadField .inputfile {
    overflow: hidden;
    font-size: 12px;
}

.fileradio,
.fileImg {
    margin: 0 5px;
    vertical-align: middle;
}

.fileImg {
    object-fit: cover;
    object-position: center;
    height: 40px;
    margin-top: 10px;
}

.fileCloseImg,
.filePlusImg {
    width: 18px;
}

.item-management-main {
    width: 100%;
    display: flex;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.item-search-wrapper {
    font-size: 14px;
    background-color: rgb(57 205 192 / 0.1);
    padding: 10px 15px;
    border-radius: 10px;
    margin: 0 0 10px;
    display: flex;
}

.item-m-size {
    width: 13%;
    padding-right: 1%;
}

.item-m-date {
    width: 15%;
    padding-right: 1%;
}

.item-m-price {
    width: 12%;
    padding-right: 1%;
}

.item-m-offer {
    width: 23%;
    padding-right: 1%;
}

.item-m-chkbx {
    padding-right: 1%;
}

.item-m-chkbx input {
    vertical-align: middle;
}

.item-m-chkbx-top {
    margin-top: 28px;
}

.fileCloseImgTop {
    margin-top: 28px;
}

.item-management-main label {
    margin-left: 5px;
}

.item-management-main input {
    vertical-align: middle;
}

.item-m-select1 {
    padding-right: 2%;
    white-space: nowrap;
}

.form-bg-Gray {
    background-color: #f3f3f3;
    padding: 10px;
    border-radius: 10px;
    position: relative;
}

.item-search-sub {
    margin: 10px 0;
    margin-right: 1%;
    display: inline-block;
    width: 24%;
}

.item-m-table {
    padding: 10px;
    background-color: #fafafa;
    font-size: 13px;
    padding-right: 15px;
    max-height: 400px;
    overflow-y: scroll;
    min-height: 400px;
}

.item-m-table table {
    border: 1px solid #e8e8e8;
    background-color: #fff;
}

.item-m-table table th {
    border-bottom: 0;
    font-weight: 500;
}

.item-m-subhead {
    font-size: 14px;
    font-weight: 700;
    color: #4d4d4d;
    margin-bottom: 10px;
}

.item-m-table table tr {
    vertical-align: middle;
}

.add-image-list {
    display: inline-block;
    width: 310px;
}

.item-m-close,
.radioSection,
.item-m-chkbx {
    display: inline-block;
}

.add-image-list .radioSection {
    float: unset;
    padding: 0 10px;
}

.itemsize-title-wrapper {
    width: 300px;
}

.item-size-main {
    border: 0.5px solid #ececec;
    display: flex;
    overflow-x: auto;
}

.itemsize-body-list {
    display: inline-block;
    border: 1px solid #ececec;
    width: 10%;
    text-align: center;
    padding: 5px 20px;
    min-height: 46px;
    vertical-align: top;
}

.itemsize-body-headlist,
.itemsize-boldtitle {
    min-height: 55px;
}

.itemsize-title {
    font-size: 12px;
}

.itemsize-boldtitle {
    font-weight: 400;
    font-size: 18px;
}

.itemsize-body-sub {
    width: 55px;
    height: 55px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    line-height: 55px;
    font-size: 13px;
    text-align: center;
}

.item-taxDiv {
    font-size: 20px;
    font-weight: 600;
}

.item-percentDiv {
    font-size: 16px;
    font-weight: 600;
    padding-top: 25px;
}

.itemsize-body-wrapper {
    min-width: 1000px;
}

.itemsize-body-list .input-design {
    width: 100%;
}

.item-taxDiv {
    padding-top: 30px;
}

.item-taxDiv p {
    font-size: 20px;
    font-weight: 400;
}

.item-percentDiv {
    font-size: 16px;
    font-weight: 400;
}

.item-tax-head {
    width: 200px;
}

.CommonBtn.categorybtnsub {
    color: rgb(255 255 255) !important;
    background-color: rgb(0 0 0) !important;
    border: 1px solid rgb(0 0 0) !important;
}

.CommonBtn.categorybtnsub:hover {
    color: rgb(255 255 255) !important;
    background: #6363639e !important;
    border: 1px solid #6363639e !important;
}

.commonButton-sm {
    font-size: 14px;
    padding: 7px 20px;
    font-weight: 400;
    text-decoration: none;
    display: inline-block;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    background: var(--common-color);
    height: auto;
    line-height: initial;
    cursor: pointer;
}

.commonBtn-sm {
    font-size: 12px;
    padding: 7px 20px;
    font-weight: 400;
    text-decoration: none;
    display: inline-block;
    border-radius: 30px;
    color: #fff;
    background: #23a196;
    /* background: #008bde; */
    height: auto;
    line-height: initial;
    cursor: pointer;
    margin-top: 4px;
}

.zoom-transale {
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    z-index: 2;
    position: relative;
}

.zoom-transale:hover {
    -webkit-transform: scale(8);
    -moz-transform: scale(8);
    -o-transform: scale(8);
    transform: scale(8);
    z-index: 999;
}

.active-text {
    color: var(--common-color);
}

.item-color-desc {
    border: 1px solid #e5e5e5;
    padding: 2px 11px;
    vertical-align: middle;
}

.item-search-sub-pos,
.item-search-sub-pos-label {
    position: relative;
}

.item-search-sub-pos .removeCategory {
    position: absolute;
    right: 3px;
    top: 30px;
    background-color: #ffffff;
    border-radius: 50%;
}

.item-search-sub-pos .addNewCategory {
    float: right;
}

.addNewCategory {
    font-size: 10px;
    color: #027dd4;
    cursor: pointer;
}

.order-address-Mainbox {
    background-color: #fafafa;
    padding: 10px;
    border-radius: 10px;
    height: 400px;
    overflow: auto;
}

.order-address-box {
    border-left: 3px solid var(--common-color);
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.form-topHead {
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    top: -13px;
    padding: 1px 10px;
    border: 1px solid #f0f0f0;
    font-weight: 500;
    left: 20px;
}

.form-white-bgSec {
    border-radius: 20px;
    padding: 20px;
    background-color: #fefefe;
}

.serv-citty-list {
    display: inline-block;
    border: 1px solid #c3c3c3;
    padding: 2px 15px;
}

.dashboardTableOverflow {
    height: 500px;
    padding-top: 0;
}


/************************** Set Locations ******************************/


/*** Pricing***/

.priceMainReports hr {
    margin: 1.5rem 0;
    border-color: rgba(255, 255, 255, 0.2)
}

.priceMainReports .priceSubReports-feature hr {
    border-color: rgba(255, 101, 1, 0.2);
}

.priceSubReportsMain {
    width: 375px;
    display: inline-block;
    margin: 0 2% 20px;
    vertical-align: top;
}

.priceSubReports {
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 2px 2px 10px #d6d6d6;
    vertical-align: top;
}

.priceSubReports .priceReportsMiddlePart {
    color: #fff;
    text-align: center;
}

.priceSubReports .priceReportsMiddlePart .priceReportsMiddleHead {
    font-size: 18px;
    font-weight: normal;
    padding: 10px 2px;
}

.priceSubReports .priceReportsMiddlePart .priceReportsMiddleRate {
    font-size: 18px;
    font-weight: 600;
    padding: 10px 2px;
}

.priceSubReports .priceReportsMiddlePart .priceMiddleRateSpan {
    font-size: 16px;
}

.priceSubReports .priceReportsMiddlePart .priceMiddleToggle {
    font-size: 24px;
    font-weight: normal;
}

.priceSubReports .priceReportsBottomPart {
    padding: 0;
}

.priceSubReports .priceMiddleToggle .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0;
    line-height: 17px;
    margin-right: 10px;
}

.priceSubReports .priceMiddleToggle .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.priceSubReports .priceMiddleToggle .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.priceSubReports .priceMiddleToggle .toggle-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
}

.priceSubReports .priceMiddleToggle input:checked+.toggle-slider {
    background-color: var(--theme-subcolor);
}

.priceSubReports .priceMiddleToggle input:focus+.toggle-slider {
    box-shadow: 0 0 1px var(--theme-subcolor);
}

.priceSubReports .priceMiddleToggle input:checked+.toggle-slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

.priceButtonPart {
    text-align: center;
    margin: 20px 0 0;
}

.priceReportsMiddleRate small {
    font-size: 60%;
    vertical-align: bottom;
}

.priceSubReports-feature .priceReportsMiddlePart {
    background-color: #fff;
    border-radius: 20px 20px 0 0;
}

.priceSubReports .priceReportsBottomPart p {
    font-size: 14px;
    text-align: left;
    margin-bottom: 0;
    height: 40px;
    padding-top: 10px;
}

.priceReportsBottomPart-main {
    background-color: #f9f9f9;
}

.priceReportsBottomPart-main:nth-child(even) {
    background-color: #f2f2f2;
}

.priceSubReports .priceReportsBottomPart-para {
    padding-left: 27px;
    position: relative;
    line-height: 20px;
    margin-bottom: 10px;
}

.priceSubReports .priceReportsBottomPart-para img {
    left: 0;
    position: absolute;
    top: 3px;
}

.priceReportsBottomPart-head .priceReportsBottomPart-para {
    position: relative;
    padding-right: 5px;
}

.priceReportsBottomPart1 .priceReportsBottomPart-head .priceReportsBottomPart-para:after {
    position: absolute;
    content: "+";
    right: 10px;
    top: 0;
    z-index: 1;
    font-size: 20px;
}

.priceReportsBottomPart1 .priceReportsBottomPart-head-active .priceReportsBottomPart-para:after {
    content: "-";
}

.priceReportsBottomPart1 .priceReportsBottomPart-head-inactive .priceReportsBottomPart-para:after {
    content: "";
}

.priceMainReports .theme-round-btn {
    padding: 5px 35px;
    font-size: 16px;
    display: inline-block;
}

.myyshopp-main-wrapper .mshop-plan-text {
    font-size: 22px;
    font-family: 'Montserrat';
    font-weight: 300;
}

.priceReportsBottomPart-head p {
    font-weight: 600;
}


/*** Pricing***/

body .activeBtn,
body .deActiveBtn {
    cursor: pointer;
    text-decoration: none;
    border-radius: 17px;
    border: 0;
    font-size: 11px;
    font-weight: bold;
    line-height: 18px;
    height: 30px;
    background: var(--common-color);
    color: var(--header-button-text);
    display: inline-block;
    padding: 5px 20px;
    margin: 0 5px 5px;
    text-align: center;
    min-width: 110px;
}

body .deActiveBtn {
    background: #ff4040;
}

body .activeBtn-sm,
body .deActiveBtn-sm {
    min-width: 45px;
    padding: 3px 10px;
    height: 25px;
}

.hrLine {
    margin: 20px 15px;
    width: 97%;
    border-bottom: 1px solid #d0d0d0;
}

a.smstooltip {
    position: relative;
}

a.smstooltip .smstooltip-span {
    z-index: 10;
    display: none;
    padding: 14px 20px;
    width: 300px;
    line-height: 16px;
    box-shadow: 0 0 8px -2px #212529;
    background-color: #fff;
    border-radius: 10px;
    top: 0;
    left: 45px;
}

a.smstooltip:hover .smstooltip-span {
    display: inline;
    position: absolute;
    /* border: 1px solid black; */
    color: black;
    text-align: left;
}

a.smstooltip .smstooltip-span:after {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 25px solid #efefef;
    content: "";
    top: 0;
    left: -7%;
    transform: translateX(7%);
    position: absolute;
}


/********************* global ***************************/

.globalSearch {
    position: fixed;
    left: 0;
    right: 0;
    width: auto;
    top: 78px;
    padding: 3px 0 0;
    bottom: 0;
    z-index: 2;
}

.globalSearchOverlay {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    top: 0;
    background: rgb(0 0 0 / 70%);
    border: 0;
    box-shadow: none;
    padding: 3px 0 0;
    bottom: 0;
}

.globalSearchPop {
    width: 900px;
    margin: auto;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    position: relative;
}

.globalSearchPop .cartRemoveItem {
    right: -55px;
    top: 3px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.globalSearchPop .cartRemoveItem img {
    width: 30px;
    filter: brightness(-300%);
    -webkit-filter: brightness(-300%);
    -ms-filter: brightness(-300%);
    -moz-filter: brightness(-300%);
    position: relative;
    left: 1px;
    top: 1px;
}

.globalSearchContent {
    text-align: left;
}

.globalSearchTitle {
    position: relative;
    height: 36px;
}

.globalSearchTitle span {
    display: inline-block;
    background: #e6f8f7;
    color: var(--common-color);
    text-transform: uppercase;
    padding: 7px 0px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 400;
    width: 240px;
    text-align: center;
}

.globalSearchTitle:after {
    content: "";
    position: absolute;
    left: 250px;
    right: 0;
    background: #eeeeee;
    height: 1px;
    top: 18px;
}

.globalSearchRow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.globalSearchRow a {
    text-decoration: none;
    height: 100%;
    display: block;
}

.addMoreItemList {
    width: 25%;
    padding: 15px;
    text-decoration: none;
}

.globalSearchItem {
    border: 1px solid #dde0e3;
    border-radius: 7px;
    text-align: center;
}

.globalItemContent {
    padding: 10px 15px;
    font-size: 12px;
    position: relative;
}

.globalItemImage {
    margin: 0 0 10px;
}

body .globalItemImage img {
    width: auto;
    border-radius: 7px;
    height: 80px;
}

.globalItemName {
    font-weight: 600;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;cursor: pointer;
}

.globalItemSize {
    font-weight: 500;
    color: #000000;
    text-transform: uppercase;    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.globalItemSize span {
    /* display: inline-block; */
    margin: 0 2px;
}

.globalItemPrice {
    color: var(--common-color);
    font-weight: 500;
}
.globalItemContent a:hover{
    text-decoration: none;
}
.text-decoration:hover{
    text-decoration: none;
}
body .globalCartButton {
    margin: 5px auto 0;
    width: 55px;
    height: 25px;
}

body .globalCartButton img {
    width: 17px;
}

.addMoreItemContent {
    text-align: center;
}

.addMoreItemContent span {
    display: block;
    color: #b8b9bc;
    font-size: 14px;
}

body .addMorePlus {
    display: inline-flex;
    background: #b8b9bc;
    width: 70px;
    height: 70px;
    color: #e8e9ec;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 84px;
    font-weight: 200;
    margin: 0 0 10px;
}

.addMoreItem {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e8e9ec;
    border-radius: 7px;
}

.globalItemStore {
    border-top: 1px solid #d4d8dc;
    display: flex;
    align-items: center;
    padding: 10px 15px;
}

.globalItemStoreLogo img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: 0 0 10px #e4e4e4;
}

.globalItemStoreDetails {
    text-align: left;
    padding: 0 0 0 10px;
    font-size: 11px;
    color: #000;
}

.globalItemStoreDetails strong {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 115px;
}

.globalItemStoreDetails span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 115px;
    font-weight: 300;
}

body .globalItemStoreImage img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0 0 10px #e4e4e4;
    margin: 20px 0 5px;
}

.globalStoreItemName {
    color: #000;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.globalStoreItemLocation {
    color: #000;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.addedText {
    color: #18ab18;
    font-weight: 400;
    padding: 3px 0 0;
    display: inline-block;
    margin: 5px auto 0;
    height: 25px;
}

.globalItemContentResult .addedText {
    height: 33px;
}

.globalSearchResult {
    padding: 40px 0;
}

.globalSearchHead {
    text-align: center;
    font-weight: 600;
    font-size: 35px;
    margin: 0 0 40px;
}

.globalSearchHead span {
    color: var(--common-color);
}

.globalItemContentResult .globalItemImage img {
    width: 80%;
    height: auto;
}

.globalItemContentResult {
    font-size: 15px;
}

.globalItemContentResult .globalCartButton {
    width: 65px;
    height: 33px;
}

.globalItemContentResult .globalCartButton img {
    width: 22px;
}

.globalItemContentResult .globalItemStoreLogo img {
    width: 50px;
    height: 50px;
}

.globalItemContentResult .globalItemStoreDetails {
    font-size: 13px;
}

.globalSearchResult .globalSearchRow {
    margin-bottom: 30px;
}

.globalItemContentResult .globalItemStoreImage img {
    width: 100px;
    height: 100px;
}

.globalSearch .globalSearchContent {
    overflow: hidden;
    overflow-y: auto;
    max-height: 440px;
    padding-right: 5px;
}

.globalSearchContent::-webkit-scrollbar {
    width: 5px;
}

.globalSearchContent::-webkit-scrollbar-thumb {
    background: #a2a2a2;
}

body .nonVegIcon,
body .vegIcon {
    position: absolute;
    right: 10px;
    bottom: 13px;
    width: 18px;
    height: 18px;
    border: 1px solid #ff3b3b;
    border-radius: 2px;
}

body .nonVegIcon:after,
body .vegIcon:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 12px;
    background: #ff3b3b;
    height: 12px;
    margin: auto;
    top: 2px;
    border-radius: 2px;
}

body .vegIcon {
    border-color: #5fb500;
}

body .vegIcon:after {
    background: #5fb500;
}

body .cartCheckApply {
    text-decoration: none;
    padding: 9px 13px;
    display: inline-block;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    background: var(--common-color);
    height: 33px;
    line-height: initial;
    font-weight: 300;
    cursor: pointer;
}

body .cartInputField {
    height: 33px;
    border: 1px solid #9f9eab;
    font-size: 13px;
    padding: 2px 13px;
    font-weight: 400;
    border-radius: 30px;
    width: 100%;
    outline: 0;
}

.cartGlobalActive .cartInputField {
    width: 138px;
}

.cartGlobalSearch {
    display: flex;
    justify-content: space-between;
}

.cartGlobal {
    position: relative;
    margin: 8px 0 0;
}

.cartGlobalList {
    position: absolute;
    bottom: 37px;
    background: #ffffff;
    border: 1px solid #3771a2;
    border-radius: 7px;
    left: 0;
    right: 0;
    display: block;max-height: 150px;
    overflow-y: auto;
}

.cartGlobalList .cartGlobalItem {
    text-decoration: none;
    font-size: 13px;
    display: block;
    padding: 5px 10px;
    border-bottom: 1px solid #8f9da8;
    color: #2b2b2b;
    cursor: pointer;
}

.cartGlobalList .cartGlobalItem:hover {
    background: #f1f1f1;
}

.cartGlobalList .cartGlobalItem:last-child {
    border: 0;
}


/********************* global ***************************/


/************** store tyming*******************/

.storeLocationCover {
    margin: 10px 0 20px;
}

.storeLocationHldr {
    padding: 15px;
    width: 100%;
    background: none;
    border: 1px solid #b7b7b7;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.storeLocationHldr h3 {
    font-size: 13px;
    line-height: 20px;
    color: #7e7e7e;
    text-align: justify;
    margin-bottom: 10px;
}

.storeDayCheckWrap {
    border-top: 1px solid #CCC;
    padding: 15px 0 0;
    display: flex;
}

.storeDayCheck {
    margin-right: 15px;
    width: 130px;
    display: inline-flex;
    align-items: center;
}

.storeDayCheck .checkBoxSquare {
    float: none;
    margin: 7px 0 0 10px;
}

.storeDayCheck .labelField {
    width: auto;
    padding: 0;
    display: block;
    font-weight: 300;
}

.sectionTime {
    padding-top: 15px;
    display: flex;
    margin: 0 0 10px;
}

.sectionTime label {
    width: 20px;
    margin: 7px 10px 0 0;
    text-align: center;
}

.sectionTime select.orderm-field {
    color: rgba(0, 0, 0, 0.7);
    padding: 2px;
    border: 1px solid rgb(169, 169, 169);
    background: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    width: 60px;
    margin: 0 10px 3px 0;
}

.sectionTime .orderm-label {
    width: 200px;
    margin: 7px 0 0px;
    text-align: left;
}

.storeTimeSec {
    padding: 30px 0;
}


/************** store tyming*******************/


/****************** upload prescription **********************/

.uploadCover {
    display: flex;
    width: 100%;
    margin: 25px 0px 0;
    justify-content: space-between;
}

.uploadIconSec {
    width: 22%;
    height: 100px;
    background: #cef1f0;
    text-align: center;
    cursor: pointer;
    border-radius: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 240px;
}

.uploadPrescription {
    width: 75%;
    min-height: 100px;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 15px 20px;
}

.uploadIconSec span {
    text-align: center;
    color: var(--common-color);
    text-transform: uppercase;
    font-weight: 600;
}

.uploadIconSec img {
    width: 50px;
    height: auto;
    margin: 0 10px 0 0;
}

body .uploadIconThump {
    /* display: none; */
    display: inline-block;
    cursor: pointer;
    width: 65px;
    height: 65px;
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 3px;
    position: relative;
}

.uploadPrescriptionHead {
    display: flex;
    align-items: baseline;
    font-size: 24px;
    color: #000;
    font-weight: 500;
    margin: 0 0 5px;
}

body .uploadValid {
    color: red;
    font-size: 13px;
    cursor: pointer;
    margin: 0 0 0 10px;
    font-weight: 400;
}

.uploadPrescription p {
    margin: 0;
    font-size: 13px;
}

body .uploadIconThump img {
    width: 100%;
    height: auto;
}

.uploadIconThumpSec {
    margin: 15px 0 0;
    display: flex;
    flex-wrap: wrap;
}

body .uploadClose {
    position: absolute;
    background: #fff;
    width: 22px;
    height: 22px;
    color: red;
    border: 1px solid #ddd;
    border-radius: 50%;
    text-align: center;
    font-size: 15px;
    line-height: 17px;
    cursor: pointer;
    top: -8px;
    z-index: 1;
    right: -10px;
}

body .uploadIconThumpActive:after {
    content: "✓ ";
    position: absolute;
    top: 20px;
    left: 0;
    margin: auto;
    right: 0;
    text-align: center;
    display: inline-block;
    font-size: 13px;
    color: #fff;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    z-index: 2;
    background: #1dad05;
}

body .uploadIconThumpActive:before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 7px;
}

.headerIconRx {
    font-size: 14px;
    font-weight: 600;
    color: var(--common-color);
    cursor: pointer;
}

.headerIconRx img {
    vertical-align: sub;
    margin: 0 5px 0 0;
    height: 23px;
}

.uploadPopDiv {
    top: 0;
    display: none;
}

.uploadPopDiv .cartDivSection {
    width: 750px;
}

.prescriptionImage {
    text-align: center;
}

.prescriptionImage img {
    width: 100%;
    height: auto;
}

body .popButton {
    background: var(--common-color);
    color: #fff;
    padding: 9px 35px;
    border-radius: 30px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 300;
    display: inline-block;
    margin: 25px 0 0;
}

.uploadedPrescription {
    padding: 10px 20px;
}

.uploadedPrescriptionText {
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 5px;
}

.uploadedPrescriptionLink a,
.uploadedPrescriptionLink span {
    display: inline-block;
    font-size: 13px;
    color: #008bde;
    margin: 0 25px 0 0;
    font-weight: 400;
    cursor: pointer;
}

body .thumpViewPris {
    display: block;
    text-align: center;
    text-decoration: none;
    color: var(--common-color);
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    margin: 7px 0 0;
    background: #cef1f0;
    padding: 3px 0;
    border-radius: 5px;
}

body .uploadIconThumpView {
    margin: 0 10px 10px 0;
}


/****************** upload prescription **********************/

.scrollView img {
    width: 45px;
}

.scrollView {
    position: fixed;
    bottom: 35px;
    right: 20px;
    z-index: 999;
    cursor: pointer;
}

body .showCommonBtn {
    background: var(--common-color);
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    padding: 13px 35px;
    border-radius: 30px;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.showMoreSection {
    text-align: center;
}

body .showCommonBtn img {
    width: 21px;
    margin: 0 0 0 8px;
}

body .searchbutton {
    text-decoration: none;
    padding: 7px 17px;
    display: inline-block;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    /* background: var(--common-color); */
    background-color: #23a196;
    height: auto;
    line-height: initial;
    font-weight: 300;
    cursor: pointer;
}

.tooltipalign {
    width: 227px;
}

.breadcrumb-ul a {
    padding: .7em 1em .7em 2em;
    float: left;
    text-decoration: none;
    color: black;
    font-size: 12px;
    font-weight: 600;
    position: relative;
    text-shadow: 0 1px 0 rgb(255 255 255 / 50%);
    background-color: #ddd;
    background-image: linear-gradient(to right, #f5f5f5, #ddd);
}

.breadcrumb-ul a::before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    border-top: 1.5em solid transparent;
    border-bottom: 1.5em solid transparent;
    border-left: 1em solid;
    border-left-color: #ccc;
    /* right: -1.1em; */
    right: -12px;
    z-index: 1;
}

.breadcrumb-ul a::after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1.5em;
    border-top: 1.5em solid transparent;
    border-bottom: 1.5em solid transparent;
    border-left: 1em solid;
    right: -1em;
    z-index: 1;
    border-left-color: #ddd;
}

.breadcrumb-ul {
    list-style: none;
    display: flex;
    align-items: center;
    /* border-style: solid; */
    margin-top: 1%;
    /* border-color: #f5f5f5 #e5e5e5 #CCC; */
    /* border-radius: 5px; */
    overflow: hidden;
    /* width: 100%; */
    padding-left: 124px;
}

.breadcrumb-li {
    padding-left: 20px;
    color: #23a196;
    font-size: 13px;
    font-weight: 400
}

.dashbord-reprt-button {
    width: 29%;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
}

.orderm-report-section {
    margin: 0px 0 0px;
}

.formimage-pos {
    position: relative;
}

.dateImage {
    width: 18px;
    position: absolute;
    right: 20px;
    top: 33px;
    z-index: 1;
    pointer-events: none;
}

.loader_imgDiv-pos {
    position: relative;
}

.loader_imgDiv {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    max-height: 100vh;
}

.loader_imgDiv-popup {
    z-index: 999;
}

.loader_imgDiv img {
    margin: 0 auto;
}

body .inputField-sm {
    width: 150px;
}

.item-border-btm {
    border-bottom: 1px solid #e1e1e1;
}

.item-border-btm:nth-last-child(1) {
    border-bottom: 1px solid transparent;
}

.inputtext {
    width: 65px;
}

.react-datepicker-wrapper .inputtext.input-design {
    width: 80px;
}

.inputDate {
    width: 130px;
}

.headerInput {
    width: 133px;
    padding-left: 15px;
}

.inputTd {
    padding-left: 4px;
    padding-right: 4px;
    ;
}

.ordermSection {
    padding-left: 0;
    margin-left: 0;
}

.formCalenderIcon {
    left: 158px;
}

.breadcrumbLeft {
    padding-left: 0;
}

.siteUrl {
    padding-left: 0;
}

.calendarImagetop {
    padding-bottom: 6px;
}

.resheduleCalender {
    position: relative;
    right: 92px;
    width: 68%;
    margin-top: 17px;
}

.resheduleCalenderImg {
    right: 130px;
    top: 96px;
    width: 18px;
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

.form-sectionLeft {
    padding-left: 124px;
    padding-right: 80px;
}

.checkoutcontStore {
    border: 1px solid #efefef;
    padding: 0;
    margin-top: 15px;
    border-radius: 10px;
    overflow: hidden;
    width: 94%;
}

.checkoutcontStoreRght {
    background: #efefef;
    padding: 15px;
    width: 75%;
    display: inline-block;
}

.checkoutcontStoreLeft {
    padding: 15px 5px;
    width: 25%;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

.checkoutcontStoreRght .summarySaveText {
    color: #5f6c7c;
}

body .contactStorePhone {
    float: left;
    background: #efefef;
    color: #000;
    cursor: default;
}

.checkoutShippingStore {
    border: 1px solid #fbd7d7;
    padding: 10px 5px;
    margin-top: 15px;
    border-radius: 10px;
    overflow: hidden;
    width: 82%;
    background: #ffe0e0;
    text-align: center;
    font-size: 14px;
    margin: 20px auto 0;
}

.checkoutShippingStore span {
    padding: 0 10px;
    font-weight: 400;
}

.checkoutShippingStoreText {
    font-weight: 600;
}

.checkoutShippingStoreTop {
    margin-top: 100px;
}

.shopMobNmbr {
    width: 100%;
    font-size: 10px;
}

.formhead-leftpading {
    padding-left: 72px;
}

.input-designfocus:focus {
    border-color: #000;
}

td.brderTd .borderitem-btm:first-child {
    border: none;
}

.borderitem-btm {
    border-top: 1px solid #e1e1e1;
}

.categorybtn:active {
    background-color: #18786f;
}


/* gr new */

.gsa-edit-seller {
    width: 100%;
}

.gsa-add-store-btn {
    display: block !important;
    text-align: center;
    width: 100%;
}

.gsa-spouse-dt {
    background: #f2f2f2;
    padding: 18px 0px;
    margin: 5px;
}

.gsa-gender-dt {
    margin: 5px;
}

.gsa-addaddStoreSection {
    width: 70%;
}

.btn-edit {
    padding: 2px 27px;
    background: #f26516 !important;
    color: #fff;
}

.btn-edit:hover {
    color: #fff;
}

.gsa-right {
    float: right;
}

#gsa-edit-seller .formInputField,
#gsa-edit-seller .addStoreSectionList .formInputTextarea,
#gsa-edit-seller.groupBoxForm .formInputField,
#gsa-edit-seller .groupBoxForm .formInputTextarea {
    width: 90.5%;
    border-radius: 5px;
}

.wizard .nav-tabs {
    position: relative;
    margin-bottom: 0;
    border-bottom-color: transparent;
}

.wizard>div.wizard-inner {
    position: relative;
    margin-bottom: 50px;
    text-align: center;
}

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    /* width: 75%; */
    width: 50%;
    margin: 0 auto;
    left: 27%;
    /* right: 0; */
    right: 27%;
    top: 15px;
    z-index: 1;
}

.wizard .nav-tabs>li.active>a,
.wizard .nav-tabs>li.active>a:hover,
.wizard .nav-tabs>li.active>a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

span.round-tab {
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 16px;
    color: #0e214b;
    font-weight: 500;
    border: 1px solid #ddd;
}

span.round-tab i {
    color: #555555;
}

.wizard li.active span.round-tab {
    background: #0db02b;
    color: #fff;
    border-color: #0db02b;
}

.wizard li.active span.round-tab i {
    color: #5bc0de;
}

.wizard .nav-tabs>li.active>a i {
    color: #0db02b;
}

.wizard .nav-tabs>li {
    width: 25%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: red;
    transition: 0.1s ease-in-out;
}

.wizard .nav-tabs>li a {
    width: 30px;
    height: 30px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
    background-color: transparent;
    position: relative;
    top: 0;
}

.wizard .nav-tabs>li a i {
    position: absolute;
    top: 45px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: 500;
    color: #000;
}

.wizard .nav-tabs>li a:hover {
    background: transparent;
}

.wizard .tab-pane {
    position: relative;
    padding-top: 20px;
}

.wizard h3 {
    margin-top: 0;
}

.wizard li.actives span.round-tab {
    background: #027dd4;
    color: #fff;
    border-color: #027dd4;
    ;
}

.gsa-select,
.txtLocation {
    border-radius: 20px !important;
}

#gsa-setLocOuter::-webkit-scrollbar {
    width: 10px;
}

.gsa-dev-slots {
    position: absolute;
    right: -23px;
    top: 10%;
    cursor: pointer;
}

.gsa-setLocSubImg {
    position: absolute;
    right: 80px;
    top: 10%;
    cursor: pointer;
}

.gsa-btn-save {
    width: 100%;
    display: block;
    margin-bottom: 4%;
}


/* Track */

#gsa-setLocOuter::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

#gsa-setLocOuter::-webkit-scrollbar-thumb {
    background: #888;
}


/* Handle on hover */

#gsa-setLocOuter::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#gsa-setLocOuter {
    max-height: 280px;
    overflow-y: auto;
}

.pull-right {
    float: right;
}


/* new classes */

.adressFormListcenter {
    text-align: center;
}

.formInputSectionwidth {
    width: 30%;
}

body .labelFieldwidth {
    width: 0;
}

.formInputFieldwidthrender {
    text-align: left;
    width: 60%;
    margin-left: 30px;
}

.formInputSectionscroll {
    overflow-y: auto;
    height: 100px;
    /* height: 80px; */
}

.formInputSectnwidth {
    width: 40%;
}

.formInputitemcode {
    width: 30%;
}

.formInputitemcodeClose {
    width: 15%;
}

.orderm-search-wrapper-width {
    width: 98%;
}

.setLocation-butn {
    font-size: 12px;
    padding: 5px 10px;
    background-color: #23a196;
    border-radius: 14px;
    color: #fff;
    border: 1px solid #23a196;
}

.setLocation-butn:hover {
    border: 1px solid #23a196;
    color: #23a196;
    background-color: transparent;
}

.formImage-right {
    width: 18px;
    position: absolute;
    right: 35px;
    top: 7px;
    z-index: 1;
    pointer-events: none;
}

.fileCloseImgMTop {
    margin-top: 40px;
}

.selectStoreBorder {
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    padding: 6px 15px;
    width: 63%;
    margin-left: 15px;
}

.addStoreSectionListWidth {
    width: 100%;
}

.addStoreSectionOuterDiv {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.addStoreSectionListWrapper {
    width: 100%;
    margin-top: 30px;
}

.formInputSectionTop {
    /* position: absolute; */
    top: -27px;
}

.addStoreSectionListMargin {
    margin-top: -35px;
}

.formInputborderR {
    border-radius: 20px !important;
}

.smalLblnewWidth {
    width: 100% !important;
}

.breadcrumb-ulPadng {
    padding-left: 110px;
}

.ButnWidthByer {
    padding: 6px 15px !important;
    font-weight: 500 !important;
    font-size: 13px !important;
}

.ButnWidthByerSave {
    padding: 6px 25px !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    border-radius: 30px;
}

.smalLblnewTop {
    top: 8px;
}

.formInputborderR:focus {
    border: 1px solid rgb(169, 169, 169) !important;
    border-radius: 20px;
    outline-offset: 0px;
    outline: none;
}

.smyLargstFieldBrder:focus {
    border-bottom: 1px solid #000;
    outline-offset: 0px;
    outline: none;
}

.gsa-spouse-borderR {
    border-radius: 20px;
}

.gsa-spouse-borderTopBotm {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.addStoreSectionListWidth {
    width: 58%;
}

.formInputFwidth {
    width: 95% !important;
}

.orderm-search-wrapper-row {
    width: 90% !important;
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
}

.headerSearchSubOuterWidth {
    width: 350px !important;
    top: 35px !important;
}

.SiteSearchOuterWidth {
    width: 530px !important;
    top: 35px !important;
    font-weight: 400;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
}


/* .adminFooter{
    position: absolute;
    width: 100%;
} */

.row-marginwidthOuter {
    width: 100%;
}

.row-marginwidth {
    width: 25%;
    display: inline-block;
}

.formInputwidthlocation {
    width: 93%;
}

.groceryfont {
    font-size: 12px;
}

.gsa-setLocSubImgright {
    right: -20px;
    top: -10%;
    width: 20px;
}

.deliveryslotOuter {
    width: 100%;
    position: relative;
}

.deliveryslotdiv {
    display: inline-block;
    width: 75%;
}

.deliveryslotdivtme {
    display: inline-block;
    width: 25%;
    /* position: absolute;
    bottom: 11px;
    right: 0; */
}

.expressOuterdiv {
    width: 100%;
}

.expressdiv {
    display: inline-block;
    /* width: 45%; */
    vertical-align: top;
}

.Scheduleddiv {
    display: inline-block;
}

.deliversblock {
    display: inline-block;
    width: 25%
}

.breadcrumb-liPleft {
    padding-left: 0;
}

.csvDiv {
    margin-left: 40px;
    cursor: pointer;
}

body .commonButton.commonButtonPadng {
    padding: 7px 15px;
}

.csvDivbuyer {
    margin-left: 64px;
    cursor: pointer;
}

.csvDivstore {
    padding-left: 60px;
    cursor: pointer;
}

.csvDivpurchase {
    margin-left: 64px;
    cursor: pointer;
}

.csvDivelectronic {
    padding-left: 60px;
    cursor: pointer;
}

.csvdivright {
    cursor: pointer;
}

.csvDivpayment {
    margin-left: 50px;
    cursor: pointer;
}

.csvDivtable {
    margin-top: 10px;
    cursor: pointer;
}

.csvDivorder {
    cursor: pointer;
}

.tooltipReport {
    position: relative;
    display: inline-block;
}

.tooltipReporttext {
    visibility: hidden;
    width: 70%;
    background-color: #dfdfdf;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    position: absolute;
    z-index: 1;
    bottom: -4px;
    font-size: 12px;
    color: #000;
    right: 14%;
}

.tooltipReporttext-site {
    width: 100%;
    bottom: -7px;
    left: 98px;
}

.tooltipReport:hover .tooltipReporttext {
    visibility: visible;
}

.row-marginTop {
    vertical-align: top;
}

.deliveryslotdivtmeOuter {
    /* width: 100%; */
    /* left: 74%; */
    position: absolute;
    /* bottom: -41%; */
    top: 89%;
    text-align: right;
    max-height: 21%;
    overflow-y: auto;
}

.deliveryinlinetop {
    display: inline-block;
}

.btnPrimaryborder {
    padding: 5px 24px;
    border-radius: 17px;
    font-size: 13px;
    font-weight: 600;
}

.addStoreSectionListWidth {
    width: 100%;
}

.removebtnMidle {
    vertical-align: middle !important;
}

.addStoreItemTitleAlignnt {
    width: 100%;
    text-align: center;
}

.addStoreSectionwidthOuter {
    width: 100%;
}

.formInputSectionLeftpadng {
    padding-left: 0;
}

.conf-cursorpointer {
    cursor: pointer;
}

.conf-cursorpointer:hover {
    text-decoration: none
}


/* .table-bordered td img{
      cursor: pointer;
  } */

.cmnbtnsubcolor {
    color: #23a196 !important;
    background-color: #fff !important;
    border: 1px solid #23a196;
}

.addStorewidthOuter {
    width: 50%;
}

.statusColorVerified {
    background-color: #f2f2f2;
}

.statusColordeliverd {
    background-color: green;
}

.statusColorcancel {
    background-color: red;
}

.rowfontBold {
    font-weight: 700;
}

.orderm_shopTextbold {
    font-weight: 700;
    color: #000;
}

.rowCursorpointer {
    cursor: pointer;
}

.activeBtnwidthmarginLeft {
    margin-left: 65px !important;
}

.delievrs-inlinediv {
    display: inline-block;
    vertical-align: middle;
}


/*** lokal  start ***/

.bannerButtonOTP {
    padding: 10px 60px !important;
    margin-right: 10px;
    font-size: 15px !important;
    font-weight: 400 !important;
    border: none;
}

.bannerButtonOTPMargin {
    margin-right: 10px;
}

.bannerButtonOTP:focus {
    outline: none;
}

.bannerButtonPadng {
    padding: 10px 27px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}

input.termCheckbox {
    width: 30px;
    height: 16px;
    margin-left: 10px;
    vertical-align: top;
    margin-top: 5px;
}

.signupLabelText {
    font-size: 18px;
    color: var(--heading-color);
    text-transform: capitalize;
    font-weight: 400;
}

.signupLabelText span {
    font-size: 14px;
    color: var(--heading-color);
    text-transform: capitalize;
    font-weight: 400;
    cursor: auto;
}

.signupLabelText a {
    font-size: 14px;
    color: var(--heading-color);
    text-transform: capitalize;
    text-decoration: underline
}

.signupLabelText a:hover {
    text-decoration: underline !important;
    color: #0ab7b3;
    font-weight: 400;
}

.paymentlabel {
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
}

.paymentconfrmedText {
    font-size: 22px !important;
    color: var(--heading-color);
    text-transform: capitalize;
    margin: 0 0 15px !important;
    font-weight: 400;
}

.paymentconfrmedTextcolor {
    color: #0c6968 !important;
}

.paymentTextHeadTop {
    font-weight: 500;
}


/* .paymentConfirmedDiv{
    transform: translate(0%, 50%)
} */

.termscondtnWidth {
    width: 100%
}

.paymentOPtnText {
    width: 434px;
}

.bannerButtonWidth {
    font-size: 15px !important;
    padding: 7px 18px !important;
}

.termscondtnOuter {
    padding: 11% 0 0px;
    background: #ffffff;
}

.termsParaoltext ol li {
    padding-bottom: 15px;
}

.listtype-terms {
    list-style-type: none;
    padding-left: 15px;
}

.termsHeadDiv {
    text-align: center;
}

.termsHeadsize {
    line-height: 25px;
    font-weight: 600 !important;
}

.signupLabelText p {
    font-size: 14px !important;
    padding-left: 1.5em;
    margin: 0 0 15px !important;
    font-weight: 400;
}

.lklSellerWrap .lkcontentWrap {
    background: #f0eeee !important;
}

.lkbannerItem p {
    margin: 0 0 30px !important;
}

.lkbannerContent {
    left: 7% !important;
}

.custom-checkbox {
    margin-bottom: 20px;
}

.lklSellerSignupWrap .custom-checkbox {
    margin-bottom: 10px;
}


/* **** */


/* Custom radio css */

.styled-checkbox {
    position: absolute;
    opacity: 0;
    z-index: 999;
    width: 20px;
    height: 20px;
}

.styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
}

.styled-checkbox+label:before {
    content: "";
    margin-right: 3px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: white;
    vertical-align: middle;
    border: 1px solid #0ab8b4;
}

.styled-checkbox:hover+label:before {
    background: #fff;
}

.styled-checkbox:checked+label:before {
    border: 1px solid #0ab7b3;
}

.styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox:checked+label:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 14px;
    background: #0ab7b3;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #0ab7b3, 4px 0 0 #0ab7b3, 4px -2px 0 #0ab7b3, 4px -4px 0 #0ab7b3, 4px -6px 0 #0ab7b3, 4px -8px 0 #0ab7b3;
    transform: rotate(45deg);
    color: #0ab7b3;
}

.listOltype li a,
.listtype-terms li a {
    text-decoration: underline !important;
}


/* Custom checkbox css */

.custom_radio {
    width: 434px;
    margin-top: 20px;
}

.custom_radio input[type="radio"] {
    display: none;
}

.custom_radio input[type="radio"]+label {
    position: relative;
    display: inline-block;
    padding-left: 1.5em;
    margin-right: 2em;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.custom_radio input[type="radio"]+label:before,
.custom_radio input[type="radio"]+label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 16px;
    height: 16px;
    text-align: center;
    color: white;
    font-family: Times;
    border-radius: 50%;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    border: 1px solid #0ab8b4;
}

.custom_radio input[type="radio"]+label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
}

.custom_radio input[type="radio"]:checked+label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #0ab7b3;
}


/* **** */


/*** lokal  end ***/

.commonBtnPadngBcus {
    padding: 7px 18px !important;
}

.orderm-search-wrapperwidthBcus {
    width: 100%;
}

body .cartInputwidth {
    padding: 3px 10px 3px 30px !important;
    width: 220px !important;
    margin-right: 5px !important;
    font-size: 13px;
    border-radius: 15px;
}
body .cart-locationiput {
    width: 87% !important;
    margin-bottom: 10px;
}

body .cartCheckApplywidth {
    padding: 3px 7px !important;
    height: 21px !important;
    font-size: 11px;
}

.cartlocation-arrow {
    position: absolute;
    top: 10px !important;
    left: 7px !important;
}

.excelStockUpdation-form {
    width: 50%;
    margin: 30px auto;
    border: 1px solid #c1c1c1;
    padding: 30px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    position: relative;
}

.subHeading-excelstockForm {
    display: block;
    padding: 0 0 10px;
    font-size: 17px;
    margin: 0 0 20px;
    text-transform: uppercase;
    color: #4f4f4f;
    text-align: center;
    font-weight: 600;
    position: relative;
    /* font-family: "Open Sans",sans-serif; */
}

.subHeading-excelstockForm:after {
    content: "";
    position: absolute;
    width: 25%;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #04bab5;
    margin: auto;
}

.mainTabDiv-excelform .nav-tabs {
    border: 0;
    justify-content: center;
}

.mainTabDiv-excelform .nav-tabs li {
    float: none;
    display: inline-block;
    border-bottom: 2px solid transparent;
    margin: 0 7px;
}

.mainTabDiv-excelform ul li a {
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.mainTabDiv-excelform .tab-contentExcelform {
    float: none;
    width: 100%;
    padding: 15px 0;
}

.excelform-stockupdatelist {
    position: relative;
    padding: 0 10px 5px;
}

.menulabel-excelform {
    display: block;
    text-align: left;
    padding: 0 0 10px;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    float: none;
    text-transform: uppercase;
    color: #4f4f4f;
}

.mandatory-excelform {
    color: #ff0000;
    font-size: 12px;
    padding-left: 3px;
    vertical-align: top;
}

select.menuBlockSelect-excelform {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background: #fff;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.7);
    padding: 2px 6px;
    border-color: #8d8d8d;
    margin: 0 0 15px;
    border-radius: 5px;
}

.fileUploadDivExcelform {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.fileUploadDivExcelform {
    overflow: hidden;
}

.fileUploadDivExcelform * {
    display: inline;
}

.fileUploadDivExcelform input[type=file] {
    bottom: 0;
    cursor: pointer;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-300px, 0px) scale(4);
}

.fileUploadDivExcelformbutton {
    padding: 9px 20px;
    font-size: 13px;
    color: #222;
    border: 1px solid #222;
    border-radius: 5px;
    display: inline-block;
    position: relative;
}

.saveButtonDiv-excelform {
    text-align: right;
    padding: 30px 10px 0px;
    clear: both;
}

.saveButtonDiv-excelform .commonLink-excelformupdate {
    float: left;
    margin-top: 8px;
    color: #04bab5;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
}

.saveButtonDiv-excelform .commonButton {
    background: #04bab5;
    padding: 9px 22px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    text-align: center;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    border: 0;
    margin-left: 5px;
    font-size: 15px;
}

.mainTabDiv-excelform .react-tabs__tab-list {
    border-bottom: none;
    text-align: center;
}

.mainTabDiv-excelform .react-tabs__tab--selected {
    border-color: transparent;
    border-radius: none;
    border-bottom: 2px solid #fc6602;
}

.mainTabDiv-excelform .react-tabs__tab:focus {
    box-shadow: none;
}

.mainTabDiv-excelform .react-tabs__tab {
    padding: 6px 0px;
    margin: 10px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.fileUploadDivExcelformbutton:hover {
    background-color: #000;
    color: #fff;
}

.fileUploadDivExcelformbutton span {
    font-size: 13px;
    font-weight: 500;
}

.formInputSectionwidth-itemmap {
    width: 36% !important;
}

.selletitemMap-subheadtable {
    font-size: 16px;
}


/* .inputtextitemName{
    width: 90px;
}
.inputtextitemLevel{
    width: 85px;
}
.inputtextitembrand{
    width: 65px;
} */

.selleritempopup-inputwidth {
    width: 55px;
}

.sellerdatepopup-inputwidth {
    width: 120px !important;
    padding: 2px 3px !important;
    color: #000 !important;
    border-radius: unset !important;
    border: 1px solid #000 !important;
}

.itemmap-tdPadng {
    padding: 5px !important;
}

.customizationPopWidth {
    width: 94%;
}

.formInputSection-widthpopup {
    width: 40%;
}

.forminput-select-cursor {
    cursor: pointer;
}

.saveStoreIcondiv img {
    cursor: pointer;
}

.btn-close-popupitem {
    color: #FFF !important;
    font-size: 23px;
    right: 5px;
    top: 5px;
    position: absolute;
    width: 33px;
    height: 33px;
    background-color: #de0000;
    padding-top: 0;
    border-radius: 20px;
    padding-left: 8px;
    text-decoration: none !important;
    border: 2px solid #C00;
    font-weight: 700;
    line-height: 26px;
}

.autosearch-widthUl {
    width: 52%;
    right: 238px !important;
    left: auto !important;
}

.cursorpoint-icon-itemmap img {
    cursor: pointer;
}

.formInputSectioncursor-itemmap {
    cursor: pointer;
}


/* Notmapped item css */

.ms-brudcrumbs a {
    color: #000;
    font-size: 14px;
    margin: 0 8px;
}

.ms-brudcrumbs a:hover {
    text-decoration: none;
}

.ms-brudcrumbs a.active {
    border-bottom: 2px solid var(--common-color);
}

.ms-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.ms-button button {
    background: #23a196;
    color: #fff;
    font-size: 14px;
    padding: 8px 15px;
    margin-left: 15px;
}

.ms-brudcrumbs.ms-sublinks a {
    color: var(--common-color);
    font-weight: 400;
}

.ms-brudcrumbs.ms-sublinks {
    margin: 15px 0px;
}

.ms-table .inputtext {
    width: 100%;
    margin-bottom: 15px;
}

.ms-wraper .orderm-right-section .checkBoxSquare {
    font-size: 14px;
    margin: 0;
    margin-left: 15px;
    padding: 0;
}

.ms-wraper .orderm-right-section .checkBoxSquare label {
    margin: 0px 7px 0 5px;
}

.ms-table-outter {
    overflow-x: hidden;
    margin-bottom: 15px;
}


/* .ms-wraper th,
.ms-wraper td {
    min-width: 150px;
} */

.ms-table td span {
    color: #23a196;
    font-weight: 400;
    cursor: pointer;
}

.ms-wraper .dashboardTableMain {
    margin-bottom: 15px;
}

.ms-wraper .orderm-right-section {
    padding: 15px 10px 10px 10px;
    width: 95%;
}


/*  */

body .loginForm .loginFormList .autoSearch {
    width: 100%;
    /* display: block !important; */
}

body .dashButton.ms-cancel {
    text-align: left;
    background: transparent;
    display: inline-block;
    float: left;
}

.dashboardmenu.dashboardMenuWeb ul li a img {
    margin-right: 10px;
}

.mslogout {
    color: var(--common-color);
    border: 1px solid var(--common-color);
    padding: 8px 15px;
    border-radius: 0px;
    min-width: 200px;
}

.msContinueShopping.mslogout {
    color: var(--continue-shopping);
    border: 1px solid var(--continue-shopping);
    padding: 8px 15px;
    border-radius: 0px;
    min-width: 200px;
    margin-bottom: 8px;font-size: 15px;
}

.msloggineduser a {
    font-weight: 400 !important;
    font-size: 14px !important;
}

.msloggineduser a span {
    color: var(--common-color) !important;
    font-size: 17px !important;
}

.mslogout:focus {
    outline: none;
}

.logoutLink.text-center a,
.logoutLink1.text-center a {
    padding: 0px;
}


/*  */

.ms_preview_btn {
    margin-left: 45px;
}

.ms_preview_btn .commonButton {
    background: var(--preview-btn-bg);
}

.ms_grocery_prodinfo h1 {
    font-size: 14px;
    margin-bottom: 2px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ms_newPrice {
    font-size: 16px;
    font-weight: 400;
    color: var(--price-color);
    margin: 0 5px 0 0;
    line-height: initial;
}

.ms_grocery_img {
    width: 100%;
    margin: 0 auto;
}

.ms_grocery_img img {
    width: 100%;
}

.ms_offer_info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 11px;
    color: #990505;
    text-align: right;
    padding: 0 2px 0 0;
    top: 7px;
    right: 8px;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    width: 95%;
    font-weight: 400;
}

.ms_star {
    width: 16px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    display: inline-block;
    margin-right: 4px;
}

.ms_grocery_prodinfo {
    padding: 15px 0 30px;
}

.ms_head_btm {
    margin-bottom: 40px;
    text-align: left;
}

.ms_pagination.paginationSection {
    text-align: center;
    margin: 40px 15px 25px;
}


/* Styles for wrapping the search box */

.ms_search {
    width: 100%;
    position: relative;
    display: flex;
}

.ms_searchTerm {
    width: 100%;
    border: 1px solid var(--search-btn-bg);
    border-right: none;
    padding: 5px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: var(--search-btn-txt-clr);
    height: 36px;
    font-size: 12px;
}

.ms_searchTerm:focus {
    color: var(--search-btn-bg);
}

.ms_searchButton {
    width: 140px;
    height: 36px;
    border: 1px solid var(--search-btn-bg);
    background: var(--search-btn-bg);
    text-align: center;
    color: var(--common-button-color);
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 14px;
}


/*Resize the wrap to see the search bar change!*/

.ms_wrap {
    width: 50%;
    position: absolute;
    top: 18%;
    left: auto;
    right: 0px;
    transform: translate(-15px, -50%);
    margin-bottom: 40px;
}

.ms_rest_prodtype p {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
}

.ms_food_tye {
    text-align: left;
    position: absolute;
    left: 40px;
}

.ms_offer_info_details {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 11px;
    color: #990505;
    text-align: right;
    padding: 0px 0px 5px 0;
    top: 7px;
    right: 0px;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    width: 100%;
    font-weight: 400;
}

.ms_analytics {
    padding: 10px;
    border-radius: 6px;
    background: #fff;
    /* padding: 3px;
    border-radius: 6px;
    background: #efefefe0; */
    border: 2px solid var(--analytics_bg);
}

.ms_analytics p {
    text-align: right;
}

.ms_analytics h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 20px;
    color: var(--heading-color);
    text-align: right;
}

.ms_analytics h3 {
    margin: 0;
    font-weight: 300;
    font-size: 12px;
}

.ms_analytics h2 span {
    font-style: normal;
    padding-left: 5px;
    color: var(--heading-color);
}

.ms_analytics_icon {
    background: #f99209;
    padding: 20px;
    border-radius: 6px;
    position: absolute;
    top: -30px;
}

.ms_analytics_icon img {
    width: 30px;
    margin: 0 auto;
    display: flex;
}

.ms_analytics hr {
    margin: 4px 0;
}

.ms_analytics_bg .ms_margin_btm {
    margin-bottom: 60px;
}

.ms_analytics_icon.ms_chart {
    width: 100%;
    height: 200px;
    position: relative;
}

.ms_analytics.ms_chart_outer {
    /* height: 360px; */
    height: 505px;
}

.ms_analytics.ms_chart_outer p {
    text-align: left;
}

.ms_bg_green {
    /* background: #44a24a; */
    background: #fff;
    border: 1px solid var(--analytics_bg);
    border-radius: 6px;
}

.ms_analytics_icon.ms_chart {
    /* background: #44a24a; */
    background: #fff !important;
}

.ms_analytics_icon.ms_chart h4 {
    color: #000;
}

.ms_bg_orange {
    background: #fc920a !important;
}

.ms_bg_red {
    background: #e63f39 !important;
}

.ms_bg_blue {
    background: #952baf !important;
    height: 80px !important;
    display: flex;
    justify-content: left;
    align-items: center;
}

.ms_bg_orange2 {
    height: 80px !important;
    background: #f99209 !important;
}

.ms_bg_blue span,
.ms_bg_orange2 span {
    color: #000;
    font-size: 14px;
    margin: 0;
}

.ms_bg_blue span a {
    padding-right: 10px;
}

.ms_bg_orange2 p {
    color: #000;
}

.ms_analytics.ms_chart_outer.ms_diagram {
    /* height: 425px; */
    height: 560px;
    border: 2px solid var(--analytics_bg);
    width: 100%;
}

.ms_icn_green {
    background: #4ca64f;
}

.ms_icn_red {
    background: #e53d3a;
}

.ms_icn_sble {
    background: #08b3c7;
}

body .commonButton.ms_analytics_btn {
    background: transparent;
    position: relative;
    top: -15px;
    color: #fe8101;
    border: 1px solid #fe8101;
    border-radius: 0;
}

.ms_al_div {
    height: auto;
    top: 0;
    margin-bottom: 15px;
}

.ms_searchbtn {
    width: 100%;
}

.ms_analtcscard .ms_analytics {
    background: #e2e2e2ab;
}


/* Safari browser btn css */

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: none;
}

.log-paswrd-eye {
    position: absolute;
    top: 12px;
    right: 10px;
    display: block;
}


/*  */

.ms_page_input input,
.ms_page_input input:focus {
    width: 85px;
    height: 40px;
    text-align: center;
    border: 1px solid #dee2e6;
    color: var(--common-color);
    outline: none;
}

.ms_page_input button,
.ms_page_input button:focus {
    height: 40px;
    text-align: center;
    border: 1px solid #dee2e6;
    color: var(--common-color);
    border-radius: 0;
    font-size: 14px;
    border-left: none;
    padding: 0 10px;
    outline: none;
}

/*  */
.ms-orderm-search {
    width: 100%;
    margin: 0;
    margin-left: -15px;
}

.ms-selectIsLoose {
    padding: 8px 0px;
    font-size: 13px;

}

.ms-noImage {
    padding: 8px 10px;
    font-size: 13px;
}

body .commonButton.ms-preview {
    padding: 10px 15px;
    font-size: 13px;
}

.gsa-pwd-img {
    position: absolute;
    right: 15px;
    top: 15%;
}

/* New Login form section Starts */

.loginWraper-formDIv .login-page {
    width: 360px;
    padding: 11% 0 0;
    margin: auto;
}

.loginWraper-formDIv .forgot-pwd a {
    color: #585858;
    font-size: 13px;
}

.loginWraper-formDIv .login-header h3 {
    font-size: 26px;
}

.loginWraper-formDIv .login-page .form .login {
    margin-top: -21px;
    margin-bottom: 26px;
}

.loginWraper-formDIv .form {
    position: relative;
    z-index: 1;
    background: rgb(255 255 255 / 93%);
    max-width: 360px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.loginWraper-formDIv .form input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 11px;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
    border: 1px solid #e2e2e2;
}

.loginWraper-formDIv .form button {
    text-transform: uppercase;
    outline: 0;
    background-color: #328f8a;
    background-image: linear-gradient(45deg, #328f8a, #08ac4b);
    width: 100%;
    border: 0;
    padding: 10px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
}

.loginWraper-formDIv .form .message {
    margin: 15px 0 0;
    color: #b3b3b3;
    font-size: 12px;
}

.loginWraper-formDIv .form .messages {
    margin: 15px 0 0;
    color: #b3b3b3;
    font-size: 16px;
}

.loginWraper-formDIv .form .message a {
    color: #4CAF50;
    text-decoration: none;
}

.loginWraper-formDIv .form .message {
    color: #000;
    text-decoration: none;
}

.loginWraper-formDIv .form .messages {
    color: #000;
    text-decoration: none;
}

.loginWraper-formDIv {
    background-image: url("../images/defaultBanner.png");
    min-height: 100vh;
    background-size: cover;
    background-blend-mode: multiply;
    background-color: rgb(183 175 175 / 80%);
}

.already-logged {
    font-size: 28px;
    color: #26374d;
    font-family: "Montserrat Bold";
}

/* New Login form section Ends */
/* Square Payment PopUp*/
.locationPopUps1 {
    background: rgba(0, 0, 0, 0.6);
}

.locationPopUps1 .confirmWrappers {
    width: 440px;
    padding: 0px;
}

.paymentHead .paymentCancel {
    background: #ccc;
    color: #282828;
    border-radius: 5px;
    border-style: none;
    font-weight: 500;
    line-height: 24px;
    outline: none;
    user-select: none;
}

.paymentHead button {
    width: 47%;
    padding: 5px;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
}

.locationPopUps1 .paymentHead {
    margin: 0;
}

.paymentHead #card-number-wrapper .sq-input-wrapper.card-input-wrapper input,
.sq-input-wrapper.card-input-wrapper label {
    font-size: 13px !important;
    font-weight: 600 !important;
}

/* ============================ itemMappingStockUpdate new css added ============================================ */
/* .autoSearch_prodInfo{
    display: flex;
    justify-content: space-between;
} */
.autoSearch_prodInfo p {
    font-size: 12px;
    margin: 0;
    color: var(--text-heighlight-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 230px;

}

.autoSearch_prodInfo p span {
    font-weight: 300;
    color: #7d7d7d;
}

body .autoSearch.autoSearch_prodInfoCategory {

    width: 45%;
    right: 345px !important;
}

body .autoSearch.autoSearch_prodInfoCategory li {
    border-bottom: 1px solid #ccc;
}

/* ==================== Delivery User List css ============================ */
.deliveryUserThumbImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
    background: #dedede;
    transition: 1s;
    z-index: 2;
    position: relative;
}

.deliveryUserThumbImage:hover {
    transform: scale(1.5);
    z-index: 999;
}

.commonbtn_delivery {
    padding: 7px 20px;
    border-radius: 7px;
    float: right;
}

/* ============================ MRA 29 video ========================================== */
.fullVideoSection {
    width: 100%;
    height: auto;
    position: relative;
}

.fullVideoSection video {
    object-fit: cover;
}

.fullVideoSection::before {
    content: "";
    top: 0;
    left: 0;
    bottom: 6px;
    right: 0;
    position: absolute;
    background: rgba(33, 35, 49, .7);
    z-index: 0;
}

/*  */


.jquery-background-video-pauseplay {
    position: absolute;
    top: 43%;
    left: 48%;
    width: 60px;
    background: transparent;
    border: none;
}

.mra_vectorImg {
    position: relative;
    top: 0px;
    width: 27%;

}

.mra_vectorImg img {
    position: absolute;
    left: 0;
    width: 100%;
}

.categoryItemSection {
    position: relative;
}

.mra_vectorImgRight {
    /* position: relative;
    top: 200px; */
    width: 20%;
}

.mra_vectorImgRight img {
    position: absolute;
    right: 0;
    width: 27%;
    margin-top: 20px;
}

.categorySectionBg {
    background-size: cover;
    background-repeat: no-repeat;
}

.socialMediaIcons span {
    width: 30px;
    float: left;
    margin-right: 8px;
}

.socialMediaIcons span img {
    width: 100%;

}

.slick-slider .slick-dots li button {
    border: 1px solid #f0eeee;
    background: #f0eeee;
}

/* Footer dynamic */
.footerSection .menuListView {
    display: flex;
    justify-content: space-between;
}

/* Order Management css */
.orderMangmnt_payment {
    padding: 0 8px;
    width: 100%;
    float: left;
}

.orderMangmnt_payment .orderMangmnt_payment_left {
    width: 50%;
    display: block;
    float: left;
    margin-bottom: 5px;
}

.order_payment_icon {
    width: 18px;
    margin-right: 8px;
}

.deliveryBoyAssigned {
    font-weight: 500;
    color: #fff;
    background: rgb(25, 152, 141);
    padding: 2px 8px;
    border-radius: 5px;
    font-size: 11px;
}

/*  */

.deliveryBoySelect ul#menu {
    position: relative;
    top: 0;
    width: 100%;
    height: 3em;
    margin: 0;
    padding: 0 10px;
    background: transparent;
    color: #000;
    float: left;
    border: 1px solid #000;
    border-radius: 4px;
}

.deliveryBoySelect ul#menu>li {
    float: left;
    list-style-type: none;
    position: relative;
    width: 100%;
}

.deliveryBoySelect label {
    position: relative;
    display: block;
    padding: 0 19px 0 4px;
    line-height: 26px;
    cursor: pointer;
    font-size: 12px;
    margin: 0;
}

.deliveryBoySelect label:after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    right: 5px;
    width: 0;
    height: 0;
    border-top: 4px solid #000;
    border-bottom: 0 solid #000;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    transition: border-bottom .1s, border-top .1s .1s;
}

.deliveryBoySelect input {
    display: none
}

.deliveryBoySelect input:checked~ul.submenu {
    max-height: 285px;
    transition: max-height 0.5s ease-in;
    width: 203px;
    padding-left: 0;
    margin-left: -7px;
    margin-top: 6px;
    border-radius: 4px;
    box-shadow: 0px 2px 6px #282828c4;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.48);
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    cursor: pointer;
}

.deliveryBoySelect ul.submenu {
    max-height: 0;
    padding: 0;
    overflow: hidden;
    list-style-type: none;
    background: #fff;
    box-shadow: 0px 2px 6px #282828c4;

    transition: max-height 0.5s ease-out;
    position: absolute;
    width: 203px;
    margin-left: -7px;
    margin-top: 6px;
}

.deliveryBoySelect ul.submenu li {
    background: #fff;
    padding: 2px 8px;
    border-bottom: 1px solid rgb(0 0 0 / 20%);
    font-size: 12px;
}

.deliveryBoySelect ul.submenu li:last-child {

    border-bottom: 1px solid transparent;

}

.deliveryBoySelect ul.submenu li:hover {
    background: #f2f2f2;
}

.deliveryBoySelect ul.submenu li a {
    display: block;
    padding: 12px;
    color: #000;
    text-decoration: none;
    white-space: nowrap;
}

.deliveryBoySelect ul.submenu li a:hover {
    background: #f2f2f2;
}

.deliveryBoyUl {
    padding: 0px;
    list-style: none;
}

.deliveryBoyChange {
    background: none;
    border: none;
    color: blue;
    float: right;

}

.taxDetailsTbleSpan {
    display: inline-block;
}

.taxDetailsTbleSpan:first-child {
    width: 200px;
}

.taxDetailsTbleSpan:nth-child(2) {
    width: 100px;
}

.taxTableRowInner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border: 1px solid #f2f2f2;
}

.taxTableRowInner:nth-child(odd) {
    background-color: #f2f2f2;
}

.loading {
    animation: extend 3s steps(3, end);
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;
}

.loading:before {
    content: "...";
}

@keyframes extend {
    0% {
        width: 0.25em;
    }

    100% {
        width: 1em;
    }
}

#Processing {
    font-size: 12px;
    color: green;
    font-weight: bold;
}

.addTaxBtn {
    color: #fff !important;
}

#popup1 .customizationPop.addTaxPopup {
    width: 530px;
}

#popup1 .customizationPop.addTaxPopup .addStoreSectionList {
    width: 100%;
}

body .customizationPop.addTaxPopup .addStoreSectionList .formInputField {
    font-size: 12px;
}

body .formInputField.taxDisabled:focus {
    border-color: 1px solid rgba(151, 151, 151, 0.37) !important;

}

.storecustomizationPop {
    width: 500px;
}

.taxSplitScroll {
    overflow-y: auto;
    max-height: 235px;
}

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ddd;

}

#style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #ddd;
}

#style-3::-webkit-scrollbar-thumb {
    background-color: #000;
}

body .customizationPop.addTaxPopup .addStoreSectionList .formInputField {
    width: 125px;
}

body .customisBtn {
    cursor: pointer;
}

.orderm-fieldheight {
    height: 40px;
    font-size: 12px;
}

.commonButton .commonBtn_search {
    line-height: 22px;
}

.headtext_wraper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}

.headtext_wraper .paginationSection {
    margin-bottom: 0;

}

/* Add Item Tax css begins here */
.ms_addItemTax {
    width: 80px;
    cursor: pointer;
    transition: transform .2s;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.ms_addItemTax:hover {
    transform: scale(1.5);
    transform: scale(1.5);
}

.ms_taxGroupDetails {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #28282899;
    padding: 2px 5px;
}

.ms_taxGroupDetailsPop {
    width: 100%;
    border-bottom: 1px solid #28282894;
    padding: 2px 5px;
    margin: 0;
    display: flex;
    align-items: center;
}

.taxSplitScroll.taxAddItemTax {
    display: inline-block;
    width: 100%
}

.orderm-coverWraper.ms_taxWrap .pagination li a,
.orderm-coverWraper.ms_taxWrap .pagination li span {
    min-height: 35px;
    min-width: 45px;
    padding: 13px 4px;
    height: 100%;
    width: auto;
}

.table-bordered.msTaxTble {
    border: 1px solid #28282894;
}

.ms-taxSubList {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #28282899;
}

.ms-taxSubList:last-child,
.ms_taxGroupDetailsPop:last-child {
    border-bottom: none;
}

.taxAlreadyAdded {
    cursor: not-allowed;
    position: relative;
    width: 100%;
    background: #ddd !important;
    color: #282828 !important;
    border-color: #ddd !important;
}

.csvbtn_export {
    margin-left: 0px;
    cursor: pointer;
    /* margin-top: 5px; */
}

.reportsformfield .formImage {
    left: 28px;
    top: 10px;
}

.reportsformfield .orderm-field {
    height: 40px;
    padding: 2px 35px;
}

.dashButtonEdit {
    min-width: 100px;
    line-height: 22px;
}

.closeimgmargin {
    margin: 6px 10px;
}

.storecustomizationPop {
    width: 500px;
}

.taxSplitScroll {
    overflow-y: auto;
    max-height: 235px;
}

#style-3::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ddd;

}

#style-3::-webkit-scrollbar {
    width: 6px;
    background-color: #ddd;
}

#style-3::-webkit-scrollbar-thumb {
    background-color: #000;
}

body .customizationPop.addTaxPopup .addStoreSectionList .formInputField {
    width: 125px;
}

.orderm-fieldheight {
    height: 40px;
}

.commonButton .commonBtn_search {
    line-height: 22px;
}

.headtext_wraper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}

.headtext_wraper .paginationSection {
    margin-bottom: 0;
}

.ms_taxTableData table th,
.ms_taxTableData table td {
    border: 1px solid #dee2e6;
    padding: 3px;
}

.ms_taxTableData {
    display: flex;
    justify-content: left;
    align-items: end;
    padding-bottom: 10px;
    padding-left: 25px;
}

@media print {
    .printBtnInvoice {
        display: none;
    }

    #container,
    .page,
    .head,
    .invoiceContainer,
    .ms_tbleWraps,
    .invoice_hldr,
    .line {

        width: 860px !important;

        font-size: 16px !important;
        margin: 0 auto !important;
    }

    .invoiceContainer {
        display: flex;
        justify-content: space-between;
    }

    .ms_inv_footer {
        padding: 2% 0;
    }

    .head {
        margin: 30px !important;
    }

    .line {
        margin-top: 15px !important;
    }
    .head2{
        font-size: 14px !important;
        
    }
    .invoiceCol1,.address2,.address,.col1,.col2,.colWide,.col6,.uSaved,.ms_inv_footer{
        font-size: 14px !important;
      
    }
    .ms_odrDetails{
        font-size: 13px !important;
       
    }
    .col1:nth-child(3),.col2{
       
        width:310px !important;
    }
    .colWideTotal{
        font-size: 14px !important;
        
    }

}
body .commonButton.clearbtn_padd{
    padding: 9px 20px;
    font-size: 14px;
}
body .commonButton.commonBtn_search{
    font-size: 14px;
}
.itemCategoryTitle span span{
    display: block;
}
.itemCategoryTitle {
    text-align: center;
}
.itemCategoryTitle span{
    display: block;
}
.productDetailAbout h3 {
    background: #26374d;
    display: inline-block;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    padding: 12px 25px;
    font-size: 16px;
}.itemProfile p {
    font-size: 16px;
}

.MraCoverWraper .loader,
body .mraLoader.loader,.loaderTxt {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #c3161c !important;
  letter-spacing: 0.2em;
  z-index: 2;
}

.MraCoverWraper .loader::before,
.MraCoverWraper .loader::after,
body .mraLoader.loader::before,
body .mraLoader.loader::after,.loaderTxt::before,.loaderTxt::after {
  content: "";
  display: block;
  width: 15px;
  height: 10px;
  background: #c3161c !important;
  position: absolute;
  animation: load .7s infinite alternate ease-in-out;
}

body .mraLoader.loader{
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #c3161c !important;
  letter-spacing: 0.2em;
  z-index: 2;
}

body .mraLoader.loader::before,
body .mraLoader.loader::after
{
  content: "";
  display: block;
  width: 15px;
  height: 10px;
  background: #c3161c !important;
  position: absolute;
  animation: load .7s infinite alternate ease-in-out;
}

.itemCategoryTitle { 
    font-size: 37px;
}
.contentWrap.subDomainWrap{
    padding: 230px 0 0px;
}
.contentWrap.loakalcontentWrapTags {
    padding: 145px 0 0px;
}
.searchDetails .offerText {
     text-align: left;
}
body .logoutButton:hover{
    color: var(--header-button-text);
    text-decoration: none;
}
.gmap-divouter{
    position: relative;
    width: 100%;
    /* height: 100vh; */
    height: 200px
}
.productItemName.productItemNameSize{
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
}
.confirmWrappers.sizechart-width{
    width: 600px;
    top: 100px;
}
.globalSearchRow .seemore-link{
    width: 100%;
    text-align: right;
    padding-right: 20px;
    font-weight: 400;
    font-size: 14px;
}.globalSearchContent .seemore-link{
    width: 100%;
    text-align: right;
    padding-right: 0px;
    font-weight: 400;
    font-size: 14px;display: block; 
}
 .dentalcontentWrap {
    height: auto;
    min-height: auto;    padding-bottom: 0;
 }
 .shipAddressActionposition{
    position: unset;
 }
 .checkoutItemDetailList.justify-spacebtwn{
    justify-content: space-between;
    padding: 25px 30px;
    border-radius: 15px;
 }
 .shippingAddressContent-width{
    width: 75%;
    padding: 0 15px;
 }
 .shippingadressList-radio .radioSection label{
    width: 22px;
    height: 22px;
    margin-right: 0;
 }
 .shippingadressList-radio{
    background: transparent;
    border-bottom: unset;
    margin: 0;
    padding: 0;
 }
 .shippingAddressContent-width p{
    font-size: 14px;
    color: #000;
 }
 .add-address-box{
    border: 1px dashed var(--common-color);
    box-sizing: border-box;
    border-radius: 15px;
    padding: 25px 30px;
    width: 100%;
    text-align: center;
    background: aliceblue;
    color: var(--common-color);
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 20px;
 }
 .shppngList-bottm-wraper{
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-top: 20px;
 }
 .shppngList-bottm-sec{
    display: flex;
    align-items: center;
 }
 .text-shippingadd-new{
    margin-left: 10px;
    font-size: 15px;
    color: #000;
    text-align: left;
    margin-right: 10px;
 }
 .head-shippingaddress{
    font-size: 18px;
    margin-bottom: 14px;
 }
 .shippingAddressList-outer-w{
    width: 85%;
    margin: 0 auto;
 }
 .no-wrap{
    white-space: nowrap;
 }
 body .dashButton.dashfeedBackBtn {
    display: flex;
    align-items: center;    padding: 2px 10px !important;
 }
 body .dashButton.dashfeedBackBtn:hover{
    color: #fff;
    text-decoration: none;
 }
 /* ======================================== */
.goOnZoom{
    background-repeat: no-repeat;    width: 100%;
    margin: 0 auto;
}
.goOnZoomInfo h3{
    font-size: 18px;
    color: #000;
 }
.goOnZoomInfo p{
    font-size: 14px;
    margin: 0;color: #000;
}
  .ViewMoreIcon{
    position: absolute; 
    top: 2px;
    right: 5px;    color: #099995;font-size: 15px;cursor: pointer;
  }
  .globalDataPopClose{ 
    position: absolute;
    right: 1px;
    top: 3px;
    border: none;
    background: none;
    opacity: 1;
  }
  .globalDataPopClose span img{
    width: 82%;height: auto;
  }
  .globalDataPopClose:focus{
     outline: none;
  }
  .globalDataPopClose .close{
    opacity: 1;
  }.globalDataPopClose .close:hover{
    opacity: .5;
  }
  .GlobalDataPopWrp h3{
    font-size: 16px;margin: 0px;
  }

  /*  */
  figure.goOnZoom {
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: zoom-in;
    margin-bottom: 15px;
  }
  figure.goOnZoom img {
    transition: opacity .5s;
    display: block;
      width: 100%;cursor: zoom-in;
  }   
  figure.goOnZoom img:hover { opacity: 0; }
  .dentalprodInfos{
    width: 100%;
    max-height: 420px;
    overflow-y: auto;
    /* margin-top: 30px;
    padding-right: 10px; */
  }
  .GlobalDataPopWrp .confirmWrappers{
    border-radius: 4px;
  }
  
.dentalprodInfos {
    overflow: auto;
}
 
.dentalprodInfos::-webkit-scrollbar  {
    width: 5px;
}

/* Track */
 
.dentalprodInfos::-webkit-scrollbar-track
  {
    background: #403c3c40;
}

/* Handle */
 
.dentalprodInfos::-webkit-scrollbar-thumb  {
    /* background: #099995; */
    background: #099995ad;
} 
/* Handle on hover */
 
.dentalprodInfos::-webkit-scrollbar-thumb:hover  {
    /* background: #099995; */
    background: #099995ad;
}
.dentalprodInfos figure img{
    width: 100%;
    border: 1px solid #b2b2b285;
    border-radius: 4px;
    height: 255px;
    object-fit: contain;
}
   
/* ================= */
.globalSearchRow{

}

.globalSearchRow::-webkit-scrollbar  {
    height: 5px;
    
}

/* Track */
 
.globalSearchRow::-webkit-scrollbar-track
  {
    background: #403c3c40;
}

/* Handle */
 
.globalSearchRow::-webkit-scrollbar-thumb  {
    /* background: #099995; */
    background: #099995ad;
} 
/* Handle on hover */
 
.globalSearchRow::-webkit-scrollbar-thumb:hover  {
    /* background: #099995; */
    background: #099995ad;
}
.globalSearchContent .seemore-link{
    color: #099995;
}
body .logoutButton{
    background: var(--common-color);
}
.shippingAddressList-outer-w.shipDataWrP{
    width: 100%;
}
/* .shippingAddressListDiv.continueBtn{
    width: 93%;
} */