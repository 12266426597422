@media (max-width:1600px) {
    .ms_analytics.ms_chart_outer {
        height: 425px;
    }
}

@media (max-width:1366px) {
    .ms_analytics.ms_chart_outer {
        height: 360px;
    }
    .ms_analytics.ms_chart_outer.ms_diagram {
        height: 415px;
    }
    .ms_analytics b {
        font-size: 14px;
    }
}
@media (max-width:1199px){
    /* .gsa-header-center .searchField {
        position: relative;
        border-radius: 0px;
        top: 10px;
        width: 30% !important;
        margin: 0 auto;
    } */
    .contentWrap.subDomainWrap {
        padding: 265px 0 0px;
    }
}
@media (max-width:1180) {
    .orderSummarySection {
        padding: 40px 20px;
    }
}

@media (max-width:1024px) {
    .adminFooter {
        position: absolute !important;
        width: 100% !important;
        bottom: 0 !important;
    }
    .footer {
        position: absolute;
        /* bottom: 0; */
        width: 100%;
    }
    .csvDivstore {
        padding-left: 5px;
        margin-left: 10px;
    }
    .csvDivelectronic {
        padding-left: 5px;
    }
    .csvDivpayment {
        margin-left: 10px;
        padding-left: 80px;
    }
    .csvDivsite {
        margin-left: 0;
    }
    .csvDivtable {
        margin-left: 20px;
    }
    .storelistWraperPadng {
        padding-left: 85px !important;
    }
    .lklSellerSignupWrap .lkbannerPincode input[type="text"] {
        height: 35px;
        font-size: 14px;
    }
    .lkbannerContentmargin p {
        margin: 0 0 0 !important;
    }
    .lkbannerContentmargin {
        top: 6% !important;
        left: 6% !important;
    }
    .paymentConfirmedDiv {
        margin-top: 15px;
    }
    .paymentTextHeadTop {
        margin-top: 10px;
    }
    .custom-checkbox {
        margin-bottom: 0;
    }
    .commonBtnPadngBcus {
        padding: 7px 13px !important;
    }
    .autosearch-widthUl {
        width: 45%;
        right: 210px !important;
        left: auto !important;
    }
    .ms-brudcrumbs a {
        margin: 0 6px;
    }
    .ms-wraper .footer {
        position: relative!important;
    }
    .ms-brudcrumbs a {
        margin: 0 0px;
        margin-right: 10px;
    }
    .ms_wrap {
        width: 70%;
    }
    .ms_pagination.paginationSection {
        margin: 0;
    }
    .ms_analytics h2 {
        font-size: 14px;
        margin: 0 0 15px;
    }
    .ms_analytics p {
        font-size: 10px;
    }
    .ms_analytics.ms_chart_outer {
        height: 295px;
    }
    .ms_analytics.ms_chart_outer.ms_diagram {
        height: 350px;
    }
    
    .accountdetails{
        padding-top: 20px;   
    }
    .gsa-mycart {
        font-size: 22px !important;
    }
}

@media (min-width:993px) {
    .cart-mobile-global {
        display: none;
    }
    .cart-web-global {
        display: block;
    }
    .storelistWraperPadng {
        padding-left: 10px;
    }
    .mainCategory li:hover {
        background: #eaeaea;
    }
    .mainCategory li:hover .subCategory {
        display: block;
    }
    .subCategory li:hover .subSubCategory {
        display: block;
    }
    .subCategory li:hover {
        background: #f5f5f5;
    }
    .subSubCategory li:hover {
        background: #eaeaea;
    }
    .addStoreSectionList-inblock {
        display: inline-block;
    }
    .ms_analytics_bg .ms_margin_btm {
        margin-bottom: 15px;
        margin-top: 15px;
    }
}

@media (max-width:992px) {
    .loginWraper-formDIv .login-page {
        width: 360px;
        padding: 8% 2px 1px;
        margin: auto;
        position: absolute;
        top: 50%;
        right: 25%;
        transform: translateY(-50%);
    }
    .headerTop {
        padding: 3px 10px;
    }
    .storeNameTop {
        font-size: 11px;
        display: none;
    }
    .headerDropLink {
        margin: 0px;
    }
    .headerBottom {
        padding: 0 10px;
        position: relative;
    }
    .toggle {
        margin: 10px 10px 0 0;
    }
    .logo img {
        width: 120px;
    }
    body .lokalLogo img {
        width: 35px;
    }
    .locationPicker img {
        width: 18px;
    }
    .category {
        font-size: 12px;
        height: 30px;
        width: 30%;
        z-index: 2;
    }
    .locationPicker span {
        font-size: 10px;
        position: absolute;
        right: 10px;
        bottom: -15px;
        width: 135px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .searchField input[type=text] {
        width: 100%;
        height: 30px;
        font-size: 11px;
        padding: 2px 10px;
    }
    .searchField img {
        right: 7px;
        bottom: 8px;
        width: 14px;
    }
    .headerImage {
        padding: 0;
        display: block;
        position: relative;
        line-height: 1;
        font-size: 11px;
        font-weight: 600;
    }
    body .mainButton {
        background: transparent;
        height: auto;
        margin: 0px;
        line-height: 1;
        display: block;
        text-align: center;
        padding: 11px 0 0 0;
        color: #000;
        font-weight: 600;
        font-size: 11px;
    }
    .mainButton img {
        display: block;
    }
    .cartCount {
        top: 4px;
        font-size: 9px;
        width: 20px;
        height: 20px;
        line-height: 18px;
        position: absolute;
        display: inline-block;
        right: auto;
        margin: 0 0 0 -7px;
    }
    .homeTab .productList,
    .itemListDiv .productList {
        width: 50%;
    }
    .homeWrap {
        width: 100%;
        padding: 0 15px;
    }
    .productImage {
        padding: 0 0 5px;
    }
    .productImage img {
        width: 90%;
    }
    .productDetails {
        padding: 10px 5px 15px;
    }
    .homeTab .react-tabs__tab-panel {
        padding: 0 0 0;
    }
    .brandName {
        font-size: 11px;
        color: #505050;
        top: -3px;
    }
    .productPrice {
        height: 20px;
        line-height: 1;
    }
    .newPrice {
        font-size: 12px;
        margin-right: 5px;
    }
    .oldPrice {
        font-size: 11px;
    }
    .oldPrice:after {
        top: 5.5px;
    }
    .productQty {
        font-size: 11px;
    }
    .productName {
        font-size: 13px;
    }
    .addCartButton {
        margin: 13px 0 0;
    }
    .saveMore {
        font-size: 11px;
        top: -18px;
    }
    .cartButton {
        font-size: 9px;
        padding: 6px 3px;
    }
    .headerSearch {
        width: 100%;
        margin: 12px 0 0;
        position: initial;
        padding: 0;
    }
    .searchField {
        width: 70%;
    }
    .headerBottom {
        padding: 5px 10px 0;
    }
    .headerLeft {
        width: 100%;
        padding: 7px 0;
    }
    .headerRight {
        width: auto;
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        left: auto;
        padding: 0;
    }
    .headerDropText svg,
    .headerImage svg,
    .headerDropLink svg {
        height: 15px;
        margin: 0 0 0 1px;
        font-size: 15px;
    }
    .headerDropText,
    .headerImage,
    .headerDropLink {
        font-size: 11px;
    }
    .headerDropText {
        max-width: 65px;
    }
    .headerCartBtn svg {
        margin: 0 3px 0 0;
    }
    .cartCount {
        font-size: 11px;
        width: 21px;
        height: 21px;
        top: -8px;
        right: -8px;
        line-height: 21px;
    }
    .headerCartBtn {
        padding: 5px 13px 5px 7px;
    }
    .headerIcons {
        margin: 0 0 0 20px;
        width: auto;
        height: auto;
    }
    .locationTextDiv {
        font-size: 10px;
        padding: 2px 0px 0 0px;
        left: auto;
        max-width: 160px;
    }
    .logo img {
        width: 120px;
        height: 35px;
    }
    .dec,
    .inc {
        height: 20px;
        width: 20px;
    }
    .offerText {
        font-size: 11px;
        padding: 5px 8px 3px;
        top: 0;
        right: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.82);
        text-align: right;
    }
    footer {
        padding: 10px 15px;
        font-size: 11px;
    }
    .footer {
        display: none;
    }
    .categoryItemDiv {
        padding: 7px 15px 5px 5px;
        height: 100%;
        line-height: 1;
    }
    .categoryArrow {
        border-width: 6px 4px 0 4px;
        top: 11px;
    }
    .shareDiv {
        width: 140px;
        bottom: 45px;
        padding: 5px;
        text-align: right;
    }
    .cartNumber {
        padding: 0px;
    }
    .shareDiv a,
    .shareDiv span {
        font-size: 0;
        display: inline-block;
    }
    .contentWrap {
        padding: 95px 0 10px;
        clear: both;
    }
    .loginPop {
        width: 360px;
        top: 10px;
    }
    .confirmWrappers {
        width: 360px;
        top: 10px;
    }
    .loginHead {
        padding: 15px 40px 15px 25px;
        text-align: left;
    }
    .loginForm {
        padding: 15px 25px;
        text-align: left;
    }
    .homeTab .react-tabs__tab,
    .homeTab .react-tabs__tab--selected {
        font-size: 11px;
        margin: 0 10px;
    }
    .faceBook,
    .google {
        width: 149px;
    }
    .faceBook img {
        width: 8px;
        margin: 0;
        left: 33px;
    }
    .google img {
        width: 13px;
        margin: 0;
        left: 19px;
    }
    /* .cartProductSection {
        max-height: 335px;
    } */
    .cartItemList {
        max-height: 335px;
    }
    .loginText {
        font-size: 22px;
    }
    .loginFormListText {
        font-size: 10px;
        color: #000000;
    }
    .loginFormList {
        padding-bottom: 10px;
    }
    .cartDiv {
        position: fixed;
        width: auto;
        /* left: 5px; */
        left: auto;
        bottom: 0;
        box-shadow: none;
        top: 40px;
        z-index: 2;
        right: 0;
        width: 375px;
    }
    .userdropdown {
        z-index: 2;
    }
    .loginClose,
    .saveClose {
        top: 14px;
    }
    .categoryDrop {
        position: fixed;
        padding: 0;
        bottom: 0;
        box-shadow: 2px 0px 10px 0 rgba(117, 117, 117, 0.29);
        width: 315px;
        text-align: left;
        top: 89px;
        overflow-y: auto;
        min-height: auto;
        left: 10px;
    }
    .subCategory,
    .subSubCategory {
        position: relative;
        width: 100%;
        left: 0;
        top: 0;
        bottom: auto;
        box-shadow: none;
        padding: 0;
    }
    .subCategory ul,
    .subSubCategory ul {
        float: none;
        width: 100%;
        height: auto;
    }
    .itemSection {
        margin: 0;
    }
    .dashboardContent {
        width: 100%;
        display: block;
    }
    .dashTitle {
        padding: 0 15px 20px;
        font-size: 20px;
        text-align: center;
    }
    .dashSection {
        padding: 0 15px;
    }
    .addressForm {
        width: 100%;
        padding: 10px 0 20px;
    }
    body .labelField {
        width: 30%;
        padding-top: 0px;
        font-size: 11px;
    }
    body .formInputField,
    body .formInputTextarea {
        height: 30px;
        font-size: 11px;
    }
    .formInputSection {
        /* width: 70%; */
        width: 65%;
        padding: 0 0 0 20px;
    }
    .groupBoxForm {
        width: 100%;
    }
    .formFieldButtonSec {
        text-align: center;
    }
    .breadcrumb-ul {
        padding-left: 104px;
        margin-top: 14%;
    }
    .orderm-search-wrapper {
        width: 100%;
    }
    .adressButtonTop {
        padding-top: 30px;
        text-align: center;
    }
    .dashboardmenu {
        display: none;
        width: 240px;
        min-height: auto;
        position: absolute;
        left: 0;
        top: 35px;
        z-index: 2;
        box-shadow: 0 0 10px #ddd;
        padding: 30px 0 0;
    }
    .dashboardToggle {
        display: block;
        position: absolute;
        left: 8px;
        border: 1px solid #ddd;
        top: auto;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 5px;
        line-height: 17px;
        z-index: 3;
    }
    body .commonButton {
        /* height: 31px; */
        font-size: 12px;
    }
    body .formInputTextarea {
        height: 100px;
    }
    .numberField .formInputField {
        width: 85%;
    }
    .analyticSection select {
        margin: 0 auto 8px;
        font-size: 14px;
        width: 100%;
        display: block;
    }
    .analyticSection {
        font-size: 14px;
        text-align: center;
    }
    .ReactTable .-pagination .-pageSizeOptions {
        display: none;
    }
    .ReactTable * {
        font-size: 12px;
    }
    .monthlyItem label,
    .monthlyItemLarge label,
    .monthlyItem span {
        font-size: 11px;
    }
    .monthlyItem {
        padding: 5px;
    }
    .monthlyItem select,
    .monthlyItemLarge select {
        padding: 1px 5px;
        height: 28px;
        font-size: 11px;
    }
    .dashboardTable .ReactTable .rt-thead .rt-tr,
    .dashboardTable .ReactTable .rt-tbody .rt-td {
        font-size: 11px;
    }
    .dashBoardCenterText {
        font-size: 12px;
        margin: 0 0 15px;
    }
    .searchItemDiv {
        width: 33.333%;
        padding: 4px;
    }
    .searchItemDiv .commonButton {
        width: 100%;
        margin: 0;
        text-align: center;
    }
    body .searchInputField {
        height: 31px;
        font-size: 12px;
        padding: 2px 8px;
        border-radius: 5px;
    }
    .dashboardmenu li a,
    .dashboardmenu li li a {
        font-size: 11px;
        padding: 5px 5px 5px 25px;
    }
    .dashboardmenu li li a {
        padding: 5px 5px 5px 40px;
    }
    .dashboardActive:after {
        width: 5px;
    }
    .logoutLink {
        padding-top: 15px;
    }
    .productList {
        width: 50%;
    }
    .nodataItem {
        padding: 30px 0;
        font-size: 11px;
    }
    .formInputText {
        padding: 0;
        font-size: 11px;
    }
    .storeTrack {
        font-size: 12px;
        margin: 0 0 5px;
    }
    .storeTrackDate {
        font-size: 11px;
    }
    .trackPath span {
        width: 100%;
        font-size: 11px;
        padding: 0px 0 0 25px;
        height: 50px;
    }
    .trackPath {
        display: block;
    }
    .trackPath span:after {
        top: -100%;
        bottom: 0;
        height: 100%;
        width: 2px;
        left: 6px;
    }
    .itemFilterSection {
        width: 100%;
        display: none;
    }
    .itemListSection {
        width: 100%;
        padding: 0 5px;
    }
    .cart_clk {
        width: calc(100% - 81px);
        padding: 6px 3px;
        min-width: 66px;
    }
    .qty {
        right: 1px;
    }
    .tagSection {
        width: 100%;
    }
    body .tag {
        padding: 3px 5px;
        font-size: 10px;
        border-radius: 5px;
        margin: 2px;
    }
    .sortBy {
        font-size: 10px;
        padding: 3px 0 0;
    }
    .filterSection {
        padding: 6px 0 0;
    }
    header {
        z-index: 4;
    }
    .dashBoardMenuMobile {
        display: block;
    }
    /* .dashboardMenuWeb
    {
        display: none;
    } */
    .sidemenu {
        width: 100%;
    }
    .menuListView {
        padding: 40px 25px 20px;
    }
    .menuClose {
        left: auto;
        top: 5px;
        right: 20px;
    }
    .mainMenuList li a,
    .mainMenuList li a:hover,
    .contactList label,
    .contactList {
        font-size: 12px;
    }
    .mainMenuList ul li {
        padding-bottom: 3px;
    }
    .menuName {
        font-size: 15px;
        margin-bottom: 10px;
    }
    .itemAddCount {
        font-size: 36px;
    }
    .homeHeader {
        padding: 10px 15px;
    }
    .homeHeaderName {
        font-size: 0;
    }
    .homeHeader .toggle {
        width: 25px;
        margin: 10px 20px 0 0;
    }
    .homeHeader .storeNumber {
        padding: 4px 0 0;
        font-size: 17px;
    }
    body .startShopping,
    body .startShopping:hover {
        padding: 10px 20px;
        border-radius: 7px;
        font-size: 14px;
        letter-spacing: 1px;
    }
    .cartEmpty img {
        width: 80px;
    }
    .productItemLeft {
        width: 68%;
        text-align: left;
    }
    .productItemRight {
        width: 32%;
    }
    .productItemImage img {
        width: 100%;
    }
    .cart {
        text-align: left;
    }
    .productItemPrice {
        line-height: 1;
    }
    .headerDropText {
        max-width: 105px;
        padding: 0 20px 11px 0;
        margin: 0 15px 0 0;
        font-size: 11px;
    }
    .headerDropText .categoryArrow {
        top: 5px;
    }
    .headerDropText p {
        font-size: inherit;
    }
    .headerDropText span {
        font-size: 10px;
        top: 14px;
    }
    .storeTitle {
        font-size: 20px;
    }
    .storeList {
        width: 100%;
        display: block;
        padding: 5px 5px;
    }
    .categoryList {
        width: 50%;
    }
    .popFormHalf {
        width: 100%;
        margin: 10px 0 0;
    }
    .customizeContent {
        padding: 25px 30px;
    }
    .formWidthSection,
    .popFormHalf .formWidthSection {
        width: 230px;
        padding: 0 0 0 15px;
    }
    .react-datepicker-wrapper {
        display: block;
    }
    .formImage {
        width: 14px;
        left: 21px;
        top: 8px;
    }
    .bookButtonSection {
        padding: 20px 30px;
    }
    .bookButtonSection p {
        font-size: 10px;
        width: 65%;
    }
    body .mainPopButton {
        font-size: 12px;
        padding: 7px 12px;
    }
    .customizeItemName {
        font-size: 17px;
        padding: 0 0 15px;
    }
    .customizeItemExtra h4 {
        font-size: 13px;
        margin-bottom: 10px;
    }
    .customizeItemExtra ul {
        padding: 0 0 0 10px;
    }
    .customizeExtraName {
        width: 84%;
        padding: 0 0 0 5px;
        font-size: 12px;
    }
    .customizePriceText {
        font-size: 16px;
    }
    .filterText {
        display: inline-block;
        background: #ddd;
        padding: 3px 10px;
        font-weight: 600;
        font-size: 13px;
        text-transform: uppercase;
        border-radius: 3px;
    }
    .fliterCoverDiv {
        position: absolute;
        background: #fff;
        width: 250px;
        z-index: 2;
        box-shadow: 0 1px 5px rgba(0, 0, 0, .16);
        padding: 2px;
        left: -250px;
        margin: 3px 0 0;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -moz-transition: all 0.3s;
        min-height: 400px;
    }
    .fliterList ul li {
        font-size: 11px;
        padding: 0 0 3px 15px;
        line-height: 16px;
    }
    .fliterMenuOpen {
        left: 0;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -moz-transition: all 0.3s;
    }
    body h5 {
        font-size: 14px;
        margin: 0 0 5px;
    }
    .categoryInnerName a,
    .fliterList ul a {
        font-size: 12px;
        padding: 3px 0;
    }
    .fliterList {
        padding: 0;
    }
    .itemFilterSection {
        display: block;
        padding: 0 0 5px;
    }
    .itemProfile {
        padding: 10px;
    }
    .profileImage {
        width: 100%;
        padding: 0 30px 20px;
    }
    .profileDetails {
        width: 100%;
    }
    .profileItemBrand {
        font-size: 11px;
    }
    .profileItemName {
        font-size: 13px;
        margin: 0 0 5px;
    }
    .radioSection label {
        top: 4px;
    }
    .radioSection {
        font-size: 11px;
    }
    .profileItemQty {
        margin: 0 0 15px;
    }
    .productDetailPrice .oldPrice {
        font-size: 100%;
    }
    .productDetailPrice .oldPrice:after {
        top: 13px;
        border-width: 2px;
    }
    .productDetailPrice .newPrice {
        font-size: 130%;
    }
    h3 {
        font-size: 15px;
        margin: 0 0 10px;
    }
    h6 {
        font-size: 14px;
        margin: 0 0 10px;
    }
    p {
        font-size: 12px;
        margin: 0 0 4px;
    }
    .relatedSlider {
        padding: 10px 10px;
    }
    .subTitle {
        font-size: 20px;
        margin: 0 0 10px;
    }
    .relatedSlider .slick-prev,
    .relatedSlider .slick-next {
        height: 40px;
        width: 23px;
    }
    .relatedSlider .slick-next:before,
    .relatedSlider .slick-next:before,
    .relatedSlider .slick-prev:before,
    .relatedSlider .slick-prev:before {
        width: 10px;
        height: 23px;
    }
    .mrpTotalList {
        font-size: 11px;
    }
    .cartSaveAmount {
        font-size: 10px;
    }
    .cartGrandTotal {
        font-size: 13px;
    }
    .cartDiv .checkoutButton {
        padding: 5px 20px;
    }
    .shopClose {
        top: 3px;
    }
    .titleHeading {
        font-size: 20px;
        padding: 15px 10px;
    }
    .orderSummary {
        padding: 0 10px 15px;
    }
    .orderLeft {
        width: 100%;
        padding: 0;
    }
    .orderRight {
        display: none;
    }
    body .borderButton {
        padding: 6px 18px;
        font-size: 11px;
    }
    body .accordion__panel {
        padding: 15px 10px;
    }
    body .commonLargeButton,
    .summaryButtonSection .commonButton {
        padding: 7px 25px;
        margin: 0 0 0 10px;
        height: auto;
    }
    .orderSection .shopLogo img {
        width: 50px;
    }
    .logo {
        padding-left: 0;
    }
    .orderSection .shopClose {
        top: 4px;
    }
    .orderPriceLeft,
    .orderPriceRight {
        width: 100%;
    }
    .priceListTotal {
        padding: 5px 0 0;
        font-size: 12px;
    }
    .priceListSummary {
        font-size: 12px;
    }
    .priceListSummary span {
        width: 100px;
    }
    .orderSummaryPrice {
        padding: 5px;
    }
    .summaryTextArea {
        padding: 10px 5px;
    }
    .summaryDetailLeft {
        width: 100%;
        padding: 0 0px 10px 0;
    }
    .summaryDetailLeft label {
        width: 42%;
        padding: 5px 5px 0 0;
        font-size: 11px;
        text-align: right;
    }
    .summaryDetailLeft input[type="text"],
    .summaryDetailLeft select,
    .summaryDetailLeft input[type="text"],
    .summaryDetailLeft textarea {
        width: 58%;
        height: 28px;
        font-size: 11px;
    }
    .orderFreeText {
        font-size: 11px;
        padding: 0 0 10px;
    }
    .orderSection .productItemList {
        padding: 5px;
    }
    .orderSection .productItemImage {
        width: 10%;
    }
    .orderSection .productItemDetails {
        padding: 0px 0 0 10px;
        width: 90%;
    }
    .orderSection .productItemName {
        font-size: 11px;
    }
    .orderSection .removeItem {
        font-size: 11px;
        bottom: 1px;
        top: auto;
    }
    .productItemRight-top {
        margin-top: 0px;
    }
    .productItemRight-top .qty {
        top: 15px;
    }
    .summaryDetailLeft textarea {
        width: 100%;
        height: 80px;
        padding: 3px 10px;
    }
    .summaryButtonSection {
        padding: 0 10px;
    }
    .paymentSection {
        width: 90%;
        margin: 30px auto;
    }
    .paymentConfirm {
        padding: 20px 0 0;
    }
    .invoice-div {
        width: 100%;
        padding: 0 5px;
        margin: 0 auto 25px;
    }
    .titleHeading-succes {
        font-size: 18px;
    }
    .invoice-div .orderSection .shopName {
        font-size: 16px;
        width: 80%;
    }
    .invoice-div .shopTitle .shoporderNumberr {
        width: 100%;
        font-size: 14px;
    }
    .invoice-div .orderSection .productItemList {
        padding: 5px;
    }
    .invoice-div .productItemDetailsInvoice .productItemLeft {
        width: 100%;
        padding: 0 0 5px 0;
    }
    .invoice-div .productItemDetailsInvoice .productItemLeftCenter {
        width: 55%;
    }
    .invoice-div .productItemDetailsInvoice .productItemRight {
        width: 45%;
    }
    .orderPriceFull {
        font-size: 13px;
        padding-bottom: 5px;
    }
    body .invoice-div .summaryButtonSection {
        margin-top: 15px;
        padding: 0 5px 10px;
    }
    .headerSearchSubOuter {
        z-index: 2;
        left: 10px;
        right: 10px;
        width: auto;
        top: 88px;
    }
    .headerSearchSub .searchDetails {
        padding: 7px 13px 0 10px;
        font-size: 10px;
        width: 60%;
    }
    .headerSearchSub .searchQty {
        width: 50%;
        padding-right: 0;
        text-align: left;
    }
    .headerSearchSub .searchItemName {
        width: 100%;
        padding-right: 0;
        max-width: 100%;
    }
    .searchList .cart_clk {
        opacity: 0;
    }
    .dashboardWrap {
        padding: 10px 0;
        position: relative;
    }
    .mainCategory li {
        position: relative;
    }
    .sugCategoryDrop {
        display: block;
        color: #545454;
        position: absolute;
        right: 3px;
        top: 2px;
        font-size: 15px;
        width: 25px;
        text-align: center;
        z-index: 1;
    }
    .removeItem {
        font-size: 13px;
    }
    .categoryLevelOne .subCategory,
    .categoryLevelTwo,
    .categoryLevelTwo .subSubCategory {
        display: block;
    }
    .saveMoreItem {
        width: 50%;
    }
    .saveMoreSlider {
        width: 50%;
        padding: 0 6px;
    }
    .saveMoreContent .productItem {
        min-height: auto;
        padding: 0;
    }
    .saveMoreSlider .productItem {
        margin: 0 6px;
    }
    .saveMoreContent {
        padding: 5px;
    }
    .addressList {
        width: 162px;
        margin: 0 2px 4px;
    }
    .addressDetails {
        padding: 10px 5px 30px;
    }
    .addressTitle {
        padding: 5px;
        font-size: 13px;
    }
    h4 {
        font-size: 12px;
    }
    .addressPanel p,
    .addressAction {
        font-size: 11px;
    }
    .addressPanel {
        min-height: 115px;
    }
    .loginClose img,
    .saveClose img {
        opacity: 1;
    }
    body .no-item-found {
        padding: 15px 5px;
        font-size: 15px;
    }
    .storeContentDiv {
        padding: 20px;
    }
    .saveMoreContent .productImage img {
        width: 150px;
    }
    .saveMoreSlider {
        padding: 0;
    }
    .errorMsg {
        font-size: 11px;
    }
    .loginText span {
        font-size: 11px;
    }
    .loginFormCheckBox .inputTextField {
        width: 55%;
    }
    .loginCaptche {
        width: 45%;
        padding-left: 3px;
    }
    .registrationText {
        font-size: 10px;
    }
    .registrationText a,
    .registrationText span {
        font-size: 110%;
    }
    .headerSearchSub::-webkit-scrollbar,
    .cartProductSection::-webkit-scrollbar,
    .fliterList .filterListHeight::-webkit-scrollbar,
    .autoSearch::-webkit-scrollbar,
    .storeContentDiv ::-webkit-scrollbar,
    .cartItemList::-webkit-scrollbar {
        width: 3px;
    }
    .pagination li a,
    .pagination li span {
        padding: 5px 0px;
        height: 30px;
        width: 30px;
        margin: 0 2px 2px;
    }
    .pagination li {
        font-size: 11px;
    }
    .profilePackQty {
        min-width: 40px;
    }
    .orderSummaryPriceSection {
        padding: 0 0 15px;
    }
    .adressActive {
        width: 20px;
        height: 20px;
        padding: 2px;
        line-height: 16px;
        font-size: 10px;
    }
    .sortListDiv {
        position: relative;
    }
    .noDataDiv img {
        width: 100px;
    }
    .noDataText {
        padding: 15px 0 30px;
        font-size: 12px;
    }
    .noDataText img {
        width: 35px;
        margin: -6px 5px 0 0;
    }
    body .autoSearch li {
        font-size: 12px;
        padding: 4px 10px;
    }
    .isOutOfStock {
        width: 32%;
        font-size: 13px;
        padding: 18px 0 0;
    }
    .orderSection .isOutOfStock {
        width: 30%;
        padding: 8px 0 0;
    }
    .headerSearchSubOuterRestaurant {
        width: auto;
    }
    .headerSearchFullView .searchField {
        width: 100%;
    }
    /***** order management *****/
    .orderm-coverWraper .contentWrap {
        padding-top: 5px;
    }
    .orderm-couponfieldholder {
        width: 46%;
        float: left !important;
    }
    .orderm-coverWraper .sideMEnu {
        top: 45px;
    }
    .orderm-coverWraper .headerIcons a {
        color: #fff;
        font-size: 14px;
    }
    .orderm-coverWraper .pagination li a,
    .orderm-coverWraper .pagination li span {
        padding: 8px 0px;
    }
    body .orderm-couponfieldholder .borderButton {
        width: 100%;
        text-align: center;
    }
    .profileDetails .offerTag {
        font-size: 13px;
        padding: 1px 30px 0px 7px;
    }
    body .offerText img {
        width: 14px;
    }
    .searchDetails .offerText {
        font-size: 10px;
    }
    .scheduleHalf {
        width: 29%;
        padding: 0 2px;
        margin: 0;
    }
    .scheduleHalf .scheduleHalfSection {
        width: 100%;
        padding: 0;
        display: block;
    }
    .scheduleHalfSection input.formInputField {
        width: 100%;
        padding: 2px 10px 2px 25px;
    }
    .disclaimerText {
        font-size: 10px;
        width: 100%;
        text-align: right;
    }
    .summaryDetailLeft.popFormList {
        margin: 0 0px 10px;
    }
    .headerSearchFullView .headerSearchSubOuter {
        width: auto;
    }
    .commonProductItemImage img {
        width: auto;
        height: 50px;
    }
    .orderSection .commonProductItemImage img {
        height: auto;
        width: 100%;
    }
    .deliveryTime {
        font-size: 11px;
    }
    .orderPriceLeft.deliveryTime {
        text-align: right;
        font-size: 11px;
        padding: 0 0 10px;
    }
    .itemAddAnimation {
        font-size: 11px;
        padding: 8px 5px;
    }
    .profileButtonSection .itemAddAnimation.itemAddAnimationOpen {
        bottom: 35%;
    }
    .orderSection {
        margin: 0 0 10px;
    }
    .summaryButtonSection .cartGrandTotal {
        font-size: 15px;
    }
    .orderSummaryButton {
        padding: 0 5px;
    }
    .addressSection {
        padding: 10px 0 10px 10px;
    }
    .orderSummaryBtmText {
        font-size: 10px;
        padding: 15px 10px;
    }
    .mobileFilter {
        display: inline-block;
        background: #e6f8f7;
        color: #000000;
        border-radius: 0px;
        padding: 8px 20px;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        margin: 0 0 10px;
    }
    .cartDiv {
        top: 96px;
        left: 0;
        width: 100%;
    }
    .cartDivSection {
        width: 760px;
        padding: 15px;
    }
    .cartStoreLogo a {
        height: 50px;
        width: 50px;
    }
    .cartStoreBtns {
        width: 40%;
    }
    .cartStoreLogo {
        width: 60%;
    }
    body .cartCommonButton {
        width: 93px;
        font-size: 12px;
        height: 30px;
    }
    .cartProductItemLeft {
        width: 70%;
    }
    .cartProductItemPrice .oldPrice:after {
        top: 8px;
    }
    .cartGrandPrice {
        font-size: 12px;
    }
    .cartPriceStoreName {
        font-size: 13px;
    }
    .cartMrpSection {
        font-size: 11px;
    }
    .cartGrandButtonDiv .commonButton {
        font-size: 12px;
        padding: 10px 0;
    }
    .grandTotalMultiple {
        max-height: 400px;
        min-height: 275px;
    }
    .cartStoreProduct {
        max-height: 535px;
    }
    .orderSummarySection {
        padding: 50px 80px;
    }
    .orderIndication {
        width: 320px;
        margin: 0 auto 25px;
    }
    .orderIndicationBar {
        height: 4px;
    }
    .orderIndicationCircle {
        width: 17px;
        height: 17px;
        right: -8px;
    }
    .orderIndicationBar:first-child {
        width: 6px;
    }
    .orderIndicationCircle span {
        width: 13px;
        height: 13px;
    }
    .checkoutTitle {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0 25px;
    }
    .checkoutOrderHead {
        display: none;
    }
    .checkoutOrderBody .checkoutOrderRow {
        padding: 30px 0;
        display: block;
    }
    .checkoutStoreColumn {
        width: 100%;
    }
    .checkOutStore {
        padding: 0;
    }
    .checkOutStoreDetails {
        padding: 0 10px 0 0;
    }
    .checkOutStoreDetails h4 {
        margin: 0 0 10px;
        font-size: 13px;
    }
    .checkoutOrderBody .checkoutItemDetail {
        width: 100%;
        padding: 20px 0 0;
    }
    .checkoutItemDetailList {
        display: block;
        padding: 4px 0 4px 25%;
        margin: 0 0 10px;
    }
    .checkOutMrpSection {
        font-size: 12px;
    }
    .checkOutStoreName {
        font-size: 13px;
    }
    .checkOutInvoice {
        width: 58%;
    }
    .checkOutItemName {
        width: 100%;
        padding: 0 0 0 10px;
        line-height: 20px;
        font-size: 13px;
    }
    .checkOutImage {
        width: 25%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        text-align: center;
    }
    .checkOutImage img {
        width: 100%;
        height: 100%;
    }
    .checkoutQtyColumn {
        width: auto;
        text-align: left;
        display: inline-block;
        min-width: 100px;
        vertical-align: middle;
        padding: 0 0 0 10px;
    }
    .checkoutQtyColumn .cartQtyBtn {
        justify-content: left;
    }
    .checkoutPriceColumn {
        width: 61%;
        padding: 0;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
    }
    .checkoutPriceColumn .cartProductItemPrice {
        font-size: 13px;
    }
    .checkOutQty {
        font-size: 12px;
    }
    body .checkoutDelete {
        right: 7px;
        top: 17px;
    }
    .checkoutDelete img {
        width: 18px;
    }
    .checkoutSlotHalf:first-child {
        padding: 0;
    }
    .checkoutSlotHalf {
        width: 100%;
    }
    .shippingAddressListDiv {
        width: 100%;
    }
    .shippingAddressItem {
        width: 270px;
        margin: 0 10px 20px;
    }
    .shippingAddresItem {
        width: 100%;
        padding: 0 10px;
        margin: 0 0 15px;
        font-size: 12px;
    }
    body .checkInputField {
        height: 35px;
        padding: 0 10px;
    }
    .checkoutPayment {
        width: 70%;
    }
    .checkoutItemSaveDiv {
        padding: 10px 0px 0 0;
    }
    .cartDivSection .cartClose img {
        width: 20px;
    }
    .cartDivSection .cartClose {
        right: 0;
        top: 0px;
        padding: 5px;
        transform: translateY(0);
    }
    .promoCodeWrap {
        padding: 30px 0;
        width: 100%;
    }
    .promoCodeTitle {
        font-size: 25px;
        margin: 0 0 10px;
    }
    .promoCodeWrap .react-tabs__tab-list li {
        font-size: 11px;
        font-weight: 500;
    }
    .promoCodeWrap .react-tabs__tab-list {
        margin: 0 0 20px;
    }
    .promoStoreCount {
        font-size: 15px;
        padding: 8px 10px;
        margin: 0 0 20px;
    }
    .promoOfferDetails p {
        font-size: 12px;
    }
    .promoCodeText {
        border: 1px dashed #fff;
        padding: 5px 15px;
        font-size: 13px;
    }
    body .promoOfferListLink {
        min-height: 90px;
    }
    .promoStoreName:after,
    .promoStoreLogo:after,
    .promoOfferLogo:after,
    .promoOfferDetails:after {
        height: 35px;
    }
    .promoStoreLogo,
    .promoOfferLogo {
        padding: 15px 17px;
    }
    .promoStoreName {
        padding: 0 20px;
        font-size: 16px;
    }
    .promoOfferDetails {
        padding: 0 20px 5px;
        font-size: 16px;
    }
    .checkOutNoFound span {
        width: 350px;
        max-width: 100%;
    }
    .checkoutAvailPromo {
        top: 63px;
        width: 100%;
        border-radius: 10px;
    }
    .checkoutAvailPromo .promoCodeText,
    .checkoutAvailPromo .promoOfferDetails p {
        font-size: 12px;
    }
    .contactParts {
        width: 100%;
    }
    .contactImg {
        width: 25%;
    }
    .contactImg img {
        max-width: 20px;
    }
    .contactTxt {
        width: 75%;
    }
    .contactTxt span {
        font-size: 12px;
    }
    .ContactTab {
        width: 100%;
        padding: 20px 20px;
    }
    .bodyTwoShadow {
        margin-top: -20px;
        margin-bottom: -40px;
    }
    .addressWrap {
        padding: 20px 0;
    }
    .contactFull {
        width: 100%;
    }
    .checkoutSlotItemPromo {
        width: 100%;
    }
    .checkoutAvailPromoBtn .commonButton {
        height: 33px;
        padding: 7px 17px;
        font-size: 13px;
    }
    .checkoutAvailPromo .promoOfferDetails p {
        width: 100%;
    }
    .globalSearch {
        top: 92px;
    }
    .globalSearchPop {
        width: 100%;
    }
    .globalSearchRow {
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: auto;
        margin: 0;
    }
    .addMoreItemList {
        width: 50%;
        padding: 5px 5px 10px;
        min-width: 190px;
    }
    .globalSearch .globalSearchContent {
        max-height: 530px;
    }
    .globalSearchHead {
        font-size: 20px;
        margin: 0 0 20px;
    }
    .globalSearchResult {
        padding: 20px 0;
    }
    .uploadCover {
        flex-wrap: wrap;
    }
    .uploadIconSec {
        width: 100%;
        margin: 0 0 10px;
        height: 90px;
    }
    .uploadPrescription {
        width: 100%;
    }
    .uploadPrescriptionHead {
        font-size: 18px;
    }
    body .uploadValid {
        font-size: 11px;
    }
    .uploadIconSec img {
        width: 45px;
    }
    .uploadPrescription p {
        font-size: 12px;
    }
    .item-m-size,
    .item-m-select1,
    .item-m-offer,
    .item-m-price,
    .item-m-date {
        width: 33%;
    }
    .item-m-size,
    .item-m-select1,
    .item-m-offer,
    .item-m-price,
    .item-m-date,
    .item-m-chkbx,
    .item-m-close,
    .item-m-plus {
        display: inline-block;
        margin-bottom: 15px;
        padding-right: 2%;
    }
    .item-search-wrapper {
        display: block;
        position: relative;
    }
    .item-search-sub {
        width: 49%;
    }
    .item-management-main {
        display: block;
    }
    .item-m-chkbx-top,
    .fileCloseImgTop {
        margin-top: 0;
    }
    .fileUploadField {
        width: 49%;
    }
    .globalSearchResult .globalSearchRow {
        margin-bottom: 30px;
        overflow: visible;
        flex-wrap: wrap;
    }
    .globalSearchResult .addMoreItemList {
        width: 100%;
    }
    .priceMainReports .priceSubReportsMain {
        width: 46%;
    }
    .checkoutcontStore {
        width: 100%;
    }
    .checkoutShippingStoreText {
        float: left;
        width: 100%;
    }
    .customizationPop {
        width: 750px;
    }
    body .deActiveBtn {
        font-size: 9px;
        padding: 7px 15px;
        margin: 4px 0px 5px;
    }
    .addlocatnMOble {
        font-size: 10px;
    }
    .containerWrap {
        padding-left: 50px;
    }
    .orderm-right-sectionMenu {
        width: 100%;
        padding-top: 100px;
    }
    .connecting-line {
        right: 71px;
        width: 46%;
    }
    .gsa-addaddStoreSection {
        width: 100%;
    }
    .addStoreSectionListwidth {
        width: 100%;
    }
    .categorylevelTableWidth {
        width: 100%;
    }
    .contentWrapLeft {
        padding-left: 15PX !important;
    }
    .orderm-right-sectionpadngLeft {
        padding-left: 0 !important;
    }
    .breadcrumb-ulpadngleft {
        padding-left: 30px;
    }
    .orderm-paginationSection {
        text-align: right;
        margin-top: 20px;
    }
    .breadcrumbLeft {
        padding-left: 0 !important;
    }
    .addressLft {
        width: 100%;
    }
    .csvDiv {
        margin-left: 55px;
    }
    .csvDivbuyer {
        margin-left: 40px;
        text-align: right;
    }
    .csvDivpurchase {
        margin-left: 40px;
        text-align: right;
    }
    .csvDivelectronic {
        padding-left: 80px;
    }
    .csvDivtab {
        margin-left: 60px;
        text-align: left;
    }
    .csvdivright {
        text-align: right;
    }
    .csvDivpayment {
        margin-left: 0;
        padding-left: 0;
    }
    .csvDivsite {
        margin-left: 90px;
    }
    .csvDivtable {
        position: absolute;
        bottom: 18px;
        right: 17%;
    }
    .actionLeftmargin {
        margin: 0 !important;
    }
    .formInputitemcodeWidth {
        width: 45% !important;
    }
    .formInputitemcodeCloseWidth {
        width: 20%;
    }
    /* .activeBtnwidth {
    width: 65%;
} */
    .orderm-right-TabSectn {
        width: 100%;
        padding: 0;
    }
    .bannerButtonOTP {
        padding: 6px 35px !important;
    }
    .bannerButtonPadng {
        padding: 7px 15px !important;
    }
    .lkbannerContentmargin {
        top: 18% !important;
        left: 4% !important;
    }
    .signupLabelText {
        font-size: 16px;
    }
    .signupLabelTextMargin {
        margin-top: 10px;
    }
    .paymentconfrmedText {
        font-size: 16px !important;
        padding-top: 10px;
        margin: 0 0 0px !important;
    }
    .paymentConfirmedDiv {
        margin-top: 10px;
    }
    .paymentlabel {
        font-size: 12px;
        width: 78%;
        line-height: 15px;
    }
    .termscondtnWidth {
        width: 100%;
        margin-bottom: 0px !important;
    }
    .paymentTextHeadTop {
        margin-top: 10px;
        margin-bottom: 0 !important;
    }
    .paymentOPtnText {
        width: 400px;
    }
    .signupLabelText p {
        font-size: 12px !important;
        width: 78%;
    }
    .custom_radio input[type="radio"]+label {
        padding-left: 17px;
    }
    .custom_radio input[type="radio"]+label:before,
    .custom_radio input[type="radio"]+label:after {
        width: 14px;
        height: 14px;
    }
    .custom_radio {
        margin-top: 5px;
    }
    .signupLabelText a {
        font-size: 12px;
    }
    .bannerButtonWidth {
        font-size: 13px !important;
        padding: 6px 14px !important;
    }
    .custom-checkbox {
        margin-bottom: 6px;
    }
    .styled-checkbox:checked+label:after {
        top: 12px;
    }
    body .cartInputwidth {
        width: 100% !important;
    }
    .excelStockUpdation-form {
        width: 90%;
    }
    .formInputSectionwidth-itemmap {
        width: 45% !important;
    }
    .autosearch-widthUl {
        width: 100%;
        right: 0!important;
        left: 0 !important;
    }
    body .commonbtn_delivery{
        font-size: 13px;
        padding: 5px 8px;
    }
    body .commonButton{
        font-size: 14px;
    }
    .footerSection .menuListView {
        display: block;
    }
    .footerSection .mainMenuList {
        width: 100% !important;
    }
    .contentWrapRestaurant .storeContent {
        position: relative;
        bottom: 0px;
         padding: 60px 0 20px;
    }
    body .loginPop {
        width: 95%;
    }
    .itemListDiv .mobileFilter {
         background: #fff;
        border: 1px solid #ddd; 
        margin: 0 10px 10px;
    }
    .contentWrap.subDomainWrap {
        padding: 150px 0 0px;
    }
    body .tag { 
        padding: 5px 10px;
         height: auto;
    }
    .mobileFilter { 
        padding: 6px 15px;
    }
    .itemTopListing .tagSection, .tagSection {
         width: 100%;
         float: left;
    } .filterSection {
        margin: 0px 0 0 !important;
     }
    .itemListTop {
        padding: 0px 0 5px;
    } 
    .itemListDiv .mobileFilter {
        margin: 0 0px 5px;
    }   
    .itemCoverDiv .itemFilterSection{
        width: 40%;
    }
    .itemCoverDiv .itemListTop {
        padding: 0 0 5px;
    }
    .itemCoverDiv .mobileFilter { 
        padding: 3px 20px;    font-size: 13px;
    }
    .offerText {
         padding: 15px 5px 3px; 
        background: transparent;
    }
    .mobileFilter img{
        width: 10px;
    }
    .shipAddressActionposition{
        text-align: right;
    }
    .checkoutItemDetailList.justify-spacebtwn{
        display: flex;
    }
    .shppngList-bottm-wraper{
        width: 100%;
    }
    .shippingAddressList-outer-w{
        width: 100%;
    }
    .dashboardToggle { 
        top: 45px;
        left: 15px;
    }
    .dashBoardMenuMobile li img{
        margin-right: 8px;
    }
    .msContinueShopping.mslogout,.mslogout{
        font-size: 15px;
    } .addressRightShow { 
        padding: 30px 0px;
        width: 100%;
    }.addressList {
         margin: 0 0px 0px;
    }
   .addressSection .slick-dots {
        bottom: -20px;
    }
    .addressAction span {
         margin: 5px 19px 5px 0;
    }
    
}

@media (max-width:991px) {
    .ms_grocery_lists {
        padding-top: 40px;
    }
    .ms_analytics {
        margin-bottom: 25px;
    }
    /* .ms_analytics_icon {
        top: -25px;
    } */
    .ms_analytics_bg .ms_margin_btm {
        margin-bottom: 15px;
        margin-top: 15px;
    }
    .ms_analytics.ms_chart_outer,
    .ms_analytics.ms_chart_outer.ms_diagram {
        height: auto;
    }
    .headtext_wraper {
        display: block;
    }
    body .cart-locationiput {
        width: 83% !important;
        margin-bottom: 10px;
    }
   
}
@media (max-width:800px){
    .coverWraper .itemCategoryTitle {
         font-size: 22px;
    }
    .lokalInWrapper .headerSearch { 
        top: 104px;
    }
    .globalSearchContent .seemore-link {
         padding: 9px 0;
    }
    .mslogout,.msContinueShopping.mslogout{
        font-size: 13px;
    }
    .cartDivSection {
        width: 93% !important;
    }
   
    /* .globalSearchRow {
         flex-wrap: wrap;
    }.addMoreItemList {
        width: 100%;
    }
    .glsearchFlexRes{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .globalSearchRow a {
        text-decoration: none;
        height: 100%;
        display: flex;
        text-align: left;
    }
    .globalItemSize{
        text-align: left;
    }
    body .globalItemImage img {
        
        margin-right: 15px;
    }
    .resCartBtns{
        position: absolute;
    bottom: 14px;
    left: 112px;
    } */
}

/***** order management *****/

@media (max-width:767px) {
    .confirmWrappers {
        width: 100%;
        top: 10px;
    }
    .loginWraper-formDIv .login-page {
        width: 100%;
        padding: 8% 2px 1px;
        margin: auto;
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translateY(-50%);
    }
    /* .cart-mobile-global{
        display: block;
    }
    .cartProductItemWrap{
        z-index: 1;
    }
    .cart-web-global{
display: none;
    } */
    .cart-web-global .cartGlobalList .cartGlobalItem {
        background: #efefef;
    }
    .cartGlobalList .cartGlobalItem {
        border-left: 1px solid #8f9da8;
        border-right: 1px solid #8f9da8;
        border-bottom: 1px solid #fff;
    }
    .cartGlobalList .cartGlobalItem:last-child {
        border-bottom: 1px solid #8f9da8;
        border-left: 1px solid #8f9da8;
        border-right: 1px solid #8f9da8;
    }
    .cartGrandButtonDiv .commonButton {
        font-size: 17px;
    }
    .cart-web-global .cartGlobalSearch {
        display: unset;
        /* justify-content: space-between; */
        /* border: 1px solid #86a9c7; */
    }
    .cartGrandButtonDivMobile {
        position: absolute;
        margin: 8px 0 0;
        bottom: 15px;
        width: 85%;
    }
    .cart-web-global {
        position: absolute;
        margin: 8px 0 0;
        /* bottom: 70px;
        width: 85%; */
        bottom: 40px;
        width: 92%;
    }
    .cart-cover-gsa {
        margin-top: 22%;
    }
    .cartContentCoverMobile {
        margin-top: 42%;
    }
    .cart-web-global .cartGlobalList {
        position: absolute;
        top: 39px;
        background: #ffffff;
        border: 1px solid #ddd;
        border-radius: 7px;
        left: 0;
        right: 0;
        display: block;
        z-index: 99999;
        min-height: 120px;
         overflow-y: auto;
    }
    .cartContentCover {
        height: 370px;
        overflow: auto;
    }
    .pull-right {
        float: none;
    }
    .wizard .nav-tabs>li a i {
        font-size: 9px;
    }
    .gsa-addaddStoreSection {
        width: 100%;
    }
    .orderm-couponBox {
        overflow: hidden;
        height: auto;
    }
    .orderm-paginationSection {
        margin-top: 10px;
        text-align: center;
    }
    .orderm-search-wrapper {
        width: 100%;
        position: relative;
    }
    .dashboardTableMain .addcartbtn {
        min-width: 150px;
    }
    .orderm-right-section {
        padding: 15px;
        width: 100%;
    }
    /* .orderm-coverWraper .sideMEnu, .orderm-coverWraper .sideMEnu:hover {
        width: 60px;
        top: 0;
        z-index: 9;
        background-color: transparent;
        position: absolute;
    } */
    /* .orderm-coverWraper .sideMEnu ul {
        width: 0;
    } */
    .orderm-coverWraper .sidemenu-close {
        position: absolute;
        content: "";
        top: 10px;
        right: 10px;
        background-color: #008074;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        text-align: center;
        color: #fff;
        display: none;
    }
    .orderm-coverWraper .sideMEnu.sideMEnu-active {
        width: 100%;
        background-color: #23a196;
        position: fixed;
    }
    .orderm-coverWraper .sideMEnu.sideMEnu-active ul {
        width: 100%;
    }
    .orderm-coverWraper .sideMEnu-active .sidemenu-close,
    .orderm-coverWraper .sidetoggle {
        display: block;
    }
    .orderm-coverWraper-sidemenu .logo {
        margin-left: 20px;
    }
    .orderm-coverWraper .sideMEnu-active .sidetoggle {
        display: none;
    }
    .orderm-ContentForm {
        padding: 20px;
    }
    .cartStoreProduct {
        /* max-height: 310px; */
        max-height: 44vh;
        width: 100%;    margin-bottom: 15px;
    }
    .cartContentCover {
        display: block;
    }
    .cartDivSection,
    .uploadPopDiv .cartDivSection {
        width: 99%;
        padding: 10px;
        height: 590px;
    }
    .cartGrandTotalDiv {
        width: 100%;
        padding: 15px;
    }
    .grandTotalMultiple {
        max-height: 120px;
        min-height: auto;
        margin: 0 0 15px;
    }
    .cartStoreLogo a {
        height: 40px;
        width: 40px;
    }
    .cartStoreDetails {
        display: block;
    }
    .cartStoreLogo {
        width: 100%;
    }
    .cartStoreBtns {
        width: 100%;
        justify-content: flex-end;
    }
    .cartStoreBtns .cartCommonButton {
        margin: 0 0 0 10px;
        height: 28px;
        font-size: 11px;
    }
    .cartProductItemDetails {
        display: block;
        padding: 0 33px 0 10px;
        width: 78%;
    }
    .cartProductItemLeft {
        width: 100%;
    }
    .cartRemoveItem {
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
    }
    .cartProductItemImage {
        width: 22%;
    }
    .cartStoreName {
        font-size: 14px;
    }
    .cartProductItemName,
    .cartProductBrand {
        font-size: 13px;
    }
    .cartProductItemPrice {
        font-size: 12px;
        margin: 0 0 2px;
    }
    .cartProductBrand {
        display: none;
    }
    .orderSummarySection {
        padding: 30px 15px;
    }
    .checkoutSlotItem {
        margin: 0 0 10px;
        display: block;
    }
    .checkoutSlotItem label {
        width: 100%;
        font-size: 13px;
        margin: 0 0 5px;
    }
    .checkoutSlotItem .inputField {
        width: 100%;
        height: 33px;
        font-size: 12px;
        padding: 1px 10px;
    }
    .checkoutSlotItem .inputTextField {
        font-size: 12px;
    }
    .checkoutCommonButton .commonButton {
        margin: 0 0 0 10px;
    }
    .checkoutGrandSec {
        padding: 30px 0 20px;
    }
    .checkoutGrandTotal {
        display: block;
        text-align: center;
    }
    .checkoutGrandPrice {
        font-size: 18px;
        margin: 0 0 10px;
    }
    .shippingAddressForm {
        width: 100%;
    }
    .shippingAddresItem {
        margin: 0 0 15px;
        font-size: 12px;
    }
    .shippingNumberField {
        width: 100%;
    }
    .shippingNumberDefult {
        width: 100%;
        font-size: 12px;
        padding: 10px 0 0;
    }
    .shippingButton .commonButton {
        margin: 0 6px;
        font-size: 12px;
        padding: 10px 15px;
    }
    .shippingAddressItem {
        margin: 0 auto 15px;
    }
    .checkoutPayment {
        width: 100%;
    }
    .paymentMethod {
        padding: 10px 0px 30px;
        width: 320px;
        margin: auto;
    }
    .paymentMethodList {
        font-size: 14px;
        margin: 0 0 10px;
        padding: 15px;
        border-width: 3px;
    }
    .paymentMethodList .radioSection label {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        top: 5px;
    }
    .paymentMethodList .radioSection label:before {
        width: 14px;
        height: 14px;
    }
    .successData {
        margin: 40px 0 30px;
    }
    .successData img {
        width: 70px;
    }
    .successData h4 {
        font-size: 23px;
        margin: 0 0 5px;
    }
    .successData p {
        font-size: 16px;
    }
    .checkoutItemText {
        font-size: 12px;
    }
    .checkoutSlotItemField {
        width: 100%;
    }
    .shippingButtonAdd .commonButton {
        margin: 0 0 0 5px;
        font-size: 12px;
        padding: 8px 15px;
    }
    .shippingButtonAdd {
        margin: 0 0 30px;
    }
    .shippingAddressContent .commonButton {
        font-size: 13px;
        padding: 10px 15px;
    }
    .cartSaveItemDiv {
        font-size: 10px;
    }
    .checkoutCommonButton .checkoutGrandPrice {
        margin: 10px 0 20px;
    }
    .shopTitleCircle .shopLogo img {
        width: 100%;
    }
    .orderSection .shopTitleCircle {
        flex-wrap: wrap;
    }
    .cartProductItemDetails .isOutOfStock {
        text-align: left;
    }
    .checkoutItemDetailList .isOutOfStock {
        width: auto;
        text-align: left;
        display: inline-block;
        min-width: 100px;
        vertical-align: middle;
        padding: 0 0 0 10px;
    }
    .profileTagSection .tag {
        padding: 0 10px 0 0;
        font-size: 12px;
    }
    .checkoutDetailLeft {
        display: block;
    }
    .checkoutDetailLeft label {
        display: none;
    }
    .checkoutDetailPicker {
        width: 100%;
    }
    .checkoutDetailPicker .formImage {
        width: 17px;
    }
    .shippingNumberField .checkInputField {
        width: 90%;
    }
    .orderSummaryRed {
        padding: 10px 10px 0;
    }
    body .cartInputField {
        width: 75%;
    }
    .item-m-size,
    .item-m-select1,
    .item-m-offer,
    .item-m-price,
    .item-m-date {
        width: 43%;
    }
    .fileUploadField {
        width: 99%;
    }
    .fileradio,
    .fileImg {
        margin: 5px;
    }
    .item-search-sub {
        width: 100%;
    }
    .setLocation-wrapper {
        padding: 0 20px 20px;
    }
    .priceMainReports .priceSubReportsMain {
        width: 100%;
    }
    /* .cartDefault {
    background-color: #efb88f;
} */
    body .cartDefault img {
        object-fit: contain;
        height: 200px;
        margin-top: 35px;
    }
    .setLocation-bdr-wrapper {
        padding: 5px;
    }
    .orderm-right-sectionMenu {
        margin-top: 75px;
    }
    .form-head {
        margin-bottom: 10px;
        font-size: 18px;
    }
    body .shippingButtonAdd .contactStorePhone {
        float: none;
        margin-bottom: 10px;
    }
    .shippingButtonAdd {
        text-align: center;
    }
    .checkoutShippingStore span {
        width: 100%;
        float: left;
    }
    .breadcrumb-ul {
        padding-left: 15px;
        margin-top: 25%;
    }
    .customizationPop {
        width: 100%;
    }
    .addStoreSectionList {
        width: 100%;
    }
    .breadcrumb-ul a {
        font-size: 8px;
    }
    body .deActiveBtn {
        font-size: 9px;
        padding: 7px 15px;
        margin: 4px 0px 5px;
    }
    .form-sectionLeft {
        padding-left: 15px;
        padding-right: 15px;
    }
    .breadcrumb-ul a::before {
        right: -10px;
    }
    .Addcitypadng {
        padding: 4px !important;
    }
    .tablepadngActons {
        padding: 13px !important;
    }
    .orderm-coverWraper .contentWrap {
        padding-top: 20px;
        padding-left: 60px;
    }
    .breadcrumbbuyer a {
        font-size: 7px;
    }
    .breadcrumbbuyer a::before {
        right: -8px;
    }
    .connecting-line {
        width: 41%;
        right: 36px;
    }
    .orderm-search-wrapper-row {
        display: block;
    }
    .activeBtntop {
        margin-top: 10px !important;
    }
    .breadcrumb-lisize {
        font-size: 11px;
    }
    .breadcrumb-ulcategory a::before {
        right: -7px;
    }
    .breadcrumb-ulcategory a {
        font-size: 7px;
    }
    .breadcrumb-ulcategory3 {
        display: block;
    }
    .breadcrumb-ulcategory3 a {
        font-size: 8px;
    }
    .breadcrumb-ulcategory3 a::before {
        right: -8px;
    }
    .contentWrapLeft {
        padding-left: 0PX !important;
    }
    .orderm-right-sectionpadngLeft {
        padding-left: 15px !important;
    }
    .dashbord-reprt-buttonwidth {
        width: 70%;
    }
    .commonButtonbottom {
        margin-bottom: 10px;
    }
    .addressvalue {
        width: 100%;
        height: auto;
    }
    .containerWrapleftbuyer {
        padding-left: 10px;
    }
    .csvDiv {
        /* margin-left: 0; */
        /* margin-top: 10px; */
        position: absolute;
        bottom: 0;
        left: 58px;
    }
    .csvDivbuyer {
        margin-left: 40px;
        text-align: left;
        position: absolute;
        bottom: 10px;
        left: 75px;
    }
    .csvDivstore {
        padding-left: 5px;
        position: absolute;
        bottom: 9px;
        left: 116px;
    }
    .csvDivpurchase {
        margin-left: 40px;
        text-align: left;
        position: absolute;
        bottom: 0;
        left: 75px;
    }
    .csvDivmob {
        margin-left: 40px;
        text-align: left;
        position: absolute;
        bottom: 0;
        left: 75px;
    }
    .csvDivelectronic {
        padding-left: 10px;
        position: absolute;
        bottom: 0;
        left: 116px
    }
    .searchtop-wrapper {
        position: relative;
    }
    .csvgroceryMob {
        position: absolute;
        bottom: 0;
    }
    .csvDivmobrepeat {
        bottom: 0;
        left: 90px;
    }
    .csvDivpayment {
        position: absolute;
        bottom: 0;
        left: 50%;
    }
    .csvDivtable {
        position: absolute;
        bottom: 18px;
        left: 33%;
    }
    .row-marginwidth {
        width: 100%;
    }
    .input-box-w-85 {
        width: 100%;
        margin-bottom: 10px;
    }
    .row-marginpadngmb {
        padding: 0;
    }
    .setLocsub {
        width: 95%;
    }
    .set-weeks {
        width: 100%;
    }
    .addStoreSectionOuterDiv {
        width: 100%;
    }
    body .labelFieldMob {
        width: 82%;
    }
    .formInputSectionMob {
        width: 16%;
    }
    body .customToggle .CommonBtn {
        font-size: 12px;
    }
    .storelistWraperPadng {
        padding-left: 20px !important;
    }
    .formInputitemcodeWidth {
        width: 80% !important;
    }
    .formInputitemcodeCloseWidth {
        width: 20%;
    }
    .contentWrapMobOuter {
        padding-left: 0 !important;
        padding-top: 0 !important;
    }
    .paymentconfrmedText {
        font-size: 14px !important;
    }
    .lkbannerContentmargin {
        left: 0% !important;
    }
    .paymentconfrmedText {
        padding-top: 0;
        margin: 0 0 7px !important;
    }
    .paymentsOuter {
        text-align: left;
        padding-left: 10px;
    }
    .input.termCheckbox {
        margin-top: 15px !important;
    }
    .input.paymentCheckbox {
        margin-top: 0;
    }
    .paymentlabel {
        font-size: 12px;
    }
    .termscondtnWidth {
        margin-top: 2px;
    }
    .termscondtnOuter {
        padding: 44% 0 0px;
        padding-left: 20px;
        padding-right: 1px;
    }
    .termsParaoltext ol li {
        padding-right: 10px;
    }
    .paymentlabel {
        line-height: 18px;
    }
    .custom-checkbox {
        margin-bottom: 15px;
    }
    .styled-checkbox:checked+label:after {
        top: 13px;
    }
    .custom_radio input[type="radio"]+label {
        padding-left: 20px;
    }
    .custom_radio input[type="radio"]+label:before,
    .custom_radio input[type="radio"]+label:after {
        width: 16px;
        height: 16px;
    }
    .signupLabelText p {
        width: 100%;
        padding-left: 20px;
    }
    body .cartInputwidth {
        width: 100% !important;
        /* border: none; */
        background: transparent;
    }
    .subHeading-excelstockForm {
        font-size: 16px;
    }
    .saveButtonDiv-excelform .commonButton {
        padding: 6px 13px;
    }
    .saveButtonDiv-excelform .commonLink-excelformupdate {
        font-size: 13px;
    }
    .excelStockUpdation-form {
        width: 95%;
        padding: 15px;
    }
    .formInputSectionwidth-itemmap {
        width: 92%!important;
    }
    .formInputSection-widthpopup {
        width: 85%;
    }
    .btn-close-popupitem {
        top: 0;
        right: 0;
        font-size: 20px;
        width: 28px;
        height: 28px;
        padding-left: 6px;
        line-height: 23px;
    }
    .contentWrapmob-itemstock {
        padding-left: 0 !important;
    }
    .ms_wrap {
        left: 30px;
    }
    .ms_analytics.ms_chart_outer,
    .ms_analytics.ms_chart_outer.ms_diagram {
        height: auto;
    }
    .commonbtn_delivery{
        float: none;
        margin-top: 10px;
    }
    .checkoutCommonButton .commonButton {
        margin: 0 0 10px 10px;
    }
    body .commonButton.commonPromoButton {
         margin-left: 10px;
    }
    .cart-cover-gsa {
        margin-top: 10%;
        overflow-x: hidden;
    }
    .cartStoreBtns { 
        padding-top: 10px;
    }
    .lokalInWrapper .headerSearch {
        top: 103px;
    } 
    .filterSection {
         width: 50%;
         padding: 0 12px 0px;
    }
    .sortList {
        width: 100%;
        float: left;
        position: relative;
        margin-bottom: 35px;
        right: 0;
    }
    .itemCoverDiv .itemFilterSection {
        width: 50%;
    }
    .itemCoverDiv .mobileFilter {
        width: 100% !important;
    }
    .sortList { 
        right: 15px;
    }
    .sortListDiv { 
        left: 22px;
    }.itemFilterSection {
        padding: 0 8px 0px 15px !important;
    }
    
    body .cart-locationiput {
        width: 80% !important;
        margin-bottom: 10px;
    }
    .paymentMethodList.shippingadressList-radio{
        padding: 0;
    }
    .checkoutItemDetailList.justify-spacebtwn{
        padding: 20px 10px;
    }
    .shippingadressList-radio .radioSection label{
        margin-right: 0;
    }
    .text-shippingadd-new{
        font-size: 13px;
    }
    .shipAddressActionposition .shipAddressRemove{
        margin-right: 0;
        margin-top: 10px;
    }
    .no-wrap{
        white-space: unset;
    }
    .shipAddressActionposition{
        font-size: 17px;
    }
    .cartDivSection, .uploadPopDiv .cartDivSection {
     
       min-height: 560px;
        /* height: auto; */
    }
    .cartContentCover {
        height: 450px;
    }
   
}
@media (max-width:640px){
    .contentWrap.subDomainWrap {
        padding: 115px 0 0px;
    } 
    .globalSearchPop .cartRemoveItem img {
        width: 15px !important; 
        left: 0px;
        top: 0px;
    }
    .globalSearchPop .cartRemoveItem { 
        padding: 2px 8px !important;
      
    }
    .globalSearchPop .cartRemoveItem {
        right: -37px;
        top: 14px;
    }
    .globalSearchPop {
        width: 88%;
     }
}
@media (max-width:570px){
    .jquery-background-video-pauseplay {
        
        left: 45%;
    }
    .categorySection{
        background-size: auto !important;
    }.coverWraper .contentWrap  .itemProfile p {
        font-size: 15px;
        line-height: 25px;
    }
    .coverWraper .contentWrap  .profileItemName {
        font-size: 20px;
    }
    
    .GlobalDataPopWrp .confirmWrappers {
         width: 90%;
    }
    .cart-web-global {
        bottom: 60px;
    }
}

@media (max-width:425px) {
    .ms_wrap {
        width: 90%;
    }
    .coverWraper .itemCategoryTitle {
        font-size: 18px;
    }
    .coverWraper .contentWrap .checkOutItemName,
    .coverWraper .contentWrap .checkOutQty { 
    font-size: 12px;
}
.lokalInWrapper .headerSearch {
    top: 100px;
} 
.itemCoverDiv .mobileFilter {
    width: 145px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    color: #000;
    padding: 3px 10px;    height: 28px;
}
/* .sortList .sortListDiv {
    height: 28px; 
} */
body .accountdetails .labelField {
  font-size: 10px;
}
.cartContentCover {
    height: 425px;
}
.cartContentCover {
    height: 400px;
}
}
@media (max-width:375px){
    .loginText {
        font-size: 18px;
    }
    body .cartInputwidth ,.cart-web-global .cartGlobalList{
        width: 95% !important;
    }
    .globalSearchPop .cartRemoveItem{
        right: -37px !important;   
    }
}
@media (max-width:320px){
    .loginText {
        font-size: 16px;
    }
    .offerText {
        font-size: 10px;
    }
}
@media (max-width:280px) {
    .itemCoverDiv .mobileFilter {
        width: 107px;
    }
}